import React, { useEffect, useState } from "react";
import CustomCombobox from "../../../common/CustomCombobox";
import ShowCase from "../../../ShowCase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import apiClientWithLoading from "../../../../services/api/ApiClient";
import { TypeLevel1Client } from "../../../../services/api/TypeLevel1Client";
import { toast } from "react-toastify";
import { getName } from "../../../../helpers/Translation";
import {
  FormTemplateEntity,
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../../domain/entities";
import { FormTemplateClient } from "../../../../services/api/FormTemplateClient";
import PendingAi from "../../../common/newDesign/PendingAi";
import { useForm } from "react-hook-form";
function CreateBlankTemplate({onClose, loading, setLoading}) {
  const [error, setError] = useState("");
  const [templateExistsError, setTemplateExistsError] = useState(false);
  const [checkTemplateExistsTimeout, setCheckTemplateExistsTimeout] = useState<NodeJS.Timeout>(null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [req, setReq] = useState<FormTemplateEntity>({
    name: "",
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[];
    level2: TypeLevel2Entity[];
    level3: TypeLevel3Entity[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const {
    watch,
    register
  } = useForm();

  const navigate = useNavigate();
  const { t, language , setLanguage} = useTranslation();
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const apiClient = apiClientWithLoading();
  const formTemplateClient = new FormTemplateClient(apiClient);
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const handleSave = async (e) => {
        e.preventDefault(); 
      setLoading(true);
      try {
       if( isFormValid){
          const  row  = await formTemplateClient.create(req)
          toast.success(t("modals.createBlankTemplate.messages.success"));
          const arabicRegex = /[\u0600-\u06FF]/;
      if (arabicRegex.test(row?.name)) {
         setLanguage("ar")
      }
      navigate(`/edition-form-template/${row?.id}`, { replace: true }); 

          
        }else{
         setError(t("modals.createBlankTemplate.error"))
        }
       
      } catch (error) {
        toast.error(
            t("modals.createBlankTemplate.messages.error")
        );
      } finally {
        setLoading(false);
      }
    
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAll();
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.id === value);
    setReq({
      ...req,
      level1Id: selectedLevel1 ? selectedLevel1.id : null,
      level2Id: null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level2: selectedLevel1 ? selectedLevel1.levels2 : [],
      level3: [],
    }));
    setError("")
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.id === value);
    setReq({
      ...req,
      level2Id: selectedLevel2 ? selectedLevel2.id : null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level3: selectedLevel2 ? selectedLevel2.levels3 : [],
    }));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.id === value);
    setReq({
      ...req,
      level3Id: selectedLevel3 ? selectedLevel3.id : null,
    });
    setError("")
  };
  const validateForm = () => {
    const isValid =
      req.name !== "" &&!templateExistsError&&
      req.level1Id != null &&
      (options.level2.length === 0 || req.level2Id != null) &&
      (options.level3.length === 0 || req.level3Id != null);
    setIsFormValid(isValid);
  };

  const templateName = watch("templateName");
  useEffect(() => {
    validateForm();
  }, [req]);
  useEffect(() => {
    if (checkTemplateExistsTimeout) {
      clearTimeout(checkTemplateExistsTimeout);
    }
    setCheckTemplateExistsTimeout(
      setTimeout(async () => {
        try {
          if(req?.name){
            const count = await formTemplateClient.countAllByFilter({
              name: String(req?.name),
            });
            setTemplateExistsError(count >= 1);
          }
          else{
            setTemplateExistsError(false)
          }
         
        } catch (error) {
          console.error(error);
        } finally {
          setCheckTemplateExistsTimeout(null);
        }
      }, 500)
    );
  }, [req?.name]);
  
  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <form
          action=""
          className="form-group w-100"
          style={{ height: "70vh" }}
          onSubmit={handleSave}
        >
          <p className="fw-bolder">
            {t("pages.listener.popups.contract.required")}
          </p>
          <div className="form-input-content">
            <div className="form-input-first-content" style={{ width: "100%" }}>
              <label htmlFor="contractName" className="case-form-labelName">
                {t("modals.generateFormTemplate.inputs.name")}{" "}
              </label>
              <div className="form-floating mb-3 w-100">
                <input
                  type="text"
                  className="form-control custom-color-input input-popup"
                  id="contractName"
                  placeholder={t(
                    "pages.listener.popups.contract.inputs.contractName.placeholder"
                  )}
                  {...register("templateName", { required: true })}
                  onChange={(e) => {
                    setReq({ ...req, name: e.target.value });
                    setError("")
                    setTemplateExistsError(false) 
                  }}
                />
                  {templateExistsError && (
                    <p className="text-danger py-0 my-0 py-0 my-0">
                      {t("modals.createBlankTemplate.unicityError")}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-input-last-content" style={{ width: "80%" }}>
              <CustomCombobox
                fullWidth
                label={t("pages.pilot.popups.contract.inputs.type1.name")}
                options={options.level1.map((item) => item)}
                optionDisplay={(option) => getName(option, language)}
                optionValue={(option) => option?.id ?? null}
                value={options.level1.find(
                  (option) => option.id == req.level1Id
                )}
                onChange={(value) => {
                  handleLevel1Change(value);
                }}
                isOpen={openCombobox === "level1"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
              />

              {(!req.level1Id || options.level2.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type2.name")}
                  options={options.level2.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level2.find(
                    (option) => option.id == req.level2Id
                  )}
                  onChange={(value) => {
                    handleLevel2Change(value);
                  }}
                  isOpen={openCombobox === "level2"}
                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                />
              )}
              {(!req.level1Id ||
                (options.level2.length > 0 && !req.level2Id) ||
                options.level3.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type3.name")}
                  options={options.level3.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level3.find(
                    (option) => option.id == req.level3Id
                  )}
                  onChange={(value) => {
                    handleLevel3Change(value);
                  }}
                  isOpen={openCombobox === "level3"}
                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                />
              )}
            </div>
          </div>
          {!isFormValid&&error&&<p style={{color:"red" , fontSize:"13px"}}>{error}</p>}
          <div className="modal-footer footer-form-pilotage">
            <button type="submit" className="btn btn-primary">
              {t("pages.listener.popups.contract.button")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CreateBlankTemplate;
