import axios from "axios";
import { API_BASE_URL } from "../../config";

// ApiClient.js
export const apiClientWithLoading = (
  setLoading: (v: boolean) => void = null,
  contentType = "application/json"
) => {
  const client = axios.create({
    baseURL: API_BASE_URL,
  });

  client.interceptors.request.use((config) => {
    if (!config.headers["Content-Type"])
      config.headers["Content-Type"] = contentType;
    if (setLoading) {
      setLoading(true);
    }

    const token = localStorage.getItem("user-token");

    if (token) {
      config.headers["user-token"] = config.headers["user-token"] || token;
    }
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (setLoading) {
        setLoading(false);
      }
      return response;
    },
    (error) => {
      if (setLoading) {
        setLoading(false);
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default apiClientWithLoading;
