import React, { useEffect, useState } from 'react'
import { ClauseParam, ListParam, SegmentedClauseParam, SegmentedEnumParam } from '../../../../domain/types/ClauseParams';
import { SegmentText } from "../../../../domain/ContractTemplate";
import ParamTypeCombobox from '../../../../components/common/CustomCombobox/ParamTypeCombobox';
import { useTranslation } from '../../../../contexts/TranslationProvider';
import { BeneficialBaseEntity, BeneficialCompanyEntity, BeneficialEntity, BeneficialPersonEntity } from '../../../../domain/entities';

function AddparamForm({ params, setInsertNew, insertMention, handleClose, selected, search }) {
	const { t } = useTranslation();
	const translationPath = "pages.contractTemplateParam.addParamForm";
	const [paramType, setParamType] = useState<ClauseParam['type'] | null>(selected?.type || null);
	const [paramName, setParamName] = useState<string | null>(selected?.name || search || "");
	const [paramLabel, setParamLabel] = useState<string | null>(selected?.label || "");
	const [paramField, setParamField] = useState<string | null>("");
	const [errors, setErrors] = useState([]);

	const onConfirm = () => {
		if (errors.length > 0 || !paramType || !paramName || !paramLabel || (paramType === "beneficial" && !paramField)) {
			return
		}
		let param: SegmentedClauseParam
		const defaultParam = {
			name: paramName, label: paramLabel, detected: true,
			definition: 0
		}
		switch (paramType) {
			case 'enum':
				param = {
					...defaultParam, type: paramType,
					args: [],
				}
				break;
			case 'list':
				param = {
					name: paramName,
					label: paramLabel,
					detected: true,
					type: paramType,
					args: [],
				}
				break;
			case 'boolean':
				param = {
					...defaultParam, type: paramType,
					args: {
						textIfTrue: SegmentText("", () => "0", params),
						textIfFalse: SegmentText("", () => "0", params),
					}
				}
				break;
			case 'beneficial':
				param = {
					name: paramName,
					label: paramLabel,
					detected: true,
					type: paramType,
					args: {
						beneficialTypes: [],
					}
				}
				break;
			case 'beneficial[]':
				param = {
					name: paramName,
					label: paramLabel,
					detected: true,
					type: paramType,
					args: {
						textPerBeneficial: SegmentText("", () => "0", params),
						beneficialTypes: [],
					}
				}
				break;
			case 'table':
				param = {
					name: paramName,
					label: paramLabel,
					detected: true,
					type: paramType,
					transposed: false,
					args: []
				}
				break;
			default:
				param = { type: paramType, name: paramName, label: paramLabel, detected: true }
				break;
		}

		insertMention(param, paramField)
		setInsertNew(false)
		setParamType(null)
		setParamName("")
		setParamLabel("")
		setParamField("")
		handleClose()
	}

	const onCancel = () => {
		setInsertNew(false)
	}

	useEffect(() => {
		if (selected && selected.name) {
			const existingParam = params.find(param => param.name === selected.name);
			if (existingParam && existingParam.name !== selected.name) {
				setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
			}
			return;
		}
		const existingParam1 = params.find(param => param.name === search);
		if (existingParam1) {
			setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
		}
	}, [selected, search])

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors(prev => prev.filter(err => err.where !== "name"));
		setParamName(e.target.value);
		if (!/^[a-z_][a-z0-9_]*$/i.test(e.target.value)) {
			setErrors(prev => [...prev, { where: "name", why: ".errors.nameWrong" }]);
		}
		const existingParam = params.find(param => param.name === e.target.value);
		if (!existingParam)
			return
		if (existingParam && (existingParam.type === "beneficial" && paramType === "beneficial"))
			return
		setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
	}

	const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors(prev => prev.filter(err => err.where !== "label"));
		setParamLabel(e.target.value);
		if (!e.target.value) {
			setErrors(prev => [...prev, { where: "label", why: ".errors.labelMissing" }]);
		}
	}

	const handleTypeChange = (value) => {
		setErrors(prev => prev.filter(err => err.where !== "type"));
		setParamType(value);
		if (!value) {
			setErrors(prev => [...prev, { where: "type", why: ".errors.typeMissing" }]);
		}
	}

	const handleFieldChange = (value) => {
		if (value && paramType === "beneficial") {
			setErrors(prev => prev.filter(err => err.where !== "field"));
		}
		setParamField(value);
		if (!value && paramType === "beneficial") {
			setErrors(prev => [...prev, { where: "field", why: ".errors.fieldMissing" }]);
		}
	}
	const beneficialFields:(keyof BeneficialPersonEntity|keyof BeneficialCompanyEntity)[]=['description', 'socialCapital', 'legalForm', 'companyName', 'registrationNumber', 'socialCapitalCurrency','postalAddress','specialization', 'firstName', 'lastName', 'fullName', 'address','cin','cinDeliverPlace','cinDeliveryDate','gender','jobTitle','nationality','dateOfBirth','placeOfBirth',"maritalStatus"]


	return (
		<div
			className="dialog-container"
			style={{
				width: "100%",
				border: "none"
			}}>
			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".type")}
				</div>
				<ParamTypeCombobox
					options={['string', 'number', 'date', 'enum', 'boolean', 'list', 'file', 'beneficial', 'table']}
					value={paramType}
					onChange={handleTypeChange}
					disabled={selected}
				/>
				{
					errors.map(err => {
						if (err.where === "type") {
							return <div className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>

			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".name")}
				</div>
				<input disabled={selected} className="dialog-input" type="text" value={paramName} onChange={(e) => handleNameChange(e)} />
				{
					errors.map((err, idx) => {
						if (err.where === "name") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>

			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".label")}
				</div>
				<input disabled={selected} className="dialog-input" type="text" value={paramLabel} onChange={(e) => handleLabelChange(e)} />
				{
					errors.map((err, idx) => {
						if (err.where === "label") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>

			{paramType == "beneficial" ? <div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".field")}
				</div>
				<ParamTypeCombobox
					options={beneficialFields}
					value={paramField}
					onChange={handleFieldChange}
				/>
				{
					errors.map((err, idx) => {
						if (err.where === "filed") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>
				:
				<></>
			}
			<div className="dialog-buttons">
				<button className="dialog-button" onClick={onCancel}>
					{t(translationPath + ".cancel")}
				</button>
				<button
					className={`dialog-button ${errors.length > 0 || !paramType || !paramName || !paramLabel || (paramType === "beneficial" && !paramField) ? "dialog-button-disabled" : ""}`}
					onClick={onConfirm}>
					{t(translationPath + ".confirm")}
				</button>
			</div>

		</div>
	);
}

export default AddparamForm