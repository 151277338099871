import React from 'react'
import { useTranslation } from "../../../../contexts/TranslationProvider";

function DefaultRender({param, value, isEditing, onChange}) {
  const { t, language } = useTranslation();
  return (
    <div className={`input-param`}>
    <div className="input-type">{param.type}</div>
    <div>
      {isEditing ? (
        <textarea
          className="custom-form-textarea"
          value={value}
          onChange={onChange}
        />
      ) : (
        <div style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label">{param.label}</div>
      )}
    </div>
  </div>
  )
}

export default DefaultRender