import "./sliderWrapper.scss"
const Slider = ({ options, language, handleComboBoxChange, selectedType, setSelectedType }) => {

  const handleOptionClick = (value) => {
    setSelectedType(value);
    handleComboBoxChange("type")(value);
  };

  return (
    <div className="wrapper-generic">
      {options.map((option, index) => (
        <div
          key={index}
          className={`slider-option slider-option-generic ${
            selectedType === option.value ? "selected-type-beneficial" : ""
          }`}
          onClick={() => handleOptionClick(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
export default Slider