import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/TranslationProvider';
import apiClientWithLoading from '../../services/api/ApiClient';
import Icon1Active from "../../assets/icon1-active.svg"
import Icon2Active from "../../assets/icon2-active.svg"
import Icon3Active from "../../assets/icon3-active.svg"
import Icon4Active from "../../assets/icon4-active.svg"
import Icon5Active from "../../assets/icon5-active.svg"
import Icon6Active from "../../assets/icon6-active.svg"
import Icon7Active from "../../assets/icon7-active.svg"
import Icon8Active from "../../assets/icon8-active.svg"
import Icon1 from "../../assets/icon1-inactive.svg"
import Icon2 from "../../assets/icon2.svg"
import Icon3 from "../../assets/icon3.svg"
import Icon4 from "../../assets/icon4.svg"
import Icon5 from "../../assets/icon5.svg"
import Icon6 from "../../assets/icon6.svg"
import Icon7 from "../../assets/icon7.svg"
import Icon8 from "../../assets/icon8.svg"
import "./useCase.scss"
import ImportDocumentModal from '../../components/modals/ImportDocumentModal';
import "../../components/modals/commonmodal.scss";
import ComingSoon from '../../components/modals/ComingSoon';
import Loading from '../../components/common/Loading';
import { DocumentUseCaseClient } from '../../services/api/DocumentUseCaseClient';
import SVGRender from '../../helpers/SVGRender';
import { useCaseIcons } from '../../assets/icons/useCaseIcons';

function AdminPage() {
  const { t, language } = useTranslation();
  const [topics, setTopics] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const usecases = t("pages.topic.usecases")

  const apiClient = apiClientWithLoading();
  const documentUseCaseClient = new DocumentUseCaseClient(apiClient)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await documentUseCaseClient.getAll(language, 'nested');
        const sortedTopics = data.rows.sort((a, b) => (b.activated ? 1 : -1));
        setTopics(sortedTopics);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [language]);

  useEffect(() => {
    if (selectedTopic !== null) {
      setSelectedUseCase(null)
    }
  }, [selectedTopic]);

  const handleUseCaseClick = (index) => {
    setSelectedUseCase(index);
  };
  const handleTopicClick = (index) => {
    setSelectedTopic(index);
  };
  if (!loading) {
    return <Loading />;
  }
  return (
    <section id="main-function" className={`topics-page ${selectedTopic !== null && !topics[selectedTopic]?.activated ? 'coming-soon-message' : ''}`}>
      <div className="topics-container">
        <div className="topics-scroll">
          {topics?.map((topic, index) => (
            <div
              key={index}
              onClick={() => handleTopicClick(index)}
              className={`topic-item ${selectedTopic === index ? 'activated' : ''}`}
            >
              <span>{topic?.name}</span>
            </div>
          ))}
        </div>
      </div>
      {selectedTopic !== null && topics[selectedTopic]?.activated ? (
        <>        <div className='usecases-container'>
          <div className="usecases-scroll">
            {usecases?.map((usecase, index) => (
              <div
                key={index}
                className={`usecase-item ${selectedUseCase === index ? 'selected-usecase' : ''}`}
                onClick={() => handleUseCaseClick(index)}
              >
                <SVGRender svgContent={useCaseIcons[index]} zoom='0.7' />
                <p className={`usecasetext ${selectedUseCase === index ? 'selected-textusecase' : ''} `}>{usecase.name}</p>
              </div>
            ))}
          </div>
          <div className='import-document-modal'>
            {showModal && <ImportDocumentModal type='topic-usecase' onClose={() => setShowModal(false)} usecaseId={topics[selectedTopic]?.id} />}
          </div>
        </div>
          <hr className='separation-line' />
          <div className='flex-grow-1 d-flex'>
            <div className="vertical-line me-3"></div>
            <button className='action-calculate-compliance' onClick={() => setShowModal(true)} >{t("pages.topic.action")}</button>
          </div>
        </>
      ) : (
        <ComingSoon />
      )}
    </section>
  );
}

export default AdminPage;
