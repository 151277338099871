import { useContext, useState } from "react";
import "./contractTempleteParam.scss";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { ListInputItem } from "../../../components/common/ListInputItem";
import { isFileType } from "../../../helpers/helper";
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { ClauseParam } from "../../../domain/types/ClauseParams";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import ParamCombobox from "./ParamCombobox";
import { useTranslation } from "../../../contexts/TranslationProvider";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
const { default: Delete } = require("../../../assets/delete.svg");
const { default: Remove } = require("../../../assets/remove.svg");


function ContractTempleteParam({
  param,
  clauseId,
  subClauseId,
  currentValue,
  handleInputChange,
  control,
  register,
}:
  {
    handleInputChange: (
      e: any,
      paramName: string,
      clauseId: ClauseEntity['id'],
      subClauseId: SubClauseEntity['id'],
    ) => void;
    param: ClauseParam;
    clauseId: ClauseEntity['id'];
    subClauseId: SubClauseEntity['id'];
    currentValue: any,
    control: Control<FieldValues, any>;
    register: UseFormRegister<FieldValues>;
  }) {
  const { combinedTemplateParams, onParamChanged, onParamDelete, onOptionAdd, onOptionChanged, onOptionDelete, onParamTypeChange } = useContext(EditContractTemplateContext);
  const [isFocused, setIsFocused] = useState(false);
  const { fileNames } = useContext(InputValuesContext);
  const fieldName = `${clauseId}_${subClauseId}_${param.name}`;
  const [isOptionEditing, setIsOptionEditing] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { t, language } = useTranslation();
  const translationPath = t("pages.contractTemplateParam.edition")
  switch (param.type) {
    // Inside the switch block of ContractTempleteParam function
    case 'beneficial':
    case 'beneficial[]':
      return <>
        <div className="form-group">
          <div
            className={`d-flex flex-column grey-border`}
            style={{ paddingRight: language == "ar" && "5px" }}
          >
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{param.type}</div>

            <div className="d-flex align-items-start justify-content-between py-2 px-2">
              {!isOptionEditing ? <label className="ms-2">{param?.label}</label> : (
                <textarea
                  className="label-input-edit"
                  value={param?.label}
                  onChange={(e) =>
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      label: e.target.value,
                    })
                  }
                />
              )
              }
            </div>
            <div className="checkbox-beneficial">
              <input
                type="checkbox"
                checked={param?.args?.beneficialTypes.includes('Person')}
                name={'Person'}
                onChange={(e) => {
                  const updatedParam = { ...param };
                  if (updatedParam.args) {
                    updatedParam.args.beneficialTypes = e.target.checked
                      ? [...new Set(['Person'])].sort() as any
                      : updatedParam.args.beneficialTypes?.filter(i => i !== 'Person');
                    onParamChanged(clauseId, subClauseId, updatedParam);
                  }
                }}

                disabled={false}
              />
              <label htmlFor="Person"> {t(translationPath.beneficial.person)} </label>
            </div>
            <div className="checkbox-beneficial">
              <input
                type="checkbox"
                checked={param.args?.beneficialTypes.includes('Company')}
                onChange={(e) => {
                  const updatedParam = { ...param };
                  if (updatedParam.args) {
                    updatedParam.args.beneficialTypes = e.target.checked
                      ? [...new Set(['Company'])].sort() as any
                      : updatedParam.args.beneficialTypes?.filter(i => i !== 'Company');
                    onParamChanged(clauseId, subClauseId, updatedParam);
                  }
                }}

                disabled={false}
              />
              <label htmlFor="Company"> {t(translationPath.beneficial.company)} </label>
            </div>
          </div>
        </div>
      </>
    case 'table':
      return (
        <div
          className={`d-flex flex-column grey-border`}
          style={{ paddingRight: language == "ar" && "5px" }}
        >
          <div className="d-flex flex-column">
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{param.type}</div>
            {!isOptionEditing ? (
              <>
                <label className="mt-2 px-3 mb-2" htmlFor={param.name}>
                  {param.label}
                </label>
                <div className="d-flex px-3">
                  <input
                    type="checkbox"
                    checked={param.transposed}
                    onChange={(e) =>
                      onParamChanged(clauseId, subClauseId, {
                        ...param,
                        transposed: e.target.checked
                      })
                    }
                  />
                  <label className="px-2" style={{ color: "#1f2b3a", fontWeight: "400" }}> {t(translationPath.table.transposed)} </label>
                </div>
                <div className="d-flex container-table-element mb-1 mt-1">
                  <span className="header-table-edit"> {t(translationPath.table.colonnes)} </span>
                  <span className="header-table-edit">{t(translationPath.table.typesTitle)}</span>
                </div>
                {param.args && param.args.map((arg, index) => (
                  <div key={index} className="d-flex  container-table-element">
                    <p> {arg.header}</p>
                    <p>{arg.type}</p>
                  </div>
                ))}
              </>
            ) : (
              <>
                <textarea
                  className="label-input-edit mt-3"
                  style={{ marginLeft: "8px" }}
                  value={param.label}
                  name={param.name}
                  onChange={(e) =>
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      label: e.target.value,
                    })
                  }
                />
              </>
            )}
          </div>
          {param.args && param.args.map((arg, index) => {
            return (
              isOptionEditing &&
              (<div className="d-flex justify-content-between  align-items-center px-2 mb-1 mt-2" style={{ width: "90%" }}>
                <input
                  className="label-input-edit"
                  style={{ height: "30px", width: "50%" }}
                  value={arg.header}
                  onChange={(e) =>
                    {console.log(e.target.value)
                      console.log({
                        ...param,
                        args: param.args.map((arg, i) =>
                          i === index
                            ? { ...arg, header: e.target.value }
                            : arg
                        ),
                      })
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    })}
                  }
                />
                <select
                  value={arg.type}
                  style={{ height: "30px", width: "30%" }}
                  className="label-input-edit"
                  onChange={(e) =>
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      args: param.args ? param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, type: e.target.value as "string" | "number" | "date" }
                          : arg
                      ) : []
                    })
                  }
                >
                  <option value="string">  {t(translationPath.table.types.string)} </option>
                  <option value="number"> {t(translationPath.table.types.number)}</option>
                  <option value="date"> {t(translationPath.table.types.date)}</option>
                </select>

                <img src={Delete} className="delete-single-option" onClick={() => onOptionDelete(clauseId, subClauseId, param, index)} />
              </div>
              )
            );
          })}
          {isOptionEditing && <p className="add-single-option" style={{ marginLeft: "8px" }}
            onClick={() => { onOptionAdd(clauseId, subClauseId, param, { header: "", type: 'string' }); }}
          >+</p>}
        </div>
      );
    case 'boolean':
      return (
        <div
          className={`d-flex flex-column grey-border`}
          style={{ paddingRight: language == "ar" && "5px" }}
        >
          <div className="d-flex flex-column">
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{param.type}</div>
            <div className="d-flex flex-column gap-2  py-2 justify-content-between  align-items-center form-group">
              {!isOptionEditing ? <label className="ms-3 mt-3 " htmlFor={param.name}>{param.label}</label> : <textarea
                className="label-input-edit"
                value={param.label}
                name={param.name}
                onChange={(e) =>
                  onParamChanged(clauseId, subClauseId, {
                    ...param,
                    label: e.target.value,
                  })
                }
              />}
              <div className="radio-group boolean-group">
                <label className="form-check-label">
                  <input
                    type="radio"
                    value="true"
                    checked={currentValue}
                    className="form-check-input boolean-check"
                    onChange={() => {
                      if (!param.args.textIfTrue)
                        onOptionAdd(clauseId, subClauseId, param, true)
                      handleInputChange(
                        { target: { value: true } },
                        param.name,
                        clauseId,
                        subClauseId,
                      );
                      onParamChanged(clauseId, subClauseId, param)
                    }}
                    name={param.name}
                  />
                  {t(translationPath.boolean.oui)}
                </label>
                <label className="form-check-label">
                  <input
                    type="radio"
                    value="false"
                    checked={!currentValue}
                    className="form-check-input boolean-check"
                    onChange={() => {
                      if (!param.args.textIfFalse)
                        onOptionAdd(clauseId, subClauseId, param, false)
                      handleInputChange(
                        { target: { value: false } },
                        param.name,
                        clauseId,
                        subClauseId,
                      );
                      onParamChanged(clauseId, subClauseId, param)
                    }}
                    name={param.name}
                  />
                  {t(translationPath.boolean.non)}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    case 'date':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{ marginBottom: calendarOpen && "280px", paddingRight: language == "ar" && "5px" }}
        > <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{param.type}</div>
          {!isOptionEditing ? <label htmlFor={param.name} className="ms-1 px-2">
            {param.label ?? "JJ/MM/AAAA"}
          </label> : <textarea
            className="label-input-edit mt-3"
            style={{ marginLeft: "8px" }}
            value={param.label}
            name={param.name}
            onChange={(e) =>
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }
          />}
          <div className="d-flex gap-2 align-items-center">
            <div className="w-100 px-3">
              <div className="date-disabled">
                JJ/MM/AAAA
              </div>
            </div>
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      return (
        <div className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"}`} style={{ paddingRight: language == "ar" && "5px" }}>
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{param.type}</div>
          {!isOptionEditing ? <label htmlFor={param.name} className="ps-3 mt-2 mb-2">
            {param.label}
          </label> : <textarea
            className="label-input-edit mt-3"
            style={{ marginLeft: "8px", marginBottom: "10px" }}
            value={param.label}
            name={param.name}
            onChange={(e) =>
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }
          />}
        </div>
      );
    case 'csv':
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`} style={{ paddingRight: language == "ar" && "5px" }}>
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{param.type}</div>
          {!isOptionEditing ? <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label> : <textarea
            className="label-input-edit mt-3"
            style={{ marginLeft: "8px" }}
            value={param.label}
            name={param.name}
            onChange={(e) =>
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }
          />}
        </div>
      );
    case 'list':
      return (
        <div className="grey-border" style={{ paddingRight: language == "ar" && "5px" }}>
          <div className="content-action-options" style={{ marginLeft: "78%", marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{param.type}</div>
          {!isOptionEditing ? <label className="ms-3 mt-3 " htmlFor={param.name}>{param.label}</label> : <textarea
            className="label-input-edit mt-3"
            style={{ marginLeft: "15px" }}
            value={param.label}
            name={param.name}
            onChange={(e) =>
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }
          />}
          <div style={{ padding: "30px" }}>
            {param.args && param.args.map((arg, index) => {
              return (
                !isOptionEditing ? (
                  <ListInputItem
                    key={index}
                    index={index}
                    handleInputChange={handleInputChange}
                    clauseId={clauseId}
                    subClauseId={subClauseId}
                    control={control}
                    fieldName={fieldName}
                    param={param}
                    isEditing={false}
                    editable={false}
                  />
                ) : (
                  <div className="d-flex w-100 param-list-container">
                    <div className="d-flex flex-column w-100 mb-2">
                      <textarea
                        className="label-input-edit mt-2"
                        style={{ height: "50px" }}
                        value={arg.option}
                        onChange={(e) =>
                          onOptionChanged(clauseId, subClauseId, param, { ...param.args[index], option: e.target.value }, index)
                        }
                      />
                      <div style={{ marginTop: "10px", gap: "5px" }} className="d-flex">
                        <input
                          type="checkbox"
                          checked={arg.required}
                          onChange={(e) =>
                            onOptionChanged(clauseId, subClauseId, param, { ...param.args[index], required: e.target.checked }, index)
                          }
                        />
                        <label> {t(translationPath.list.required)} </label>
                      </div>
                    </div>
                    <img src={Delete} style={{ marginLeft: language != "ar" && "-15px", marginTop: "-30px" }} className="delete-single-option" onClick={() => onOptionDelete(clauseId, subClauseId, param, index)} />
                  </div>
                )
              );
            })}
            {isOptionEditing && <p className="add-single-option" style={{ marginLeft: "0px" }} onClick={() => { onOptionAdd(clauseId, subClauseId, param, { option: "", required: false }); }}>+</p>}

          </div>

        </div>
      );
    case 'enum':
      const enumOptions = param.args && param.args.map((arg) => arg.option);
      const hasValue = null;
      return (
        <>
          <div className="form-group">
            <div
              className={`d-flex flex-column  ${!hasValue ? "grey-border" : "green-border"
                }`}
              style={{ paddingRight: language == "ar" && "5px" }}
            > <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
                <img
                  className="edit-action-option pointer"
                  src={isOptionEditing ? EditIconOn : EditIcon}
                  onClick={() => {
                    setIsOptionEditing(!isOptionEditing);
                  }}
                />
                <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
              </div>
              <div className="param-type ms-2">{param.type}</div>
              <div className="d-flex align-items-start justify-content-between py-2 px-2">
                {!isOptionEditing ? <label className="ms-2">{param.label}</label> : <textarea
                  className="label-input-edit"
                  style={{ marginLeft: "8px" }}
                  value={param.label}
                  name={param.name}
                  onChange={(e) =>
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      label: e.target.value,
                    })
                  }
                />}
              </div>
              <div className=" flex-column align-items-start px-3 pb-1 gap-0">
                {enumOptions?.map((option, index) => (
                  <div key={`${fieldName}_${index}`} className="form-check">
                    <div style={{ display: isOptionEditing ? "flex" : "" }}>
                      {!isOptionEditing ? <input
                        type={"radio"}
                        className={"form-check-input"}
                        value={!isOptionEditing ? index : option}
                        checked={currentValue === index}
                        onChange={(e) => {
                          handleInputChange(
                            { target: { value: index } },
                            param.name,
                            clauseId,
                            subClauseId
                          )
                        }}
                      /> : <textarea
                        className="label-input-edit mt-2"
                        style={{ marginLeft: "-15px" }}
                        value={option}
                        onChange={(e) =>
                          onOptionChanged(clauseId, subClauseId, param, e.target.value, index)
                        }
                      />}
                      {!isOptionEditing ? <label className="form-check-label" htmlFor={String(index)}>
                        {option}
                      </label> : <img src={Delete} style={{ marginRight: language == "ar" && "20px" }} className="delete-single-option" onClick={() => onOptionDelete(clauseId, subClauseId, param, index)} />}
                    </div>
                  </div>
                ))}
              </div>
              {isOptionEditing && <p className="add-enum-single-option" onClick={() => { onOptionAdd(clauseId, subClauseId, param, { option: "", text: "" }); }}>+ {t("pages.contractTemplateParam.edition.enum.option")}</p>}
            </div>
          </div>
        </>
      );
    default:
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <ParamCombobox param={param} clauseId={clauseId} subClauseId={subClauseId} changeParamType={onParamTypeChange} />

          <div className="d-flex gap-2 align-items-center">
            {!isOptionEditing ? <label
              className="ms-3 mt-3"
            >{param.label}</label> : (
              <textarea
                className="label-input-edit mt-2 mb-2"
                style={{ marginLeft: "8px" }}
                value={param.label}
                name={param.name}
                onChange={(e) =>
                  onParamChanged(clauseId, subClauseId, {
                    ...param,
                    label: e.target.value,
                  })
                }
              />
            )}
          </div>
        </div>
      );
  }
}
export default ContractTempleteParam;
