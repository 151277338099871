import React, { useState } from "react";
import Loading from "../../common/Loading";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { ContractTemplateEntity, ProjectEntity, UserEntity } from "../../../domain/entities";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteMessage.scss";
import { toast } from "react-toastify";
import { UserClient } from "../../../services/api/UserClient";
import { ProjectClient } from "../../../services/api/ProjectClient";
interface ConfirmDeleteMessageProps {
  onClose: (value: boolean) => void;
  msgDeleteUser?: any;
  template?: ContractTemplateEntity;
  reloadTemplates?: () => void;
  setNewUser?: (value: boolean) => void;
  user?: UserEntity;
  type?:string,
  messageDelete?:string,
  project?:ProjectEntity
  setNewChange?: (value: boolean) => void;
}
function ConfirmDeleteMessage({
  msgDeleteUser = "",
  template = {} as ContractTemplateEntity,
  onClose = () => {},
  reloadTemplates = () => {},
  setNewUser = () => {},
  user,
  type, 
  messageDelete,
  project,
  setNewChange
}: ConfirmDeleteMessageProps) {
  const [loading, setLoading] = useState(false);
  const [msgModal, setMessageModal] = useState("");
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const projectClient = new ProjectClient(apiClient);
  const userClient = new UserClient(apiClient);

  const handleDelete = async (template: ContractTemplateEntity) => {
    setLoading(true);
    try {
      const { deleted } = await contractTemplateClient.delete(template.id);
      if (deleted) {
        await reloadTemplates();
        onClose(false);
        toast.success(t("modals.confirmDelete.messages.success"));
      } else {
        setMessageModal(t("modals.confirmDelete.inactiveMessage"));
      }
    } catch (error) {
      console.error("Error deleting template: ", error);
      toast.error(t("modals.confirmDelete.messages.error"));
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await userClient.delete(id);
      if (deleted) {
        setNewUser(true);
        setLoading(false);
        onClose(false);
        toast.success(t("pages.users.popup.messages.successDelete"))
      }
    } catch (err) {
      toast.error(t("pages.users.popup.messages.errorDelete"))

    }
  };
  const handleDeleteProject = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await projectClient.delete(id);
      if (deleted) {
        setNewChange(true)
        onClose(false);
        toast.success(t("pages.viewProject.messages.successDelete"))
      }
    } catch (err) {
      toast.error(t("pages.viewProject.messages.errorDelete"))

    }
  };

  const handleMakeInactive = async (template: ContractTemplateEntity) => {
    setLoading(true);
    try {
      const updateTemplate = await contractTemplateClient.update(template.id, {
        ...template,
        activated: false,
      });
      if (updateTemplate) {
        await reloadTemplates();
      }
      onClose(false);
      toast.success(t("modals.confirmDelete.messages.inactive"));
    } catch (error) {
      console.error("Error updating template: ", error);
      toast.error(t("modals.confirmDelete.messages.inactiveError"));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if(messageDelete){
      setMessageModal(
        messageDelete +
          " " +
          project?.name +
          `${language !== "ar" ? " ?" : " ؟"}`
      );
    }else{
      if (msgDeleteUser) {
        setMessageModal(
          msgDeleteUser +
            " " +
            user.fullName +
            `${language !== "ar" ? " ?" : " ؟"}`
        );
      } else {
        setMessageModal(
          `${t("modals.confirmDelete.deleteMessage")}${template.name}${
            language !== "ar" ? "?" : " ؟"
          }`
        );
      }
    }
    
  }, [template, t, language, project]);

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">{msgModal}</h1>
                    <div className="modal-buttons">
                      {!msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                        <button
                          className="btn submit-delete"
                          onClick={() => {
                            msgDeleteUser
                              ? handleDeleteUser(user?.id)
                              : messageDelete ? handleDeleteProject(project?.id):handleDelete(template) 
                          }}
                        >
                          {t("modals.confirmDelete.delete")}
                        </button>
                      )}
                      {msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                        <button
                          className="btn  make-inactive"
                          onClick={() => handleMakeInactive(template)}
                          style={{ marginLeft: "20px" }}
                        >
                          {t("modals.confirmDelete.makeInactive")}
                        </button>
                      )}
                      <button
                        onClick={() => onClose(false)}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteMessage;
