import React, { useEffect, useContext, useState, useMemo } from "react";
import { ContractEntity } from "../../../domain/entities";
import { ContractTemplateParsedData, ParsedClauseParam, extractOptionalClauses, extractOptionalSubclauses, generateCombinedParams } from "../../../helpers/ParseTemplateData";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { isClauseExcluded, isSubClauseExcluded } from "../../../helpers/helper";
import { getRenderedParams } from "../../../helpers/renderedParams";
import { prepareTableData } from "../../../helpers/Analytics"
import { mergeParams } from "../../../helpers/ParseTemplateData";
import { useTranslation } from "../../../contexts/TranslationProvider";

function InputList({
    templateData,
    contractName,
    isEditing,
    paramValues,
    initialExcludedClauses,
    initialExcludedSubClauses,
}: {
    templateData: ContractTemplateParsedData,
    contractName: ContractEntity['name'],
    isEditing: boolean,
    paramValues: ContractEntity['paramValues'],
    initialExcludedClauses: ContractEntity['excludedClauses'],
    initialExcludedSubClauses: ContractEntity['excludedSubClauses'],
}) {

    const [data, setData] = useState([])

    const {
        inputValues,
        setInputValues,
        fileNames,
        beneficialsMap,
        excludedClauses,
        setExcludedClauses,
        excludedSubClauses,
        setExcludedSubClauses,
    } = useContext(InputValuesContext);
    const {t, language}=useTranslation()


    const combinedParams: ParsedClauseParam[] = useMemo(() => {
        const renderedParamNames = []
        templateData
            ?.filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
            ?.map((clause) => {
                renderedParamNames.push(...getRenderedParams(clause.rawText[0], inputValues, fileNames, beneficialsMap, clause.params, clause.code))
                clause.subClauses
                    .filter((subClause) => !isSubClauseExcluded(subClause.id, excludedSubClauses))
                    .map((subClause) => {
                        renderedParamNames.push(...getRenderedParams(subClause.rawText[0], inputValues, fileNames, beneficialsMap, subClause.params, clause.code, subClause.code))
                    });
            });

        const combinedParams = generateCombinedParams(templateData, isEditing, paramValues).map((param) => {
            param.rendered = renderedParamNames.includes(param.name);
            return param;
        });
        return combinedParams;
    }, [templateData, isEditing, paramValues, inputValues, excludedClauses, excludedSubClauses]);

    useEffect(() => {
        if (isEditing) {
            const newInputValues = {};
            combinedParams.forEach((param) => {
                const { name, value } = param;
                newInputValues[name] = value;

            });
            setInputValues(newInputValues);
            setExcludedClauses(initialExcludedClauses);
            setExcludedSubClauses(initialExcludedSubClauses);
        }
    }, [isEditing]);
    useEffect(() => {
        if (!inputValues)
            return
        const params = mergeParams(templateData)
        setData(prepareTableData(params, inputValues, fileNames, beneficialsMap))
    }, [inputValues])

    return (
        <>
            <div key={0} className="contract-header" style={{ marginTop: "20px", display: "flex", flexDirection: "row", borderBottom: "1px solid #DBDFE", paddingRight: "0px" }}>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }} >{t("modals.contractAnalysis.inputList.codeClause")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.nomClause")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.codeSousClause")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.nomSousClause")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.nomParametre")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.typeParametre")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.labelParametre")}</span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>{t("modals.contractAnalysis.inputList.valeurParametre")}</span>
                </div>
            </div>
            <div className="contract-rows">
                {data.map((row, index) => {
                    return <div key={index} className="contract-row" style={{ marginTop: "20px", display: "flex", flexDirection: "row", borderBottom: "1px solid #DBDFE" }}>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span >{row[0]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[1]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[2]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[3]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[4]}</span>
                        </div>

                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[5]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[6]}</span>
                        </div>
                        <div className="table-cell" style={{ flex: 1 }}>
                            <span>{row[7]}</span>
                        </div>
                    </div>
                })}
            </div>

        </>
    );
}

export default InputList;
