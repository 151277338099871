import React from 'react'
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getResetPassword } from '../../services/api/UserAuth';
import { resetPassword } from '../../services/api/UserAuth';
import CarouselContainer from '../../components/common/Carousel';
import "./resetPassword.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
interface FormData {
    password: string,
    confirmPassword: string,
}
const ResetPassword = () => {
    const {t, language}=useTranslation()
    const translationPath=t("pages.resetPassword")
    const location = useLocation();
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search);
    const resetPasswordToken = params.get('resetPasswordToken');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);


    useEffect(() => {
        const checkResetPasswordToken = async () => {
            try {
                const { user } = await getResetPassword(resetPasswordToken);
                console.log("Reset password for user:", user);
            } catch (error) {
                console.error("Invalid or expired reset password token:", error);
                navigate("/forget-password");
            }
        };
        checkResetPasswordToken();
    }, [resetPasswordToken, navigate]);
    const [formData, setFormData] = useState<FormData>({
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState<Record<string, string>>({
        password: '',
        confirmPassword: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newErrors: Record<string, string> = {};

        if (!formData.password.trim()) {
            newErrors.password =  t(translationPath.errorMessages.emptyPassword);
        }

        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword =  t(translationPath.errorMessages.emptyConfirmPassword);
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword =  t(translationPath.errorMessages.passwordMismatch);
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const user = await resetPassword(formData.password, resetPasswordToken);
                console.log('User password reset successfully:', user);
                setPasswordResetSuccess(true);
            } catch (error) {
                console.error('Error resetting password:', error);
            }
        }

    };
    return (
        <div className='reset-password-content' style={{paddingRight:language=="ar"&&"100px"}}>
            <div className='sign-up-sub-content'>
                <div className='logo-contractzlab'></div>
                <h1 className='title-sign-up'>{t(translationPath.title)}</h1>
                {passwordResetSuccess && (
                    <div className="alert alert-success" style={{ width: "76%" }}>
                        {t(translationPath.successMessage)}
                        <br />
                        <Link to='/sign-in' className='btn-login'>
                            {t(translationPath.signInLink)}
                        </Link>
                    </div>
                )}
                <form onSubmit={handleSubmit} style={{ marginTop: '15%' }}>
                    <div className="form-group signup-group">
                        <label htmlFor="password" className="label-sign-up-form">
                        {t(translationPath.passwordLabel)}
                        </label>
                        <input
                            type="password"
                            className={`form-control input-sign-up ${errors.password ? 'is-invalid' : ''}`}
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                    </div>

                    <div className="form-group signup-group">
                        <label htmlFor="confirmPassword" className="label-sign-up-form">
                        {t(translationPath.confirmPasswordLabel)}
                        </label>
                        <input
                            type="password"
                            className={`form-control input-sign-up ${errors.confirmPassword ? 'is-invalid' : ''}`}
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                        />
                        {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}

                    </div>

                    <button type="submit" className="btn btn-primary btn-signup">
                       {t(translationPath.signUpButton)}
                    </button>
                </form>
            </div>
            <CarouselContainer/>
        </div>
    )
}

export default ResetPassword