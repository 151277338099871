/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useParams } from "react-router-dom";
import "./contractTemplatePage.scss";
import Loading from "../../components/common/Loading";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity, InitialContractTemplateEntity, GroupEntity } from "../../domain/entities";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import { LoadingContext } from "../../contexts/LoadingContext";
import EditContractTemplate from "./components/EditContractTemplate";
import EditContractTemplateContext, { EditContractTemplateContextType } from "../../contexts/EditContractTemplateContext";
import { ClauseParam, EnumOption, ListOption, TableLigne, SegmentedClauseParam } from "../../domain/types/ClauseParams";
import { compileSegmentationAndParams, updateSegment, insertParamInSegment, SegmentTextAndParams, genIdFactory, cleanSegmentation, deleteSegment, compileSegmentation, fixTemplateIndexation } from "../../domain/ContractTemplate";
import { ContractTemplateClient } from "../../services/api/ContractTemplateClient";
import { InitialContractTemplateClient } from "../../services/api/InitialContractTemplateClient"
import { useTranslation } from "../../contexts/TranslationProvider";
import EventManager from "../../services/EventManager";
import { toast } from "react-toastify";
import { set } from "react-hook-form";
import { normalizeName } from "../../utils/string";
import { GroupClient } from "../../services/api/GroupClient";


function ContractTemplatePage() {
	const location = useLocation();
	const { isLoading, setLoading } = useContext(LoadingContext);
	const { setLanguage, language, t } = useTranslation();
	const translationPath = "pages.contractTemplateParam."


	const [contractTemplate, setContractTemplate] = useState<ContractTemplateEntity>(null);
	const [initialTemplate, setInitialTemplate] = useState<InitialContractTemplateEntity>(null);
	const [combinedTemplateParams, setCombinedTemplateParams] = useState<ClauseParam[]>([]);
	const [groups, setGroups] = useState<GroupEntity[]>([]);
	const [previewValues, setPreviewValues] = useState<ContractEntity['paramValues']>({});

	const { contractTemplateId } = useParams<{ contractTemplateId?: string }>() || {};
	const apiClient = apiClientWithLoading(setLoading);
	const contractTemplateClient = new ContractTemplateClient(apiClient)
	const initialContractTemplateClient = new InitialContractTemplateClient(apiClient)
	const groupClient = new GroupClient(apiClient)
	const onSelectGroupInClause = async (index: string, newGroups: GroupEntity[]) => {
    newGroups?.forEach(newGroup => {
        let group = contractTemplate.groups.find(g => g.id === newGroup.id);

        if (group) {
            if (!group.Group_ContractTemplate.clauseCodes.includes(index)) {
                group.Group_ContractTemplate.clauseCodes.push(index);
            }
        } else {
            contractTemplate?.groups?.push({
                ...newGroup,
                Group_ContractTemplate: {
                    clauseCodes: [index]
                }
            });
        }
    });
		contractTemplate.groups.forEach(group => {
				if (!newGroups.some(newGroup => newGroup.id === group.id)) {
					group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.filter(code => code !== index);
				}
			});
    contractTemplate.groups = contractTemplate?.groups?.filter(group =>
        group?.Group_ContractTemplate?.clauseCodes?.length > 0
    );
    const updatedContractTemplate = { ...contractTemplate };

    try {
        const result = await contractTemplateClient.update(contractTemplate.id, updatedContractTemplate);
        if (result) {
            setContractTemplate(updatedContractTemplate);
        }
        return result;
    } catch (error) {
        console.error("Failed to update the contract template:", error);
        return null;
    }
};

	const prepareTemplate = (template: ContractTemplateEntity) => {
		const clauses = template.clauses?.map(clause => {
			const newClause = { ...clause }
			newClause.params = clause.params ?? []
			newClause.rawText = [clause.rawText[0] == "" ? '-' : clause.rawText[0]]
			newClause.segmentation = SegmentTextAndParams(newClause.rawText[0], clause.params ?? [], genIdFactory(clause.index))
			newClause.subClauses = clause.subClauses?.map(subClause => {
				const newSubClause = { ...subClause }
				newSubClause.params = subClause.params ?? []
				newSubClause.rawText = [subClause.rawText[0] == "" ? '-' : subClause.rawText[0]]
				newSubClause.segmentation = SegmentTextAndParams(newSubClause.rawText[0], subClause.params ?? [], genIdFactory(subClause.index))
				return newSubClause
			})
			return newClause
		})
		return { ...template, clauses }
	}
	useEffect(() => {
		(async () => {
			const row = await contractTemplateClient.getById(parseInt(contractTemplateId));
			if (!row) return;
			if (row.languages) {
				const { languages } = row;
				if (languages?.length !== 0 && (!(languages as string[]).includes(language))) {
					setLanguage(languages[0]);
				}
			}
			const groupData = await groupClient.getAll();
			setGroups(groupData.rows);
			setContractTemplate(prepareTemplate(row));
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const result = await initialContractTemplateClient.getOriginal(parseInt(contractTemplateId));
				if (!result) return;
				setInitialTemplate((result as any).row);
			} catch (e) {
				console.error(e)
			}
		})();
	}, []);

	useEffect(() => {
		if (!contractTemplate) return;
		// combine params in contractTemplate
		const combinedParams = contractTemplate.clauses.reduce((acc, clause) => {
			const clauseParams = clause.params
			acc.push(...clauseParams);

			clause.subClauses?.forEach(subClause => {
				const subClauseParams = subClause.params
				acc.push(...subClauseParams);
			});

			return acc;
		}, [] as ClauseParam[]);

		// Filter out duplicate parameters based on the 'name' property
		const uniqueParams = combinedParams.reduce((acc, param) => {
			if (!acc.some(p => p.name === param.name)) {
				acc.push(param);
			}
			return acc;
		}, [] as ClauseParam[]);

		setCombinedTemplateParams(uniqueParams);

	}, [contractTemplate]);

	const updateInitialTemplate = async () => {
		try {
			const result = await initialContractTemplateClient.getOriginal(parseInt(contractTemplateId));
			if (!result) return;
			setInitialTemplate((result as any).row);
		} catch (e) {
			console.error(e)
		}
	}

	const insertClauseInContractTemplate = async (name) => {
		const result = await contractTemplateClient.createClause(parseInt(contractTemplateId), name);
		if (!result.row) return;
		return result.row
	}

	const insertExistingClauseInContractTemplate = async (clauseId: ClauseEntity['id'], newName: string) => {
		const result = await contractTemplateClient.addExistingClause(parseInt(contractTemplateId), clauseId, newName);
		if (!result.row) return;
		return result.row
	}

	const insertSubClauseInContractTemplate = async (clauseId: ClauseEntity['id'], name: string) => {
		const result = await contractTemplateClient.createSubClause(clauseId, name);
		if (!result.row) return;
		return result.row
	}

	const fixClauseIndexation = (clause: ClauseEntity) => {
		const newSubClauses = clause.subClauses?.map((subClause, index) => {
			subClause.index = (index + 1).toString()
			subClause.segmentation = SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory((index + 1).toString()))
			return subClause
		})
		return { ...clause, subClauses: newSubClauses }
	}

	const onParamChanged = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let params: ClauseEntity['params']
		let clauseIdx: number
		let subClauseIdx: number
		let paramIdx: number
		let segParamIdx: number
		let segmentation: ClauseEntity['segmentation']
		let rawText: ClauseEntity['rawText']

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			params = subClause.params
			rawText = subClause.rawText
			segmentation = subClause.segmentation
		} else {
			params = clause.params
			rawText = clause.rawText
			segmentation = clause.segmentation
		}

		paramIdx = params.findIndex(p => p.name == param.name)
		params[paramIdx] = param

		if (subClauseId) {
			segmentation = SegmentTextAndParams(rawText[0], params, genIdFactory(subClause.index))
			subClause.params = [...params]
			subClause.segmentation = segmentation
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.params = [...params]
			segmentation = SegmentTextAndParams(rawText[0], params, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTempaleValue = { ...contractTemplate }
		if(param.type == "beneficial"){
			// update all params with the same name and type
			newContractTempaleValue.clauses.forEach(clause => {
				const clauseParams = clause.params.map(p => {
					if(p.name == param.name && p.type == param.type){
						return param
					}
					return p
				})
				clause.params = clauseParams
				clause.segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
				clause.subClauses.map(subClause => {
					const subClauseParams = subClause.params.map(p => {
						if(p.name == param.name && p.type == param.type){
							return param
						}
						return p
					})
					subClause.params = subClauseParams
					subClause.segmentation = SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory(subClause.index))
					return subClause
				})
			})
		}
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)
	}
	const onParamDelete = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: any | ClauseParam, index?: number) => {
		const newContractTemplateValue = { ...contractTemplate };
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			const clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					const subClause = { ...clause.subClauses[subClauseIdx] };
					const newParams = subClause.params.filter(parameter => parameter.name !== param.name)
					const newSegmentedParams = subClause.segmentation.segmentedParams.filter(segmentedParam => segmentedParam.name !== param.name)
					const newText = compileSegmentation(subClause.segmentation.segmentedText, newSegmentedParams)
					const newSegmentation = SegmentTextAndParams(newText, newParams, genIdFactory(subClause.index))
					const { text, params } = compileSegmentationAndParams(newSegmentation.segmentedText, newSegmentation.segmentedParams)
					subClause.params = [...params]
					subClause.rawText = [text]
					subClause.segmentation = newSegmentation
					clause.subClauses[subClauseIdx] = subClause;
					clause.subClauses = [...clause.subClauses];
				}
			} else {
				const newParams = clause.params.filter(parameter => parameter.name !== param.name)
				const newSegmentedParams = clause.segmentation.segmentedParams.filter(segmentedParam => segmentedParam.name !== param.name)
				const newText = compileSegmentation(clause.segmentation.segmentedText, newSegmentedParams)
				const newSegmentation = SegmentTextAndParams(newText, newParams, genIdFactory(clause.index))
				const { text, params } = compileSegmentationAndParams(newSegmentation.segmentedText, newSegmentation.segmentedParams)
				clause.params = [...params]
				clause.rawText = [text]
				clause.segmentation = newSegmentation
			}

			newContractTemplateValue.clauses[clauseIdx] = clause;
			setContractTemplate(newContractTemplateValue);
		}
	};
	const onParamTypeChange = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, type: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let params: ClauseEntity['params']
		let clauseIdx: number
		let subClauseIdx: number
		let paramIdx: number
		let segParamIdx: number
		let segmentedParams: ClauseEntity['segmentation']['segmentedParams']

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			params = subClause.params
			segmentedParams = subClause.segmentation.segmentedParams
		} else {
			params = clause.params
			segmentedParams = clause.segmentation.segmentedParams
		}

		// remove the param 
		paramIdx = params.findIndex(p => p.name == param.name)
		params.splice(paramIdx, 1)

		// create the new param with the new type and same name and label and push it at the exact position
		switch (type) {
			case "boolean":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "boolean", args: { textIfTrue: "[ ]", textIfFalse: "[ ]" }, definition: 0 })
				break;
			case "enum":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "enum", args: [], definition: 0 })
				break;
			case "list":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "list", args: [] })
				break;
			case "table":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "table", args: [], transposed: false })
				break;
			case "number":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "number" })
				break;
			case "date":
				params.splice(paramIdx, 0, { name: param.name, label: param.label, type: "date" })
				break;
			default:
				break;
		}

		if (subClauseId) {
			const newSegmentation = SegmentTextAndParams(subClause.rawText[0], params, genIdFactory(subClause.index))
			subClause.params = [...params]
			subClause.segmentation.segmentedParams = [...newSegmentation.segmentedParams]
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			const newSegmentation = SegmentTextAndParams(clause.rawText[0], params, genIdFactory(clause.index))
			clause.params = [...params]
			clause.segmentation.segmentedParams = [...newSegmentation.segmentedParams]
		}

		let newContractTempaleValue = { ...contractTemplate }
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)
	}
	const onOptionDelete = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, index: number) => {
		let newContractTemplateValue = { ...contractTemplate };
		let segmentation: ClauseEntity['segmentation']
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			let clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					let subClause = { ...clause.subClauses[subClauseIdx] };

					if (param.type === "enum") {
						if (!param.args) {
							param.args = [];
						}
						param.args = param.args.filter((p, i) => i !== index);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "list") {
						if (!param.args) {
							param.args = [];
						}
						param.args = param.args.filter((p, i) => i !== index);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "table") {
						if (!param.args) {
							param.args = [];
						}
						param.args = param.args.filter((p, i) => i !== index);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
				}

			} else {
				if (param.type === "enum") {
					if (!param.args) {
						param.args = [];
					}
					param.args = param.args.filter((p, i) => i !== index);
				}
				if (param.type === "list") {
					if (!param.args) {
						param.args = [];
					}
					param.args = param.args.filter((p, i) => i !== index);
				}
				if (param.type === "table") {
					if (!param.args) {
						param.args = [];
					}
					param.args = param.args.filter((p, i) => i !== index);
				}
			}
			segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
			clause.segmentation = segmentation
			newContractTemplateValue.clauses[clauseIdx] = clause;
			setContractTemplate(newContractTemplateValue);
		}
	}

	const onOptionChanged = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, option: any, index: number) => {
		let newContractTemplateValue = { ...contractTemplate };

		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			let clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					let subClause = { ...clause.subClauses[subClauseIdx] };

					if (param.type === "enum") {
						if (!param.args) {
							param.args = [];
						}
						param.args[index].option = option;
						if (!param.args[index].text)
							param.args[index].text = '[ ]';
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "list") {
						if (!param.args) {
							param.args = [];
						}
						param.args[index] = option;
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					const segmentation = SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory(subClause.index))
					subClause.segmentation = segmentation
					clause.subClauses[subClauseIdx] = subClause;
				}
			} else {
				if (param.type === "enum") {
					if (!param.args) {
						param.args = [];
					}
					param.args[index].option = option;
					if (!param.args[index].text)
						param.args[index].text = '[ ]';
				}
				if (param.type === "list") {
					if (!param.args) {
						param.args = [];
					}
					param.args[index] = option;
				}
			}
			const segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
			clause.segmentation = segmentation
			newContractTemplateValue.clauses[clauseIdx] = clause;
			setContractTemplate(newContractTemplateValue);
		}
	}

	const onOptionAdd = (
		clauseId: ClauseEntity['id'],
		subClauseId: SubClauseEntity['id'],
		param: ClauseParam,
		option: EnumOption | ListOption | TableLigne | boolean
	) => {
		let newContractTemplateValue = { ...contractTemplate };

		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			let clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					let subClause = { ...clause.subClauses[subClauseIdx] };

					if (param.type === "boolean") {
						if (option) {
							param.args.textIfTrue = "[ ]"
						} else {
							param.args.textIfFalse = "[ ]"
						}
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "enum") {
						if (!param.args) {
							param.args = [];
						}
						param.args.push(option as EnumOption);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "list") {
						if (!param.args) {
							param.args = [];
						}
						param.args.push(option as ListOption);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					if (param.type === "table") {
						if (!param.args) {
							param.args = [];
						}
						param.args.push(option as TableLigne);
						clause.subClauses[subClauseIdx] = subClause;
						clause.subClauses = [...clause.subClauses];
					}
					const segmentation = SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory(subClause.index))
					subClause.segmentation = segmentation
					clause.subClauses[subClauseIdx] = subClause;
				}
			} else {
				if (param.type === "enum") {
					if (!param.args) {
						param.args = [];
					}
					param.args.push(option as EnumOption);
				}
				if (param.type === "boolean") {
					if (option) {
						param.args.textIfTrue = "[ ]"
					} else {
						param.args.textIfFalse = "[ ]"
					}
				}
				if (param.type === "list") {
					if (!param.args) {
						param.args = [];
					}
					param.args.push(option as ListOption);
				}
				if (param.type === "table") {
					if (!param.args) {
						param.args = [];
					}
					param.args.push(option as TableLigne);
				}
			}

			const segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
			clause.segmentation = segmentation
			newContractTemplateValue.clauses[clauseIdx] = clause;
			setContractTemplate(newContractTemplateValue);
		}
	};

	const onSegmentChange: EditContractTemplateContextType['onSegmentChange'] = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, segmentText: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let segmentation: ClauseEntity['segmentation']
		let clauseIdx: number
		let subClauseIdx: number
		console.log("object")

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}

		let newSegmentation = updateSegment(segmentation.segmentedText, segmentation.segmentedParams, id, segmentText)
		console.log(newSegmentation)
		const cleanedSegmentation = cleanSegmentation(newSegmentation.segmentedText, newSegmentation.segmentedParams)
		const { text, params } = compileSegmentationAndParams(cleanedSegmentation.segmentedText, cleanedSegmentation.segmentedParams)


		if (subClauseId) {
			subClause.segmentation = SegmentTextAndParams(text, params, genIdFactory(subClause.index))
			subClause.rawText = [text]
			subClause.params = [...params]
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.segmentation = SegmentTextAndParams(text, params, genIdFactory(clause.index))
			clause.rawText = [text]
			clause.params = [...params]
		}

		let newContractTempaleValue = { ...contractTemplate }
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)

	}

	const onAddParam = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, newParam: SegmentedClauseParam, textBefore: string, textAfter: string, field: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let segmentation: ClauseEntity['segmentation']
		let clauseIdx: number
		let subClauseIdx: number

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		const existingParam = segmentation.segmentedParams.find(p => p.name === newParam.name && p.type === newParam.type)
		let newSegmentation
		if (!existingParam) {
			newSegmentation = insertParamInSegment(segmentation.segmentedText, segmentation.segmentedParams, id, newParam, 0, textBefore, textAfter, field)
		} else {
			const definitions = segmentation.segmentedParams.filter((param) => param.name == newParam.name && param.type == newParam.type)
			const definition = definitions.reduce((prev, curr) => Math.max(prev, (curr as any).definition || 0), 0) + 1
			newSegmentation = insertParamInSegment(segmentation.segmentedText, segmentation.segmentedParams, id, newParam, definition, textBefore, textAfter, field)
		}
		const cleanedSegmentation = cleanSegmentation(newSegmentation.segmentedText, newSegmentation.segmentedParams)
		const { text, params } = compileSegmentationAndParams(cleanedSegmentation.segmentedText, cleanedSegmentation.segmentedParams)

		if (subClauseId) {
			subClause.segmentation = SegmentTextAndParams(text, params, genIdFactory(subClause.index))
			subClause.rawText = [text]
			subClause.params = [...params]
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.segmentation = SegmentTextAndParams(text, params, genIdFactory(clause.index))
			clause.rawText = [text]
			clause.params = [...params]
		}

		let newContractTempaleValue = { ...contractTemplate }
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)
	}

	const onParamReorder = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], oldIndex: number, newIndex: number) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let params: ClauseEntity['params']
		let rawText: ClauseEntity['rawText']

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			params = subClause.params
			rawText = subClause.rawText
		} else {
			params = clause.params
			rawText = clause.rawText
		}

		const [removed] = params.splice(oldIndex, 1)
		params.splice(newIndex, 0, removed)

		if (subClauseId) {
			subClause.params = [...params]
			const newSegmentation = SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory(subClause.index))
			subClause.segmentation = newSegmentation
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.params = [...params]
			const newSegmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
			clause.segmentation = newSegmentation
		}

		let newContractTempaleValue = { ...contractTemplate }
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)
	}

	const onClauseDelete = async (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], applyMerge: Boolean): Promise<ContractTemplateEntity> => {
		let newContractTemplateValue = { ...contractTemplate };
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			const clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					const deletedSubClause = clause.subClauses[subClauseIdx];
					if (applyMerge) {
						if (subClauseIdx === 0) {
							clause.rawText = [clause.rawText[0] + "\n" + deletedSubClause.name + "\n" + deletedSubClause.rawText[0]]
							clause.params = [...clause.params, ...deletedSubClause.params]
							clause.segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory(clause.index))
						} else {
							const prevSubClause = clause.subClauses[subClauseIdx - 1];
							prevSubClause.rawText = [prevSubClause.rawText[0] + "\n" + deletedSubClause.name + "\n" + deletedSubClause.rawText[0]]
							prevSubClause.params = [...prevSubClause.params, ...deletedSubClause.params]
							prevSubClause.segmentation = SegmentTextAndParams(prevSubClause.rawText[0], prevSubClause.params, genIdFactory(prevSubClause.index))
							clause.subClauses[subClauseIdx - 1] = prevSubClause;
						}
					}
					clause.subClauses = clause.subClauses.filter(sc => sc.id !== subClauseId);
					newContractTemplateValue.clauses[clauseIdx] = clause;
				}
			} else {
				const result = await contractTemplateClient.deleteClause(clauseId);
				if (result) {
					newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.id !== clauseId);
					toast.success(t(translationPath + "toasts.deleteClause.success"))
				}
			}

			setContractTemplate(newContractTemplateValue);
			return newContractTemplateValue
		}

	}

	const onClauseSwap = async (index: number, localId: number, externalId: number, externalName: string): Promise<ContractTemplateEntity> => {
		const newContractTemplateValue = { ...contractTemplate }
		const result = await contractTemplateClient.deleteClause(localId);
		if (result) {
			newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.id !== localId);
			toast.success(t(translationPath + "toasts.deleteClause.success"))
		}
		const row = await insertExistingClauseInContractTemplate(externalId, externalName)
		if (!row.id || !row.code) {
			return contractTemplate
		}
		const newClause: ClauseEntity = {
			...row,
			index: index.toString(),
		}
		const newSegmentation = SegmentTextAndParams(newClause.rawText[0], newClause.params, genIdFactory(index.toString()))
		newClause.segmentation = newSegmentation
		// remove clause if duplicate
		newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => normalizeName(c.name) !== normalizeName(newClause.name))
		// reinsert new clause
		newContractTemplateValue.clauses.splice(index, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		// save template
		setContractTemplate(newContractTemplate)
		return newContractTemplate
	}

	const onSegmentDelete = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let segmentation: ClauseEntity['segmentation']
		let clauseIdx: number
		let subClauseIdx: number

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}

		let newSegmentation = deleteSegment(segmentation.segmentedText, segmentation.segmentedParams, id)
		newSegmentation = cleanSegmentation(newSegmentation.segmentedText, newSegmentation.segmentedParams)
		const { text, params } = compileSegmentationAndParams(newSegmentation.segmentedText, newSegmentation.segmentedParams)

		if (subClauseId) {
			subClause.segmentation = SegmentTextAndParams(text, params, genIdFactory(subClause.index))
			subClause.rawText = [text]
			subClause.params = [...params]
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.segmentation = SegmentTextAndParams(text, params, genIdFactory(clause.index))
			clause.rawText = [text]
			clause.params = [...params]
		}

		let newContractTempaleValue = { ...contractTemplate }
		newContractTempaleValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTempaleValue)
	}

	const insertClause = async (insertIndex: number, clauseName: string): Promise<ContractTemplateEntity> => {
		const row = await insertClauseInContractTemplate(clauseName)
		if (!row.id || !row.code) return;
		const newClause: ClauseEntity = {
			...row,
			name: clauseName,
			index: insertIndex.toString(),
			subClauses: [],
		}
		const newContractTemplateValue = { ...contractTemplate }
		// reinsert new clause
		newContractTemplateValue.clauses.splice(insertIndex, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		setContractTemplate(newContractTemplate)
		return newContractTemplate
	}

	const insertImportedClause = async (insertIndex: number, clauseId: ClauseEntity['id'], newName: string): Promise<ContractTemplateEntity> => {
		const row = await insertExistingClauseInContractTemplate(clauseId, newName)
		if (!row.id || !row.code) {
			return contractTemplate
		}
		const newClause: ClauseEntity = {
			...row,
			index: insertIndex.toString(),
		}
		const newSegmentation = SegmentTextAndParams(newClause.rawText[0], newClause.params, genIdFactory(insertIndex.toString()))
		newClause.segmentation = newSegmentation
		const newContractTemplateValue = { ...contractTemplate }
		// remove clause if duplicate
		newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.name !== newClause.name)
		// reinsert new clause
		newContractTemplateValue.clauses.splice(insertIndex, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		// save template
		setContractTemplate(newContractTemplate)
		//EventManager.invoke('SaveContractTemplate', true)
		return contractTemplate
	}

	const onClauseNameChange = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], name: string) => {
		let newContractTemplateValue = { ...contractTemplate };
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);

		if (clauseIdx !== -1) {
			const clause = { ...newContractTemplateValue.clauses[clauseIdx] };

			if (subClauseId) {
				const subClauseIdx = clause.subClauses!.findIndex(sc => sc.id === subClauseId);

				if (subClauseIdx !== -1) {
					const subClause = { ...clause.subClauses[subClauseIdx] };
					subClause.name = name;
					clause.subClauses[subClauseIdx] = subClause;
					clause.subClauses = [...clause.subClauses];
				}
			} else {
				clause.name = name;
			}

			newContractTemplateValue.clauses[clauseIdx] = clause;
			setContractTemplate(newContractTemplateValue);
		}
	}

	const insertSubClause = async (insertIndex: number, clauseId: ClauseEntity['id'], subClauseName: string): Promise<ContractTemplateEntity> => {
		const result = await insertSubClauseInContractTemplate(clauseId, subClauseName)
		if (!result.id || !result.code) return;
		const newContractTemplateValue = { ...contractTemplate };
		const newSubClause: SubClauseEntity = {
			...result,
			name: subClauseName,
			index: insertIndex.toString(),
		}
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);
		if (clauseIdx == -1) return;
		const clause = { ...newContractTemplateValue.clauses[clauseIdx] };
		clause.subClauses.splice(insertIndex, 0, newSubClause);
		const newClause = fixClauseIndexation(clause)
		newContractTemplateValue.clauses[clauseIdx] = newClause;
		setContractTemplate(newContractTemplateValue);
		return newContractTemplateValue
	}

	const insertSubClauseWithContent = async (insertIndex: number, clauseId: ClauseEntity['id'], subClause: SubClauseEntity): Promise<ContractTemplateEntity> => {
		const result = await insertSubClauseInContractTemplate(clauseId, subClause.name)
		if (!result.id || !result.code) return;
		const newContractTemplateValue = { ...contractTemplate };
		const newSubClause: SubClauseEntity = {
			...result,
			name: subClause.name,
			index: insertIndex.toString(),
			params: subClause.params,
			rawText: subClause.rawText,
			segmentation: SegmentTextAndParams(subClause.rawText[0], subClause.params, genIdFactory(insertIndex.toString()))
		}
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);
		if (clauseIdx == -1) return;
		const clause = { ...newContractTemplateValue.clauses[clauseIdx] };
		clause.subClauses.splice(insertIndex, 0, newSubClause);
		const newClause = fixClauseIndexation(clause)
		newContractTemplateValue.clauses[clauseIdx] = newClause;
		setContractTemplate(newContractTemplateValue);
		return newContractTemplateValue
	}

	const contractListParentRef = useRef()
	const EditContractTemplateContextValue = useMemo(() => {
		return {
			contractTemplate,
			setContractTemplate,
			combinedTemplateParams,
			setCombinedTemplateParams,
			previewValues,
			setPreviewValues,
			onParamChanged,
			contractListParentRef,
			onParamDelete,
			onOptionAdd,
			onSegmentChange,
			onAddParam,
			onOptionChanged,
			onOptionDelete,
			onParamTypeChange,
			onParamReorder,
			onClauseDelete,
			onSegmentDelete,
			insertClause,
			onClauseNameChange,
			insertSubClause,
			initialTemplate,
			setInitialTemplate,
			updateInitialTemplate,
			insertImportedClause,
			insertSubClauseWithContent,
			onClauseSwap,
			onSelectGroupInClause,
			groups
		}

	}, [contractTemplate, combinedTemplateParams, previewValues, contractListParentRef, initialTemplate])
	return (
		<EditContractTemplateContext.Provider value={EditContractTemplateContextValue}>
			<div className="contract-container d-flex justify-content-between align-items-center">
				{!contractTemplate ? (
					<div className="contract-loading-container">
						<Loading height="90vh" />
					</div>
				) : (
					<EditContractTemplate />
				)}
			</div>
		</EditContractTemplateContext.Provider>
	);
}

export default ContractTemplatePage;
