import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import "./formTemplateParameterInput.scss";
import BeneficialRender from "./BeneficialRender";
import TableRender from "./TableRender";
import BooleanRender from "./BooleanRender";
import FileRender from "./FileRender";
import ListRender from "./ListRender";
import EnumRender from "./EnumRender";
import DefaultRender from "./DefaultRender";

function FormTemplateParameterInput({
  page,
  param,
  isEditing,
}) {
  const { onParamChanged} = useContext(EditContractTemplateContext);
  const [localValue, setLocalValue] = useState(param.label);
  useEffect(() => {
    setLocalValue(param.label);
  }, [param.label]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalValue(value);
    onParamChanged(page.id, { ...param, label: value });
  };
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");

  switch (param.type) {
    case "beneficial":
      return (
        <BeneficialRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "table":
      return (
        <TableRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "boolean":
      return (
        <BooleanRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "file":
      return (
        <FileRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        isEditing={isEditing}
        />
      );
    case "csv":
      return (
        <FileRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        isEditing={isEditing}
        />
      );
    case "list":
      return (
        <ListRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "enum":
      return (
        <>
        <EnumRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        page={page}
        isEditing={isEditing}
        />
        </>
      );
    default:
      return (
       <DefaultRender
        value={localValue}
        onChange={handleInputChange}
        param={param}
        isEditing={isEditing}
        />
      );
  }
}

export default FormTemplateParameterInput;