import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import AdminPage from '../../pages/AdminPage'
import ContractTemplatesPage from '../../pages/ContractTemplates'
import ContractTemplatePage from '../../pages/ContractTemplate'
import ImportTypeLevels from '../../pages/ImportTypeLevels'
import ListGroups from '../../pages/ListGroups'
import ImportRulesPage from '../../pages/ImportRules'
import UsersPage from '../../pages/UsersPage'
import LoadingTemplateProvider from '../../contexts/LoadingTemplateContext'
import FormTemplatesPage from '../../pages/FormTemplatesPage'
import FormTemplatePage from '../../pages/FormTemplatePage'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './transition.scss'

function AdminRouter() {
  const location = useLocation();
  return (
    <LoadingTemplateProvider>
          <Routes>
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/import-type-levels" element={<ImportTypeLevels />} />
            <Route path="/admin/list-groups" element={<ListGroups />} />
            <Route path="/admin/import-rules" element={<ImportRulesPage />} />
            <Route path="/contract-templates" element={<ContractTemplatesPage />} />
            <Route path="*" element={<Navigate to="/admin" />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/edition-contrat-template/:contractTemplateId?" element={<ContractTemplatePage />} />
            <Route path="/edition-form-template/:formTemplateId?" element={<FormTemplatePage />} />
            <Route path='/form-templates' element={<FormTemplatesPage />} />
          </Routes>
    </LoadingTemplateProvider>

  )
}

export default AdminRouter