import React from 'react'
import { useTranslation } from '../../../../contexts/TranslationProvider'

function FileRender({param, isEditing, value, onChange}) {
  const { t, language } = useTranslation();
  return (
    <div className="input-param">
    <div className="input-type">{param.type}</div>
    {!isEditing ? (
      <label style={{justifyContent: language === "ar" ? "right" : "left"}} htmlFor={param.name} className="custom-form-label">
        {param.label}
      </label>
    ) : (
      <textarea
        className="label-input-edit mt-3"
        style={{ marginLeft: "8px", marginBottom: "10px" }}
        name={param.name}
        value={value}
        onChange={onChange}
      />
    )}
  </div>
  )
}

export default FileRender