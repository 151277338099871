import { ClauseEntity, ContractEntity } from "../domain/entities";
import { ContractTemplateParsedData } from "./ParseTemplateData";
import { getValueFromInputs } from "./segmentation";

export function getRenderedParams(rawText: string, inputs: Record<ClauseEntity['id'], any>,
  fileNames: ContractEntity['fileNames'], beneficialsMap: ContractEntity['beneficialsMap'],
  params: ContractTemplateParsedData[number]['params'],
  clauseCode: string, subClauseCode?: string) {
  let renderedParams: string[] = []

  const escapeDollar = (str: string) => str.replace(/\\\$/g, "\uFF04");

  escapeDollar(rawText)
    .split(/(\$[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*|\$\$)/)
    .filter((part) => part !== undefined)
    .forEach((part, index) => {
      if (part.startsWith("$")) {
        const inputPath = part.slice(1);
        const { value: input, parentParam } = getValueFromInputs(inputPath, inputs, fileNames, beneficialsMap, params);
        if (!parentParam) {
          console.warn(`param not found. Code-${clauseCode}${subClauseCode && `--${subClauseCode}` || ""}:${inputPath}`);
          return
        }
        renderedParams.push(parentParam.name)
        if (input == undefined)
          return
        switch (parentParam.type) {
          case 'beneficial[]':
            const textPerBeneficial = parentParam.args.textPerBeneficial
            if (Array.isArray(input) && input.length)
              renderedParams.push(...getRenderedParams(textPerBeneficial, inputs, fileNames, beneficialsMap, params, clauseCode, subClauseCode))
            break;
          case 'enum':
            const arg = parentParam.args[input as any as number]
            if (!arg)
              return
            if (parentParam.name == "type_marche") {
              console.log(JSON.stringify(arg.text));
            }
            renderedParams.push(...getRenderedParams(arg.text, inputs, fileNames, beneficialsMap, params, clauseCode, subClauseCode))
            break;
          case 'boolean':
            const text = input ? parentParam.args.textIfTrue : parentParam.args.textIfFalse
            renderedParams.push(...getRenderedParams(text, inputs, fileNames, beneficialsMap, params, clauseCode, subClauseCode))
            break;
          default:
            break;
        }
      }
    }
    );

  return renderedParams
}
