import React, {useState} from 'react'
import './inputField.scss'
import { useTranslation } from '../../../../contexts/TranslationProvider'
function InputField({value, onChange, placeholder, type, label, error = [], name, required = false, className = ''}) {
  const {t, language} = useTranslation()
  const [inputType, setInputType] = useState(type)

  return (
    <div className={`input-field ${className}`}>
      <label className='input-label'>
        {label} {required && <span className='required'>*</span>}
        </label>
        <div className='input-element-container'>
          {
            type == 'password' && (<>
            {
              inputType === 'password' ? 
              <div className={`show-password ${language === "ar" && "arabic"}`} onClick={() => setInputType('text')}></div> :
              <div className='hide-password' onClick={() => setInputType('password')}></div>
            }
            </>)
          }
        <input
        type={inputType}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className='input-element'
      />
        </div>
      
      {error && <span className='error'>{error.map(
        (err, index) => <span key={index}>{err}</span>
      )}</span>}
    </div>
  )
}

export default InputField