// src/components/common/CustomCombobox.js
import React, { useState, useEffect } from "react";
import "./customCombobox.scss";
import { BsChevronDown } from "react-icons/bs";
import { Notification_UserClient } from "../../../services/api/Notification_UserClient";
import { UserClient } from "../../../services/api/UserClient";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { flags } from "../../../assets/icons/flags";
import { useTranslation } from "../../../contexts/TranslationProvider";
interface CustomComboboxProps {
  optionDisplay?: any,
  optionValue?: any,
  onClick?: any
}
const FlagCombobox = ({ optionDisplay, optionValue, onClick}: CustomComboboxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    const value = optionValue ? optionValue(option) : option;
    setIsOpen(false);
    handleFlagChange(value)
  };
  const apiClient = apiClientWithLoading()
  const userClient = new UserClient(apiClient)

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setIsOpen(!isOpen);
  };
  const languages = [
    {
      language: "fr",
      flag: flags.fr,
    },
    {
      language: "en",
      flag: flags.en,
    },
    {
      language: "ar",
      flag: flags.ar,
    },
  ];
  const { setLanguage, language } = useTranslation();
  const storedLanguage = localStorage.getItem("language") || "fr";
  const [flag, setFlag] = React.useState({
    language: storedLanguage,
    flag: flags[storedLanguage],
  });
  useEffect(() => {
    setFlag(languages.find((element) => element.language == language));
  }, [language]);
  const handleFlagChange = async (value) => {
    setLanguage(value);
    userClient.setLanguage(value)
    setFlag(languages.find((element) => element.language == value));
  }
  const currentOptionDisplay = optionDisplay ? optionDisplay(flag) : flag;

  return (
    // added a new div
      <div className="flag-combobox-container" onClick={onClick}>
        <div className="custom-combobox">
          <div className="flag-combobox-selected" onClick={handleDropdownClick}>
            {currentOptionDisplay}
          </div>
          {isOpen && (
            <div className="flag-combobox-options">
              {languages.map((option, index) => {
                const key = `${index}`;
                const display = optionDisplay ? optionDisplay(option) : option;
                if(display === currentOptionDisplay)
                    return null;
                return (
                  <div key={key} className="flag-combobox-option" onClick={() => handleOptionClick(option)}>
                    {display}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
  );
};

export default FlagCombobox;
