import "../commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomCombobox from "../../common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import { ProjectEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { useTranslation } from '../../../contexts/TranslationProvider';
import EditFormContext, { EditFormContextType } from "../../../contexts/EditFormContext";
import EventManager from "../../../services/EventManager";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { FormClient } from "../../../services/api/FormClient";
import { getName } from "../../../helpers/Translation";

interface CreateFormModalType {
  onClose: () => void;
  dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
  typeReq?: number
}
function CreateFormModal({ onClose, dataProject, typeReq }: CreateFormModalType) {
  const { t, language } = useTranslation();
  const [errors, setErrors] = useState<{
    formName: boolean
    level1: boolean;
    level2: boolean;
    level3: boolean;
  }>({
    formName: false,
    level1: false,
    level2: false,
    level3: false,
  });
  const navigate = useNavigate();
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)
  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState("");
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [levels1, setLevels1] = useState<TypeLevel1Entity[]>([]);
  const [formExistsError, setFormExistsError] = useState(false);
  const [checkFormExistsTimeout, setCheckFormExistsTimeout] = useState<NodeJS.Timeout>(null);
  const modalContentRef = useRef(null);
  const apiClient = apiClientWithLoading();
  const formClient = new FormClient(apiClient)
  const typeLevel1Client = new TypeLevel1Client(apiClient)

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { rows } = await typeLevel1Client.getAllWithFormTemplate(language);
      setLevels1(rows)
      setLoading(false);
    })();
  }, []);
  const selectedLevel1 = levelsFilter.level1Id && levels1.find(l => l.id == levelsFilter.level1Id)
  const levels2 = selectedLevel1 && selectedLevel1.levels2
  const selectedLevel2 = levelsFilter.level2Id && levels2?.find(l => l.id == levelsFilter.level2Id)
  const levels3 = selectedLevel2 && selectedLevel2.levels3
  const selectedLevel3 = levelsFilter.level3Id && levels3?.find(l => l.id == levelsFilter.level3Id)
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const checkFormFields = (formName, selectedLevel1, selectedLevel2, selectedLevel3) => {
    let hasError = false;
    // Check formName
    if (formName === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        formName: true,
      }));
      hasError = true;
    }
    // Check type levels
    if (!selectedLevel1) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level1: true,
      }));
      hasError = true;
    }
    if (!selectedLevel2 && levels2 && levels2.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level2: true,
      }));
      hasError = true;
    }
    if (!selectedLevel3 && levels3 && levels3.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level3: true,
      }));
      hasError = true;
    }

    return !hasError;
  };
  const onSubmit = async (e) => {
    e.preventDefault()
    const result = checkFormFields(formName, selectedLevel1, selectedLevel2, selectedLevel3)
    if (result === true) {
      navigate("/edit-form", {
        state: {
          levelsFilter: levelsFilter,
          form: null,
          formId: null,
          name: formName,
          project: dataProject && dataProject?.project,
          requirementType: typeReq && typeReq,
          requirement: dataProject && dataProject?.requirement
        },
      });
    }
  };

  useEffect(() => {
    if (checkFormExistsTimeout) {
      clearTimeout(checkFormExistsTimeout)
    }
    setCheckFormExistsTimeout(setTimeout(async () => {
      try {
        const count = await formClient.countAllByFilter({ "name": formName });
        setFormExistsError(count >= 1);
      } catch (error) {
        console.error(error);
      } finally {
        setCheckFormExistsTimeout(null)
      }
    }, 500))
  }, [formName]);

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.pilot.popups.form.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <p>{t("pages.pilot.popups.form.description")}</p>
                {loading ? (
                  <Loading height="35vh" />
                ) : (
                  <form action="" className="form-group" onSubmit={onSubmit}
                    style={{ maxHeight: "500px", marginBottom: "20%" }}
                  >
                    <p className="fw-bolder">{t("pages.pilot.popups.form.required")}</p>
                    <div className="form-input-content">
                      <div className="w-50">
                        <label htmlFor="formName" className="case-form-labelName">{t("pages.pilot.popups.form.inputs.formName.name")}</label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input input-popup"
                            id="formName"
                            placeholder={t("pages.pilot.popups.form.inputs.formName.placeholder")}
                            onChange={(event) => {
                              const value = event.target.value;
                              setFormName(value);
                              setErrors(prevErrors => ({
                                ...prevErrors,
                                formName: false,
                              }));
                            }}
                          />
                        </div>
                        {errors.formName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error1")}</p>}
                        {formExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error2")}</p>}
                      </div>
                      {dataProject ? (
                        <div className="content-levels">
                          <>
                            <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type1.name")}</label>
                            <select value={getName(dataProject?.requirement?.level1, language)} disabled>
                              <option>{getName(dataProject?.requirement?.level1, language)}</option>
                            </select>
                          </>
                          {dataProject?.requirement?.level2 && (
                            <>
                              <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type2.name")}</label>
                              <select value={getName(dataProject?.requirement?.level2, language)} disabled>
                                <option>{getName(dataProject?.requirement?.level2, language)}</option>
                              </select>
                            </>
                          )}
                          {dataProject?.requirement?.level3 && (
                            <>
                              <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type3.name")}</label>
                              <select value={getName(dataProject?.requirement?.level3, language)} disabled>
                                <option>{getName(dataProject?.requirement?.level3, language)}</option>
                              </select>
                            </>
                          )}
                        </div>
                      ) : (
                        <div style={{width:"40%"}}>
                          <CustomCombobox
                          fullWidth
                            label={t("pages.pilot.popups.form.inputs.type1.name")}
                            options={levels1}
                            value={selectedLevel1}
                            optionDisplay={(option: TypeLevel1Entity) => getName(option, language)}
                            optionValue={(option: TypeLevel1Entity) => option?.id}
                            onChange={(level1Id: TypeLevel1Entity['id']) => {
                              setLevelsFilter({
                                level1Id,
                                level2Id: null,
                                level3Id: null
                              });
                              setErrors(prevErrors => ({
                                ...prevErrors,
                                level1: false,
                              }));
                            }}
                            isOpen={openCombobox === "level1"}
                            setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                          />
                          {errors.level1 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type1.error")}</p>}
                          {levels2?.length > 0 && (
                            <CustomCombobox
                            fullWidth
                              label={t("pages.pilot.popups.form.inputs.type2.name")}
                              options={levels2}
                              value={selectedLevel2}
                              optionDisplay={(option: TypeLevel2Entity) => getName(option, language)}
                              optionValue={(option: TypeLevel2Entity) => option?.id}
                              onChange={(level2Id: TypeLevel2Entity['id']) => {
                                setLevelsFilter({
                                  ...levelsFilter,
                                  level2Id,
                                  level3Id: null
                                });
                                setErrors(prevErrors => ({
                                  ...prevErrors,
                                  level2: false,
                                }));
                              }}
                              isOpen={openCombobox === "level2"}
                              setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                            />
                          )}
                          {errors.level2 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type2.error")}</p>}
                          {levels3?.length > 0 && (
                            <CustomCombobox
                            fullWidth
                              label={t("pages.pilot.popups.form.inputs.type3.name")}
                              options={levels3}
                              value={selectedLevel3}
                              optionDisplay={(option: TypeLevel3Entity) => getName(option, language)}
                              optionValue={(option: TypeLevel3Entity) => option?.id}
                              onChange={(level3Id: TypeLevel3Entity['id']) => {
                                setLevelsFilter({
                                  ...levelsFilter,
                                  level3Id,
                                });
                                setErrors(prevErrors => ({
                                  ...prevErrors,
                                  level3: false,
                                }));
                              }}
                              isOpen={openCombobox === "level3"}
                              setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                            />
                          )}
                          {errors.level3 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type3.error")}</p>}
                        </div>)}
                    </div>
                    <button
                      disabled={formExistsError || !!checkFormExistsTimeout}
                      type="submit" className="btn btn-primary" style={{ left: "4.5%" }}>
                      {t("pages.pilot.popups.form.button")}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFormModal;
