import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import EditContractAI from "./EditContractAI";
import AiValidationContext from "../../contexts/AiValidationContext";
import apiClientWithLoading from "../../services/api/ApiClient";
import {
  addIndexToAnswers,
  fetchTextFileUrl,
} from "../../helpers/helper";
import { LoadingContext } from "../../contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import "./aiConfig.scss"
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { useTranslation } from "../../contexts/TranslationProvider";

function AiContractConfiguration() {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [isEditing, setIsEditing] = useState(false);
  const {
    setAiResponseStatus,
    setAiCompletionPercentage,
    aiContractData,
    setAiContractData,
    setRawSummarySheet,
    setProject,
    setRequirement,
    setRequirementType
  } = useContext(AiValidationContext);

  const location = useLocation();
  const { language, setLanguage } = useTranslation();
  const { contractId } = useParams<{ contractId?: string }>() || {};
  const contractNameFromModal =
    location.state?.contractDataFromModal?.contractName;
  const aiContractModel = location.state?.aiContractData;
  const apiClient = apiClientWithLoading(setLoading);
  const contractAIClient = new ContractAIClient(apiClient)
  const fetchContractData = async () => {
    const row = await contractAIClient.getById(parseInt(contractId));
    if (row.form) {
      const { languages } = row.form;
      if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
        setLanguage(languages[0]);
      }
    }
    const textcontent = await fetchTextFileUrl(
      row?.textFile?.url
    );
    setAiContractData({
      contractName: row?.name,
      contractId: row?.id,
      formId: row?.formId,
      textContent: textcontent,
    });

    setAiResponseStatus(addIndexToAnswers(row?.aiResponsesStatus));
    setRawSummarySheet(row?.summarySheet);
    setIsEditing(true);
  };

  useEffect(() => {
    if (contractId) {
      fetchContractData();
    } else {
      if (
        contractNameFromModal &&
        contractNameFromModal !== aiContractData.contractName
      ) {
        // Resetting the context to its initial state
        setAiCompletionPercentage(0);

        // Setting the aiContractName from the context to the new contractName
        setAiContractData({
          contractName: contractNameFromModal,
          contractId: aiContractModel.contractId,
          formId: aiContractModel.formId,
          textContent: aiContractModel?.textContent,
        });
        setRawSummarySheet(aiContractModel.summarySheet);
        setAiResponseStatus(
          addIndexToAnswers(aiContractModel.aiResponsesStatus)
        );
        if (location?.state?.project) {
          setProject(location?.state?.project)
          setRequirement(location?.state?.requirement)
          setRequirementType(location?.state?.requirementType)
        }
      }
    }
  }, [contractNameFromModal, contractId]);
  const navigate = useNavigate()
  const { previousPathname } = location?.state || { previousPathname: [] };

  return (
    <div className="contract-container">
      {isLoading ? <Loading height="90vh" /> : <>
        <div className="px-2 container-paths">
          {previousPathname && previousPathname?.map((item, index) => (
            <span key={index}>
              <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
              <span className="path-separator"> {">"} </span>
            </span>
          ))}
          {previousPathname && previousPathname.length > 0 && <span className="current-pathname">{aiContractData.contractName}</span>}
        </div>
        <EditContractAI />
      </>}
    </div>
  );
}

export default AiContractConfiguration;
