import React, { useEffect } from "react";
import "./filePreviewModal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";
interface FileProps {
  isOpen?: boolean;
  onRequestClose?: any;
  file?: any;
  text?: any;
}
const FilePreviewModal = ({
  isOpen,
  onRequestClose,
  file,
  text,
}: FileProps) => {
  const { t, language } = useTranslation();
  useEffect(()=>{
    const arabicRegex = /[\u0600-\u06FF]/;
    if(text){
      if (arabicRegex.test(text)) {
        getTextAlignment()
      }
    }
  
  },[text])
  const getTextAlignment = () => {
    const arabicRegex = /[\u0600-\u06FF]/ ;
    if(text) {
      if(arabicRegex.test(text)){
      return "text-right"; 
      }
  };
}

  const translationPath = t("modals.previewFile");
  if (!isOpen) {
    return null;
  }
  const fileURL = !text ? URL.createObjectURL(file!) : "";
  const fileType = file?.type;

  const renderFilePreview = () => {
    if (fileType === "application/pdf") {
      return (
        <iframe
          src={fileURL}
          title="file preview"
          style={{ width: "100%", height: "500px" }}
        />
      );
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <p>{translationPath.wordFile}</p>;
    } else {
      return <p>{translationPath.notSupportedFile}</p>;
    }
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div className="modal  d-flex justify-content-center align-items-center">
        <div className="modal-content modal-content-preview-file">
          <div className="modal-header">
            <h5 className="modal-title">{translationPath.title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onRequestClose}
              aria-label="Close"
              style={{ marginRight: language === "ar" && "75%" }}
            ></button>
          </div>
          {!text ? (
            <div className="p-3">
              <h4>{file.name && file.name}</h4>
              {renderFilePreview()}
            </div>
          ) : (
            <div className="p-3">
              <h4 className={`${getTextAlignment()}`} >{file.name ? file.name : file}</h4>
              <div className="ai-text-container">
                <div className={`ai-text ${getTextAlignment()} `}>
                  <p>{text}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FilePreviewModal;
