import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormListInputItem } from '../../../Form/components/FormListInputItem';
const { default: Delete } = require("../../../../assets/delete.svg");

function ListRender({isEditing, value, onChange, param, page}) {
  const { onOptionChanged, onOptionDelete, onOptionAdd } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  return (
    <div className={`input-param`}>
    <div className="input-type">{param.type}</div>
    <div className="wrapper-item-list-form">
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label" htmlFor={param.name}>
          {param.label}
        </label>
      ) : (
        <textarea
          className="custom-form-textarea"
          style={{ marginLeft: "15px" }}
          name={param.name}
          value={value}
          onChange={onChange}
        />
      )}
      <div className="form-template-list-params">
        {param?.args &&
          param?.args?.map((arg, index) => {
            return !isEditing ? (
              <FormListInputItem
                key={index}
                index={index}
                currentValue={[]}
                handleInputChange={() => {}}
                param={param}
                colorLabel="#212529"
              />
            ) : (
              <div className="d-flex w-100 param-list-container">
                <div className="d-flex flex-column w-100 mb-2">
                  <textarea
                    className="custom-form-textarea"
                    style={{ height: "50px" }}
                    value={arg.option}
                    onChange={(e) =>
                      onOptionChanged(
                        page.id,
                        param,
                        { ...param.args[index], option: e.target.value },
                        index
                      )
                    }
                  />
                  <div
                    style={{ marginTop: "10px", gap: "5px" }}
                    className="d-flex"
                  >
                    <input
                      type="checkbox"
                      checked={arg.required}
                      onChange={(e) =>
                        onOptionChanged(
                          page.id,
                          param,
                          {
                            ...param.args[index],
                            required: e.target.checked,
                          },
                          index
                        )
                      }
                    />
                    <label>{t(translationPath.list.required)} </label>
                  </div>
                </div>
                <img
                  src={Delete}
                  className="delete-single-option"
                  onClick={() => onOptionDelete(page.id, param, index)}
                />
              </div>
            );
          })}
        {isEditing && (
          <p
            className="add-single-option"
            // style={{ marginLeft: "0px" }}
            onClick={() => {
              onOptionAdd(page.id, param, {
                option: "",
                required: false,
              });
            }}
          >
            +
          </p>
        )}
      </div>
    </div>
  </div>

  )
}

export default ListRender