import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../services/api/ApiClient";
import { toast } from "react-toastify";
import { LoadingContext } from "../contexts/LoadingContext";
import { BeneficialEntity, FormEntity, FormTemplatePageEntity } from "../domain/entities";
import { EditFormContextType } from "../contexts/EditFormContext";
import { isFileType } from "../helpers/helper";
import { numberToEnumList } from "../domain/Project";
import { DocumentClient } from "../services/api/DocumentClient";
import { FormClient } from "../services/api/FormClient";
import { ProjectClient } from "../services/api/ProjectClient";
import useTranslation from "./useTranslation";
import { isObject } from "chart.js/dist/helpers/helpers.core";
import { BeneficialClient } from "../services/api/BeneficialClient";
import { mergeParamsForm } from "../helpers/ParseTemplateData";
import { LoadingRessourceContext } from "../contexts/LoadingRessourceContext";

export function useSubmitForm() {
  const { setLoading } = useContext(LoadingContext);
  const { setLoadingRessources } = useContext(LoadingRessourceContext)
  const navigate = useNavigate();
  const { t } = useTranslation();
  function isObject(value) {
    return value !== null && typeof value === "object";
  }

  const submitForm = async (context: EditFormContextType) => {
    const {
      formId,
      name,
      paramValues,
      templateId,
      completionPercentage,
      setTemplate,
      setParamValues,
      setTemplateId,
      setGroups,
      setFormId,
      setForm,
      project,
      requirementType,
      requirement,
      template,
    } = context;
    const body: FormEntity = {
      templateId: templateId,
      paramValues: paramValues,
      status: completionPercentage === 100 ? "Done" : "Draft",
      name: name,
    };
    try {
      setLoadingRessources(prev => ([...prev, { type: "Form", id: formId }]))
      const apiClient = apiClientWithLoading(setLoading, "application/json");
      const documentClient = new DocumentClient(apiClient);
      const formClient = new FormClient(apiClient);
      const projectClient = new ProjectClient(apiClient);
      const beneficialClient = new BeneficialClient(apiClient);
      let paramValues = body.paramValues!;
      let hasError = false;
      for (const key of Object.keys(paramValues)) {
        const value = paramValues[key];
        if (isFileType(value)) {
          const file = value;
          const name = file.name;
          try {
            const {
              row: { id },
            } = await documentClient.upsertMultiForm({ file, name });
            paramValues[key] = id;
          } catch (error) {
            hasError = true;
            console.error(`Error uploading file for key ${key}:`, error);
            toast.error(`Error uploading file for ${key}`);
            break;
          }
        }
        else if (value && isObject(value.data)) {
          console.log("paramValues[key] :>> ", paramValues[key]);
          let bData = value.data as BeneficialEntity
          let id = bData.id;
          bData.name = bData.type == 'Person' ? `${bData.firstName} ${bData.lastName}` : bData.companyName
          let row = id
            ? await beneficialClient.update(id, bData)
            : (await beneficialClient.upsert(bData)).row;
          id = row.id;
          if (id) {
            paramValues[key] = id;
          }
        }
      }

      body.paramValues = paramValues;

      let submitedFormId: FormEntity["id"];
      if (formId) {
        const row = await formClient.update(formId, body);
        submitedFormId = row.id;
        toast.success(t("pages.editionForm.updating.messages.success"));
      } else {
        const row = await formClient.create(body);
        submitedFormId = row.id;
        if (project) {
          const row = await projectClient.update(project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: numberToEnumList(requirementType)[0],
                id: submitedFormId,
              },
            },
            excludedRequirements: [...project.excludedRequirements],
          });
          navigate(`/projet/${row.id}`, { replace: true });
        }
        toast.success(t("pages.editionForm.creating.messages.success"));
      }
      setParamValues({});
      setTemplateId(null);
      setGroups([]);
      setTemplate(null);
      setFormId(null);
      setForm(null);
      setLoadingRessources(prev => prev.filter(r => r.id !== formId && r.type === "Form"))
      return submitedFormId;
    } catch (error) {
      console.error(error);
      setLoadingRessources(prev => prev.filter(r => r.id !== formId && r.type === "Form"))
      toast.error(
        formId
          ? t("pages.editionForm.updating.messages.error")
          : t("pages.contracts.creating.messages.error")
      );
    }
  };

  return submitForm;
}
