import React, { useEffect } from 'react'
import {Carousel} from 'bootstrap';
import "./carousel.scss"
import { useTranslation } from '../../../contexts/TranslationProvider';

function CarouselContainer() {
    useEffect(() => {
        const carousel = new Carousel(document.getElementById('carouselComponent'), {
            interval: 4000 
        });
    }, []);
    const {t, language}=useTranslation()
    const translationPath=t("component.carousel")
  return (
    <div id="carouselComponent" className="carousel slide" data-bs-ride="carousel" style={{ width: "60%", height: '100vh' }}>
    <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
        <div className="carousel-item active">
            <div className="d-block w-50 h-50 img1-sign-up" style={{marginRight:language=="ar"&&"30%",marginLeft:language=="ar"&&"0"}}></div>
            <div className="carousel-caption d-none d-md-block caption-text">
                <h5 className='caption-sign-up-title'>{t(translationPath.title1)}</h5>
                <p className='caption-sign-up-subtitle'>{t(translationPath.subtitle1)}</p>
            </div>
        </div>
        <div className="carousel-item">
            <div className="d-block w-50 h-50 img1-sign-up" style={{marginRight:language=="ar"&&"30%"}} ></div>
            <div className="carousel-caption d-none d-md-block caption-text">
                <h5 className='caption-sign-up-title'>{t(translationPath.title2)}</h5>
                <p className='caption-sign-up-subtitle'>{t(translationPath.subtitle2)}</p>
            </div>
        </div>
        <div className="carousel-item">
            <div className="d-block w-50 h-50 img1-sign-up" style={{marginRight:language=="ar"&&"30%"}}></div>
            <div className="carousel-caption d-none d-md-block caption-text">
                <h5 className='caption-sign-up-title'>{t(translationPath.title3)}</h5>
                <p className='caption-sign-up-subtitle'>{t(translationPath.subtitle3)}</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default CarouselContainer