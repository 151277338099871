import React, { useEffect, useState } from 'react';
import TemplateCard from './TemplateCard';
import { useTranslation } from '../../contexts/TranslationProvider';
import "./createProject.scss"
import apiClientWithLoading from '../../services/api/ApiClient';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom';
import { validateProjectCreation, ValidationResult } from '../../domain/Project';
import SaveDraftModel from '../../components/modals/SaveDraftModal';
import { PromiseSub, createPromiseSub } from '../../utils/promise';
import MiddlewareManager from '../../services/MiddlewareManager';
import EventManager from '../../services/EventManager';
import { ProjectClient } from '../../services/api/ProjectClient';
const MatchingTemplates = ({ projectTemplates, dataProject, setValidationData, selectedBeneficial, setProjectNameUnicity }) => {
  const location = useLocation()
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);


  const { t } = useTranslation();
  const [notSelectedReq, setDeSelectedReq] = useState([]);
  const [validationErrors, setValidationErrors] = useState<ValidationResult['errors']>({});
  const apiClient = apiClientWithLoading();
  const projectClient = new ProjectClient(apiClient)

  const navigate = useNavigate();
  useEffect(() => {
    setValidationErrors({})
    setValidationData({})
  }, [dataProject?.name, projectTemplates])

  const handleSubmitProject = async () => {
    const validation: ValidationResult = validateProjectCreation({
      name: dataProject?.name,
      templateId: projectTemplates[0]?.id,
      values: {},
    });
    if (!validation.isValid) {
      setValidationErrors(validation.errors);
      setValidationData(validation.errors);
      return;
    }
    setValidationErrors({});
    try {
      const data = { ...dataProject, templateId: projectTemplates[0]?.id, values: {}, excludedRequirements: notSelectedReq };
      const row = await projectClient.create(data);
      if (row?.id && selectedBeneficial) {
        const project = await projectClient.update(row?.id, { ressource: { beneficialsId: [selectedBeneficial.id] } });
      }
      navigate(`/projet/${row?.id}`);
      toast.success(t("pages.createProject.messages.success"));
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error(t("pages.createProject.messages.error"));
      if(error.code=="ERR_BAD_REQUEST"){
        setProjectNameUnicity(false)
      }
    }
  };
  const onLocation = async (pathname: string) => {
    if (location.pathname === "/create-project" && pathname != "/create-project" && dataProject?.name && projectTemplates[0]) {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };
  useEffect(() => {
    MiddlewareManager.subscribe('location', onLocation);
    MiddlewareManager.subscribe('location', onLocation);
    return () => {
      MiddlewareManager.unsubscribe('location', onLocation);
    };
  }, [onLocation]);
  useEffect(() => {
    if (location.pathname !== "/create-project") {
      setSaveDraft(false);
    }
    return () => { };
  }, []);
  useEffect(() => {
    EventManager.subscribe('SubmitProject', handleSubmitProject)
    return () => {
      EventManager.unsubscribe('SubmitProject', handleSubmitProject)
    }
  }, [dataProject?.name, projectTemplates[0]])
  const onDeSelect = (selectedReqId) => {
    setDeSelectedReq((prevNotSelectedReq) => {
      const isAlreadySelected = prevNotSelectedReq.find(id => id === selectedReqId);
      if (isAlreadySelected) {
        return prevNotSelectedReq.filter(id => id !== selectedReqId);
      } else {
        return [...prevNotSelectedReq, selectedReqId];
      }
    });
  };
  return (
    <>
      <div className='templates-content'>
        <h6 className='sub-header-content'>{t('pages.createProject.chooseTemplateDescription')}</h6>
        <h3 className='header-content'>{t('pages.createProject.chooseTemplate')}</h3>
        <div className='container-templates'>
          <div className='template-cards-wrapper'>
            <TemplateCard template={projectTemplates[0]} onDeSelect={onDeSelect}
              isNotSelected={(id) => notSelectedReq.includes(id)} />
          </div>
        </div>
        {validationErrors.templateId && !validationErrors.templateId.isValid && (
          <div className='text-danger'>{t('pages.createProject.errors.errorTemplateSelect')}</div>
        )}
        <button className='create-project' onClick={handleSubmitProject}>
          {t('pages.createProject.submitProject')}
        </button>
      </div>
      {draft && (
        <SaveDraftModel
          type="project"
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
        />
      )}
    </>
  );
};

export default MatchingTemplates;
