import React, { useState, useRef, useContext, useEffect } from "react";
import "./exchangeClause.scss";
import ComparisonClause from "./ComparisonClause";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { getName } from "../../../helpers/Translation";

function ClauseSelectModal({
  onClose,
  addNewClause,
  currentClause,
}: {
  onClose: () => void;
  addNewClause: any;
  currentClause: any;
}) {
  const modalComparableRef = useRef(null);
  const [foundClause, setFoundClause] = useState(null);
  const [searchClause, setSearchClause] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const lookup = require("../../../assets/lookup.png");
  const { t, language } = useTranslation();
  const translationPath = "modals.clauseExchange.";
  const {
    contractTemplate,
    onClauseSwap,
  } = useContext(EditContractTemplateContext);
  const apiClient = apiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const searchRef = useRef(null);
  const handleInputChange = (event) => {
    setSearchClause(event.target.value);
    setShowDropdown(true);
  };
  const handleDropdownSelect = (item) => {
    setFoundClause(item);
    setSearchClause(item.name);
    setFilteredClauses([]);
    setShowDropdown(false);
  };
  useEffect(() => {
    if (!searchClause) return;
    const searchClauses = async (name: string) => {
      try {
        setLoading(true);
        const result = await contractTemplateClient.searchClauses(searchClause);
        const { rows: clauses } = result;
        setFilteredClauses(
          clauses.filter((clause) => clause.template.id !== contractTemplate.id)
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error searching clauses:", error);
        toast.error("Error searching clauses");
      }
    };
    searchClauses(searchClause);
  }, [searchClause]);

  const handleConfirm = async () => {
    try {
      if (!foundClause) return;
      await onClauseSwap(
        addNewClause,
        currentClause.id,
        foundClause.id,
        foundClause.name
      );
      toast.success("Clause replaced successfully");
    } catch (err) {
      console.error("Error exchanging clause:", err);
      toast.error("Error exchanging clause");
    }
  };

  // handle click outside of searchRef
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <>
      <>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t(translationPath + "title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "90%" }}
                ></button>
              </div>
              <div className="body-comparison">
                <div
                  className="clause-comparison-choice"
                  ref={modalComparableRef}
                  style={{ position: "relative" }}
                ></div>
                <div className="clause-comparison-container">
                  <div className="clause-exchange-current">
                    {currentClause ? (
                      <ComparisonClause clause={currentClause} />
                    ) : (
                      <span>{t(translationPath + "noClause")}</span>
                    )}
                  </div>
                  <div className="clause-exchange-found">
                    <div
                      ref={searchRef}
                      style={{ marginBottom: "10px" }}
                      className="project-beneficial-name"
                    >
                      <input
                        type="text"
                        className=""
                        value={searchClause}
                        onChange={(event) => handleInputChange(event)}
                        style={{ width: "100%" }}
                      />
                      <img
                        src={lookup}
                        alt="#"
                        className={`lookup-img ${
                          language == "ar" && "lookup-img-arabic"
                        }`}
                      />
                      {currentClause && (
                        <span
                          onClick={() => {
                            setFoundClause(null);
                            setSearchClause("");
                            setShowDropdown(false);
                          }}
                          className={`x-img ${
                            language == "ar" && "x-img-arabic"
                          }`}
                        >
                          X
                        </span>
                      )}

                      {showDropdown && (
                        <div className="list-beneficial-project">
                          {filteredClauses && filteredClauses.length > 0 ? (
                            filteredClauses.map((item, index) => (
                              <div
                                className="item-beneficial-project"
                                key={index}
                                onClick={() => handleDropdownSelect(item)}
                              >
                                <span>{item.name}</span>
                                <div className="d-flex flex-col">
                                  {item?.template?.level1?.name && (
                                    <span className="level-types-template">
                                      {getName(
                                        item?.template?.level1,
                                        language
                                      ) +
                                        (item?.template?.level2
                                          ? "/" +
                                            getName(
                                              item?.template?.level2,
                                              language
                                            )
                                          : "") +
                                        (item?.template?.level3
                                          ? "/" +
                                            getName(
                                              item?.template?.level3,
                                              language
                                            )
                                          : "")}
                                    </span>
                                  )}
                                  <span className="item-template-name">
                                    {item.template?.name}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                    {foundClause ? (
                      <ComparisonClause clause={foundClause} height="88%" />
                    ) : (
                      <span>{t(translationPath + "noClause")}</span>
                    )}
                  </div>
                </div>
              </div>
              <button
                disabled={!foundClause}
                className={`confirm-button ${
                  !foundClause && "disabled-confirm-button"
                }`}
                onClick={handleConfirm}
              >
                {t(translationPath + "swap")}
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default ClauseSelectModal;
