import { useContext } from "react";
import AiValidationContext from "../../../../contexts/AiValidationContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { removeIndex } from "../../../../helpers/helper";
import { AxiosInstance } from "axios";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import { ContractAIClient } from "../../../../services/api/ContractAIClient";

function AiContractSaveButton() {
  const {
    aiCompletionPercentage,
    aiResponsesStatus,
    setAiResponseStatus,
    aiContractData,
    setAiContractData,
    project
  } = useContext(AiValidationContext);

  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleNavigation = () => {
    const currentLocation = window.location.pathname;
    navigate(-1);
    setTimeout(() => {
      const newLocation = window.location.pathname;
      if (newLocation.startsWith("/createDocumentRequirement")) {
        navigate(-1);
      }
    }, 100);
  };




  const submitContract = async (apiClient: AxiosInstance, status, aiResponsesStatus) => {
    try {
      const data = {
        aiResponsesStatus,
        status,
      };
      const contractAIClient = new ContractAIClient(apiClient)

      await contractAIClient.update(aiContractData.contractId, data);
      setAiResponseStatus([]);
      setAiContractData({
        contractName: "",
        contractId: null,
        formId: null,
      });
      handleNavigation()
      setTimeout(() => {
        toast.success(t("pages.editionContractAi.messages.success"))
      }, 100);
    } catch (error) {
      console.error(error);
      toast.error(t("pages.editionContractAi.messages.error"));
    }
  };
  const handleFormSubmit = async () => {
    const apiClientMultipart = apiClientWithLoading(setLoading);

    const status = aiCompletionPercentage === 100 ? "Done" : "Draft";

    const finalResponse = removeIndex(aiResponsesStatus);
    submitContract(apiClientMultipart, status, finalResponse);
  };
  return (
    <button className="navbar-button me-5" onClick={handleFormSubmit}>
      {aiCompletionPercentage !== 100
        ? t("navbar.save2")
        : t("navbar.save1")}
    </button>
  );
}

export default AiContractSaveButton;
