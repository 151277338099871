import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { getClauseName, getTemplateClausesSummaryData } from "../../../helpers/ParseTemplateData";
import { componentToHtml, htmlToDocx } from "../../../services/api/componentToDocx";
import { downloadFile } from "../../../utils/file";
import { serializeParamValues } from "../../../hooks/serializeParamValues";
import { saveWorkbook } from "../../../utils/csv";
import { useTranslation } from "../../../contexts/TranslationProvider";
// import csv from "csv.png"


const ExportModal = ({ onClose }) => {
  const modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalref.current && !modalref.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalref]);
  const [loading, setLoading] = useState(false);
  const { inputValues, templateData, excludedClauses, excludedSubClauses, contract, fileNames, beneficialsMap } = useContext(InputValuesContext);
  const contractName = inputValues.contractName ?? contract?.name
  const { t } = useTranslation();

  const GenerateDocxUrl = async () => {
    const contractNameJsx = document.getElementById("contractName");
    const clauseListContainer = document.getElementById("clauseListContainer");
    const summary = getTemplateClausesSummaryData(templateData, excludedClauses, excludedSubClauses);
    const sumaryJsx = (
      <React.Fragment>
        {summary.map((clause, clauseIndex) => {
          return (
            <React.Fragment>
              <h3>
                {clauseIndex + 1} - {getClauseName(clause.name, clauseIndex)}
              </h3>
              {clause.subClauses.map((subClauseName, subIdx) => (
                <h4 style={{ marginLeft: "20px" }}>
                  {clauseIndex + 1}.{subIdx + 1} - {subClauseName}
                </h4>
              ))}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
    const summaryhtml = componentToHtml(sumaryJsx);
    const documentHtml = `
    <head><meta charset="UTF-8"></head>
    ${contractNameJsx.outerHTML}
    ${summaryhtml}
    <br style="page-break-before: always; clear: both" />
    ${clauseListContainer.outerHTML
        .replace(/<span\s+[^>]*class="[^"]*\bcomment\b[^"]*"[^>]*>.*?<\/span>/g, '')
        .replace(/<img[^>]*>/g, '')}
    `;
    setLoading(true)
    const url = await htmlToDocx(documentHtml, contractName);
    downloadFile(url, `${contractName}.docx`)
    setLoading(false)
    onClose()

  };
  const GenerateXlsxUrl = async () => {
    const workbook = serializeParamValues(inputValues, templateData, fileNames, beneficialsMap)
    saveWorkbook(workbook, `${contractName}.xlsx`)
    onClose()
  }



  return (
    <>
      <>
        <div id="contractz-lab" ref={modalref} className="contractz-lab-export" style={{ position: "absolute", top: "9vh",right: "35vh" }}>
          <div className="modal-dialog export-modal-specific">
            <div className="modal-content" style={{ width: "fit-content" }}>
              <div className="modal-header header-export" >
                <h5 className="modal-title export-title">{t("pages.editionContract.popups.export.title")}</h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-export">
                  <div className="form-group">
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.description")}</p>
                    <div className="form-floating mb-3 export-buttons">
                      <button className="btn export-btn" onClick={GenerateXlsxUrl} style={{ margin: "10px 0px" }}>
                        <div className="csv-img"></div>
                        <p>{t("pages.editionContract.popups.export.exportCSV")}</p>
                      </button>

                      <>
                        <button className="btn export-btn" onClick={GenerateDocxUrl} style={{ margin: "10px 0px" }}>
                          <div className="word-img"></div>
                          <p>{t("pages.editionContract.popups.export.exportWord")}</p>
                        </button>
                      </>

                    </div>
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.footer")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ExportModal;
