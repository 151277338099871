import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ContractTemplateEntity } from "../../domain/entities/ContractTemplate";
import { GenerateTemplateFromDocumentRequest, ValidationWarning } from "../../domain/ContractTemplate";
import { ClauseEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity, UserEntity } from "../../domain/entities";
import { GenerateClauseParamType } from "../../domain/types/Clause";

export class ContractTemplateClient extends ModelClient<ContractTemplateEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ContractTemplate')
  }

  async import(file: File, type: 'xlsx' | 'json' = 'xlsx') {
    const url = type == 'xlsx' ? new URL(this.modelUrl + "/importFromXlsx")
      : new URL(this.modelUrl + "/importFromJSON")
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.apiClient.post<{
      warnings: ValidationWarning[], errors: any[],
      rows: {
        id: ContractTemplateEntity['id'], name: ContractTemplateEntity['name'],
      }[]
    }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async export(id: ContractTemplateEntity['id'], type: 'xlsx' | 'json' = 'xlsx') {
    try {
      const url = type == 'xlsx' ? new URL(this.modelUrl + "/exportToXlsx")
        : new URL(this.modelUrl + "/exportToJSON")
      url.searchParams.append("id", String(id))

      const response = await this.apiClient.get<{ url: string }>(url.href);
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }

  async updateWithWarnings(id: ContractTemplateEntity['id'], data: ContractTemplateEntity, schema = "full") {
    const response = await this.apiClient.patch<{ warnings: ValidationWarning[], row: ContractTemplateEntity }>(
      `${this.modelUrl}?id=${id}&schema=${schema}`,
      { data },
      { headers: { "Content-Type": "application/json" }, }
    );
    return response.data;
  }

  async generateFromDocument(req: GenerateTemplateFromDocumentRequest) {
    const url = new URL(this.modelUrl + "/generateFromDocument")

    const formData = new FormData();

    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    req.isScanned && formData.append("isScanned", String(1));
    req.level1Id && formData.append("level1Id", String(req.level1Id));
    req.level2Id && formData.append("level2Id", String(req.level2Id));
    req.level3Id && formData.append("level3Id", String(req.level3Id));
    const response = await this.apiClient.post<{ warnings: ValidationWarning[], row: ContractTemplateEntity }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  }

  async createClause(templateId: ContractTemplateEntity['id'], name: ClauseEntity['name']) {
    const url = new URL(this.modelUrl + "/addClause")
    url.searchParams.append("id", String(templateId))

    const response = await this.apiClient.post<{ row: ClauseEntity }>(url.href, {}, {
      params: { name },
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }

  async addExistingClause(templateId: ContractTemplateEntity['id'], clauseId: ClauseEntity['id'], newName: string = "") {
    const url = new URL(this.modelUrl + "/addExistingClause")
    url.searchParams.append("id", String(templateId))
    const response = await this.apiClient.post<{ row: ClauseEntity }>(url.href, {}, {
      params: { clauseId, clauseName: newName },
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }

  async createSubClause(clauseId: ClauseEntity['id'], name: string) {
    const url = new URL(this.modelUrl + "/Clause/addSubClause")
    url.searchParams.append("id", String(clauseId))

    const response = await this.apiClient.post<{ row: ClauseEntity }>(url.href, {
      name
    }, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }

  async generateClauseParams(clauseId: ClauseEntity['id'], promptTypes: GenerateClauseParamType[], comments: string = "") {
    const url = new URL(this.modelUrl + "/generateClauseParams")
    url.searchParams.append("clauseId", String(clauseId))

    const response = await this.apiClient.post<{ warnings: ValidationWarning[], row: ClauseEntity }>(url.href, {
      promptTypes,
      comments
    });
    return response.data;

  }

  async activate(id: ContractTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: ContractTemplateEntity }>(`${this.modelUrl}/activate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
  async deactivate(id: ContractTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: ContractTemplateEntity }>(`${this.modelUrl}/deactivate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
  async searchClauses(name: string) {
    try {
      const response = await this.apiClient.post<{ rows: ClauseEntity[] }>(`${this.modelUrl}/searchClauseByName`, {
        name
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching clauses with name: ${name}`, error);
      return null;
    }
  }

  async searchSubClauses(name: string) {
    try {
      const response = await this.apiClient.post<{ rows: ClauseEntity[] }>(`${this.modelUrl}/searchSubClauseByName`, {
        name
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching clauses with name: ${name}`, error);
      return null;
    }
  }

  async select(level1Id: TypeLevel1Entity['id'],
    level2Id: TypeLevel2Entity['id'],
    level3Id: TypeLevel3Entity['id'],
    language: UserEntity['language'],
    schema: string = "full") {
    try {
      const response = await this.apiClient.get<{ row: ContractTemplateEntity }>(`${this.modelUrl}/select`, {
        params: {
          level1Id,
          level2Id,
          level3Id,
          language,
          schema,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error selecting template : ${[
        level1Id,
        level2Id,
        level3Id,]}`, error);
      return null;
    }
  }
  async deleteClause(clauseId: ClauseEntity['id']) {
    try {
      const response = await this.apiClient.delete<{ row: ContractTemplateEntity }>(`${this.modelUrl}/deleteClause`, {
        params: { clauseId },
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting clause with ID: ${clauseId}`, error);
      return null;
    }
  }
}