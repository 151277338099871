import React, { useState } from "react";
import "../../../components/modals/commonmodal.scss";

import apiClientWithLoading from "../../../services/api/ApiClient";

import FileInput from "../../../components/common/FileInput";
import { toast } from "react-toastify";
import { extractXLSXRows } from "../../../utils/csv";
import { DocumentUseCaseResponseEntity } from "../../../domain/entities";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { DocumentClient } from "../../../services/api/DocumentClient";
import ShowCase from "../../../components/ShowCase";
import CheckBoxInput from "../../../components/common/CheckBoxInput";
function QuestionUseCase({ onClose }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState<number>();
  const { t, language } = useTranslation();
  const [document, setDocument] = useState<File>(null);
  const [isScanned, setIsScanned] = useState(false);

  const [questionsFile, setQuestionsFile] = useState<File>(null);
  const [useCaseResponse, setUseCaseResponse] = useState<DocumentUseCaseResponseEntity>(null);
  const apiClient = apiClientWithLoading(setLoading);
  const documentClient = new DocumentClient(apiClient)


  const handleSave = async () => {
    if (questionsFile && document) {
      try {
        setLoading(true)
        const {
          row: { id: documentId },
        } = await documentClient.upsertMultiForm({
          'name': document.name,
          'file': document,
          isScanned,
        });
        setRow(documentId)
        extractXLSXRows(questionsFile, (rows) => {
          const questions = rows.map(r => r['question'])
          console.log(questions);
          documentClient.questionUseCase(documentId, questions).then(({ row }) => {
            setUseCaseResponse(row)
            toast.success(t("pages.listener.popups.question.messages.success"));
            setLoading(false)
            navigate(`/ai-document-response/${row?.id}`)
          })
        })
      } catch (error) {
        toast.error(error?.message ?? error ?? t("pages.listener.popups.question.messages.error"));
      } finally {
        setLoading(false)
        onClose()
      }
    }
  };
  // const exportToCSV = () => {
  //   if (useCaseResponse && useCaseResponse.response && document.name) {
  //     const workbook = XLSX.utils.book_new();
  //     const worksheet = XLSX.utils.aoa_to_sheet([
  //       ["Question", "Answer"],
  //       ...useCaseResponse.response.map(([question, answer]) => [question, answer]),
  //     ]);
  //     worksheet["!cols"] = [{ wch: 40 }, { wch: 40 }];
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "useCaseResponse_Sheet");
  //     saveWorkbook(workbook, `${document.name}_useCaseResponse.xlsx`);

  //   }
  // };
  return (

    <>
      <div
        className="modal-backdrop fade show"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content content-use-case-question">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.listener.popups.question.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  disabled={loading}
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <ShowCase height="500px" />
              ) : (
                <div className="modal-body">
                  <div className="form-floating mb-3">
                    <FileInput
                      label={t("pages.listener.popups.question.add_doc")}
                      onChange={(file) => setDocument(file)}
                      value={document}
                      borderClassName={document ? "green-border" : "grey-border"}
                    />
                  </div>
                  <div className="form-floating">
                    <CheckBoxInput
                      checked={isScanned}
                      name={t("pages.listener.popups.contract.inputs.isScanned")}
                      onChange={() => setIsScanned(!isScanned)}
                      disabled={false}
                    />
                  </div>
                  <div className="form-floating mb-3">
                    <FileInput
                      label={t("pages.listener.popups.question.add_question")}
                      onChange={(file) => setQuestionsFile(file)}
                      value={questionsFile}
                      borderClassName={questionsFile ? "green-border" : "grey-border"}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" style={{ left: "4.5%" }} onClick={handleSave}>
                      {t("pages.listener.popups.question.execute")}
                    </button>

                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionUseCase;
