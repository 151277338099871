import React, { useEffect, useRef, useState } from "react";
import "./ImportTypeLevelsPage.scss";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { useTranslation } from "../../contexts/TranslationProvider";
import FileInput from "../../components/common/FileInput";
import { toast } from "react-toastify";
import { TypeLevel1Client } from "../../services/api/TypeLevel1Client";
import { saveWorkbook } from "../../utils/csv";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router";
import {
  TypeLevel1Stat,
  TypeLevel2Stat,
  TypeLevel3Stat,
} from "../../domain/types/TypeLevel";
import { getName } from "../../helpers/Translation";
import { AiFillDelete } from "react-icons/ai";
import ConfirmDeleteType from "../../components/modals/ConfirmDeteleType";
import AddType from "../../components/modals/AddType";
const { default: EditIcon } = require("../../assets/edit_icon.svg");

function ImportTypeLevels() {
  const apiClient = apiClientWithLoading();
  const types = new TypeLevel1Client(apiClient);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>(null);
  const { t, language } = useTranslation();
  const translationPath = t("pages.typeLevels.tableHeaders");
  const translation = t("pages.typeLevels");
  const [levels1, setLevels1] = useState<TypeLevel1Stat[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [newTypeAdded, setNewType] = useState(false);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [typeToHandle, setTypesToHandle] = useState<TypeLevel1Stat[]>([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await types.getStats();
      setLevels1(data.rows);
      levels1 && setLoading(false);
    };
    fetchData();
    setNewType(false);
  }, [newTypeAdded]);

  const handleSave = async () => {
    if (file) {
      try {
        await types.import(file);
        toast.success(t("modals.importTypeLevels.messages.success"));
      } catch (error) {
        toast.error(
          error?.message ?? error ?? t("modals.importTypeLevels.messages.error")
        );
      } finally {
        setLoading(false);
      }
    }
  };
  const handleExport = async () => {
    try {
      const { url } = await types.export();
      const apiUrl = new URL(url);
      const response = await fetch(apiUrl.toString());
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      saveWorkbook(workbook, "typeLevels.xlsx");
      toast.success(t("modals.exportTypeLevels.messages.success"));
    } catch (error) {
      console.log("error", error);
      toast.error(
        error?.message ?? error ?? t("modals.exportTypeLevels.messages.error")
      );
    }
  };
  return (
    <section className="w-100 p-3">
      <div style={{ marginBottom: "40px", marginTop: "3px" }}>
        <span>
          <span
            className="previous-pathname"
            onClick={() => navigate("/admin")}
          >
            {t("sidebar.admin")}
          </span>
          <span className="path-separator"> {">"} </span>
        </span>
        <span className="current-pathname">
          {t("modals.importTypeLevels.title")}
        </span>
      </div>
      <div className="">
        <button className="mb-3" onClick={() => {setTypesToHandle([]);setShowModal(true)}}>  {t(translation.addNewType)}</button>
        <div className="table-container-types">
          <table className="fixed-header-table-types">
            <thead>
              <tr className="header-table-types">
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.level1)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.level2)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.level3)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.NbContractTemplate)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.NbContractAIForm)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.NbFormTemplate)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                  {t(translationPath.NbProjectTemplate)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                  className="title-table"
                >
                {t(translationPath.actions)}
                </th>
              </tr>
            </thead>
            <tbody className="scrollable-body">
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <>
                  {" "}
                  {levels1.map((level1, index1) => {
                    let rowSpan = 1;
                    level1.levels2.forEach(
                      (level2) => (rowSpan += level2.levels3.length + 1)
                    );
                    const colSpan = level1.levels2.length ? 1 : 3;
                    return (
                      <React.Fragment key={`level1_${level1.id}`}>
                        <tr className="data-table-types">
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "2px",
                              textAlign: "center",
                            }}
                            rowSpan={rowSpan}
                            colSpan={colSpan}
                          >
                            {level1?.name ?? <i>NULL</i>}
                          </td>
                          {level1.levels2.length == 0 && (
                            <React.Fragment>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                {level1.countContractTemplates}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                {level1.countContractAIFormTemplates}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                {level1.countFormTemplates}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                {level1.countProjectTemplates}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                <span>
                                  {" "}
                                  <img
                                    src={EditIcon}
                                    alt="edit"
                                    className="edit-user"
                                    style={{cursor:"pointer"}}
                                    onClick={() => {
                                      setTypesToHandle([level1]);
                                      setShowModal(true);
                                    }}
                                  />
                                </span>
                                {level1.countContractTemplates === 0 &&
                                  level1.countContractAIFormTemplates === 0 &&
                                  level1.countFormTemplates === 0 &&
                                  level1.countProjectTemplates === 0 && (
                                    <span>
                                      <AiFillDelete
                                        className="delete-user"
                                        style={{
                                          color: "#e5142f",
                                          cursor: "pointer",
                                          marginTop: "1px",
                                          fontSize: "18px",
                                        }}
                                        onClick={() => {
                                          setTypesToHandle([level1]);
                                          setConfirmDelete(true);
                                        }}
                                      />
                                    </span>
                                  )}
                              </td>
                            </React.Fragment>
                          )}
                        </tr>
                        {level1.levels2.map(
                          (level2: TypeLevel2Stat, innerIndex2) => {
                            const rowSpan2 = level2.levels3.length + 1;
                            const colSpan2 = level2.levels3.length ? 1 : 2;
                            return (
                              <React.Fragment key={`level2_${level2.id}`}>
                                <tr className="data-table-types">
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      padding: "2px",
                                      textAlign: "center",
                                    }}
                                    rowSpan={rowSpan2}
                                    colSpan={colSpan2}
                                  >
                                    {level2?.name?? <i>NULL</i>}
                                  </td>
                                  {level2.levels3.length == 0 && (
                                    <React.Fragment>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "2px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {level2.countContractTemplates}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "2px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {level2.countContractAIFormTemplates}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "2px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {level2.countFormTemplates}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "2px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {level2.countProjectTemplates}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "2px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span>
                                          {" "}
                                          <img
                                            src={EditIcon}
                                            alt="edit"
                                            className="edit-user"
                                           style={{cursor:"pointer"}}

                                            onClick={() => {
                                              setTypesToHandle([
                                                level1,
                                                level2,
                                              ]);
                                              setShowModal(true);
                                            }}
                                          />
                                        </span>
                                        {level2.countContractTemplates === 0 &&
                                          level2.countContractAIFormTemplates ===
                                            0 &&
                                          level2.countFormTemplates === 0 &&
                                          level2.countProjectTemplates ===
                                            0 && (
                                            <span>
                                              {" "}
                                              <AiFillDelete
                                                className="delete-user"
                                                style={{
                                                  color: "#e5142f",
                                                  cursor: "pointer",
                                                  marginTop: "1px",
                                                  fontSize: "18px",
                                                }}
                                                onClick={() => {
                                                  setTypesToHandle([
                                                    level1,
                                                    level2,
                                                  ]);
                                                  setConfirmDelete(true);
                                                }}
                                              />
                                            </span>
                                          )}
                                      </td>
                                    </React.Fragment>
                                  )}
                                </tr>
                                {level2.levels3.map(
                                  (level3: TypeLevel3Stat, innerIndex3) => {
                                    return (
                                      <React.Fragment
                                        key={`level3_${level3.id}`}
                                      >
                                        <tr className="data-table-types">
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {level3?.name ?? (
                                              <i>NULL</i>
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {level3.countContractTemplates}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {
                                              level3.countContractAIFormTemplates
                                            }
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {level3.countFormTemplates}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {level3.countProjectTemplates}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "2px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <span>
                                              {" "}
                                              <img
                                                src={EditIcon}
                                               style={{cursor:"pointer"}}

                                                alt="edit"
                                                className="edit-user"
                                                onClick={() => {
                                                  setTypesToHandle([
                                                    level1,
                                                    level2,
                                                    level3,
                                                  ]);
                                                  setShowModal(true);
                                                }}
                                              />
                                            </span>
                                            {level3.countContractTemplates ===
                                              0 &&
                                              level3.countContractAIFormTemplates ===
                                                0 &&
                                              level3.countFormTemplates === 0 &&
                                              level3.countProjectTemplates ===
                                                0 && (
                                                <span>
                                                  {" "}
                                                  <AiFillDelete
                                                    className="delete-user"
                                                    style={{
                                                      color: "#e5142f",
                                                      cursor: "pointer",
                                                      marginTop: "1px",
                                                      fontSize: "18px",
                                                    }}
                                                    onClick={() => {
                                                      setTypesToHandle([
                                                        level1,
                                                        level2,
                                                        level3,
                                                      ]);
                                                      setConfirmDelete(true);
                                                    }}
                                                  />
                                                </span>
                                              )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="actions-import-types">
          <div className="form-floating mb-5 pt-3" style={{ width: "60vh" }}>
            <FileInput
              onChange={(file) => setFile(file)}
              value={file}
              borderClassName={file ? "green-border" : "grey-border"}
            />
          </div>

          <div
            style={{
              paddingBottom: "3%",
              width: "60vh",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              type="submit"
              className="action-import-typeLevels"
              onClick={handleSave}
            >
              {t("modals.importTypeLevels.actions.import")}
            </button>
            <button
              type="submit"
              className="action-import-typeLevels"
              onClick={handleExport}
            >
              {t("modals.importTypeLevels.actions.export")}
            </button>
          </div>
        </div>
      </div>
      <div className="import-document-modal">
        {showModal && (
          <AddType
            allTypes={levels1}
           type={typeToHandle}
           titleEdit="edit type"
            setNewtype={setNewType}
            onClose={() => setShowModal(false)}
          />
        )}
          {showConfirmDelete && (
          <ConfirmDeleteType
            setNewType={setNewType}
            msgDeleteType={"delete type"}
            onClose={() => setConfirmDelete(false)}
            type={typeToHandle}
          />
        )}
      </div>
    </section>
  );
}

export default ImportTypeLevels;
