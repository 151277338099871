import EventManager from "../../../../services/EventManager";
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'

function EditFormTemplateSaveButton() {
  const navigate=useNavigate()
  const { t } = useTranslation();
  const handleFormTemplateSubmit = async () => {
   await EventManager.invoke('SubmitFormTemplate',true)
   navigate('/form-templates')
  };

  return (
    <button className="navbar-button" onClick={handleFormTemplateSubmit}>
        {t("navbar.saveFormTemplate")}
    </button>
  );
}

export default EditFormTemplateSaveButton;
