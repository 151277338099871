import React from 'react'
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { signUp } from '../../services/api/UserAuth';
import CarouselContainer from '../../components/common/newDesign/Carousel';
import "./signUp.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
import 'react-toastify/dist/ReactToastify.css';
import InputField from '../../components/common/newDesign/InputField';
import FlagCombobox from '../../components/common/CustomCombobox/FlagCombobox';
interface FormData {
    email: string,
    password: string,
    confirmPassword: string,
    firstName?: string,
    lastName?: string,
}
const SignUp = () => {
    const { t, language } = useTranslation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const translationPath = "pages.signUp.newDesign."
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const validatePassword = (password) => {
        const minLength = /.{8,}/;
        const uppercase = /[A-Z]/;
        const lowercase = /[a-z]/;
        const punctuation = /[!@#$%^&*(),.?":{}|<>]/;
        const number = /[0-9]/;

        if (!minLength.test(password)) {
            return t(translationPath + "inputs.password.error1");
        }
        if (!uppercase.test(password)) {
            return t(translationPath + "inputs.password.error2");
        }
        if (!lowercase.test(password)) {
            return t(translationPath + "inputs.password.error3");
        }
        if (!number.test(password)) {
            return t(translationPath + "inputs.password.error4");
        }
        if (!punctuation.test(password)) {
            return t(translationPath + "inputs.password.error5");
        }
        return '';
    };
    const validateConfirmPassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            return t(translationPath + "inputs.confirmPassword.error");
        }
        return '';
    }
    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState<Record<string, string[]>>({
        email: [],
        password: [],
        confirmPassword: [],
        signUp: [],
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, signUp: [] });
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, email: value });
        if (!validateEmail(value)) {
            setErrors({ ...errors, email: [t(translationPath + "inputs.email.error")], signUp: [] });
        } else {
            setErrors({ ...errors, email: [], signUp: [] });
            return;
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, password: value });
        const error = validatePassword(value);
        const confirmPasswordError = validateConfirmPassword(value, formData.confirmPassword);
        if (error) {
            if (confirmPasswordError) {
                setErrors({ ...errors, password: [error], signUp: [], confirmPassword: [confirmPasswordError] });
            } else {
                setErrors({ ...errors, password: [error], signUp: [] });
            }
        } else {
            if (confirmPasswordError) {
                setErrors({ ...errors, password: [], signUp: [], confirmPassword: [confirmPasswordError] });
            } else {
                setErrors({ ...errors, password: [], signUp: [], confirmPassword: [] });
            }

        }
    }

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, confirmPassword: value });
        const error = validateConfirmPassword(formData.password, value);
        if (error) {
            setErrors({ ...errors, confirmPassword: [error], signUp: [] });
        } else {
            setErrors({ ...errors, confirmPassword: [], signUp: [] });
            return;
        }
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            setLoading(true);
            const user = await signUp({
                email: formData.email,
                password: formData.password,
                UserType: "Client",
                firstName: formData.firstName,
                lastName: formData.lastName,
            });
            setLoading(false);
        } catch (error) {
            console.error('Error signing up user:', error);
            setErrors({ ...errors, signUp: [t(translationPath + "error")] });
            setLoading(false);
        }
    };

    const handlePrevious = () => {
        navigate('/sign-in')
    }

    return (
        <div className='sign-up-content'>
            <CarouselContainer />
            <div className='sign-up-sub-content'>
                <div className='header'>
                    <div onClick={handlePrevious} className='logo-back'></div>
                <FlagCombobox
                        optionValue={(option) => option?.language || null}
                        optionDisplay={(option) => option?.flag || ""}
                    />
                </div>
                <div className='form-container'>
                    <div className='form-section'>
                    <h1 className={`greeting ${language == "ar" && "arabic"}`}>
                        {t(translationPath + "title")}
                    </h1>
                    <div className='signup-form'>
                        <div className='side-by-side'>
                            <InputField
                                value={formData.firstName}
                                onChange={handleInputChange}
                                placeholder={t(translationPath + "inputs.firstName.placeholder")}
                                type='text'
                                label={t(translationPath + "inputs.firstName.label")}
                                name='firstName'
                                error={errors.firstName}
                            />
                            <InputField
                                value={formData.lastName}
                                onChange={handleInputChange}
                                placeholder={t(translationPath + "inputs.lastName.placeholder")}
                                type='text'
                                label={t(translationPath + "inputs.lastName.label")}
                                name='lastName'
                                error={errors.lastName}
                            />
                        </div>
                        <InputField
                            value={formData.email}
                            onChange={handleEmailChange}
                            placeholder={t(translationPath + "inputs.email.placeholder")}
                            type='email'
                            label={t(translationPath + "inputs.email.label")}
                            name='email'
                            error={errors.email}
                            required
                        />

                        <InputField
                            value={formData.password}
                            onChange={handlePasswordChange}
                            placeholder={t(translationPath + "inputs.password.placeholder")}
                            type='password'
                            label={t(translationPath + "inputs.password.label")}
                            name='password'
                            error={errors.password}
                            required
                        />
                        <InputField
                            value={formData.confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            placeholder={t(translationPath + "inputs.confirmPassword.placeholder")}
                            type='password'
                            label={t(translationPath + "inputs.confirmPassword.label")}
                            name='confirmPassword'
                            error={errors.confirmPassword}
                            required
                        />
                        <button
                            disabled={errors.email.length !== 0 || errors.password.length !== 0 || errors.confirmPassword.length !== 0 || loading}
                            className={`create-account ${(errors.email.length !== 0 || errors.password.length !== 0 || errors.confirmPassword.length !== 0 || loading) && "disabled"}`}
                            onClick={handleSubmit}
                        >
                            {t(translationPath + "createAccount")}
                        </button>
                        {
                            errors.signUp.length !== 0 && (
                                <div style={{ color: "red" }} className='error-container'>
                                    {errors.signUp.map((error, index) => (
                                        <>{error}</>
                                    ))}
                                </div>
                            )
                        }
                        <p className='signin-suggestion'>
                            {t(translationPath + "haveAccount")}
                            &nbsp;
                             <Link className='link' to='/sign-in'>
                                {t(translationPath + "signIn")}
                             </Link>
                        </p>
                    </div>
                </div>
                </div>
                
            </div>
        </div>
    )
}

export default SignUp