import React, { useState, useMemo } from 'react'
import './projectCard.scss'
import project from "../../../../assets/new-design-icons/Projects.svg"
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { getTypeLevels } from '../../../../helpers/helper'
import { useNavigate } from 'react-router-dom'
import ConfirmDeleteMessage from '../../../modals/ConfirmDeteleMessage'
import { FiMoreHorizontal } from "react-icons/fi";
import ContractCardActionsModal from '../../../modals/ContractCardActionsModal'

function ProjectCard({ data, setProjectListChanged }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);
  const { t, language } = useTranslation();
  const projectIcon = project.toString()
  const navigate = useNavigate();
  const onClick = () => { 
    navigate(`/projet/${data?.id}`)
  }
  const onDelete = () => {
    setConfirmDelete(true)
   }
  const beneficialProfilePhoto = data?.ressource?.beneficials?.filter(b => b.profilePhoto)?.[0]?.profilePhoto
  const typeDisplay = useMemo(() => {
      return getTypeLevels(data).map(
      level => {
        const translatedName = level.translations.find(t => t.language === language)
        if (translatedName) {
          return translatedName.name
        } else {
          return level.name
        }
      }
    ).join("/")
  }, [data, language]);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      {confirmDeleteMessage && <ConfirmDeleteMessage onClose={()=>setConfirmDelete(false)} messageDelete={t("pages.viewProject.deleteMessage")} project={data} setNewChange={setProjectListChanged}/>}
    <div onClick={onClick} key={data.id + '-' + data.type} className='project-card-container'>
      <div className='header'>
        <div className='title'>
          <div className='icon'>
            <img src={projectIcon} alt="icon" style={{ width: "30px", height: "30px" }} />
            <div className='name'>
              {data.name}
            </div>
          </div>
          </div>
          <div className='actions'>
            <button className="plus-icon" onClick={(e) => togglePopup(e)}>
              <FiMoreHorizontal size={20} />
            </button>
            {isPopupOpen &&
              <div className='popup'>
                <ContractCardActionsModal onView={onClick} onDelete={onDelete} />
              </div>
            }
          </div>
        </div>
        <div className='footer'>
          {typeDisplay ? <div className='status'>{typeDisplay}</div> : ""}
          {
              beneficialProfilePhoto && beneficialProfilePhoto.url &&
              <img src={beneficialProfilePhoto.url} alt="" className="project-beneficial" />
            } 
        </div>
    </div>
    </>
  )
}

export default ProjectCard