import { UserEntity } from "../../../domain/entities";
import "./sidebar.scss";

function SidebarUser({ isHovering = true, user }:
  {
    isHovering: boolean;
    user: UserEntity;
  }
) {

  return (
    <div
      style={{ textDecoration: "none" }}
      className={`d-flex gap-3 sidebar-item`}
    >
      {isHovering ? <div className="sidebar-user-text">
        <div className="sidebar-user-role">{user?.UserType}</div>
        <div className="sidebar-user-name">{user?.fullName}</div>
      </div> : ''}
    </div>
  );
}

export default SidebarUser;
