
import React, { useContext, useEffect, useState } from "react";
import ClauseText from "./ClauseText";
import { ClauseEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import InputValuesContext from "../../../contexts/InputValuesContext";

interface ComparableClauseRenderProps {
	comparableClauses: ClauseEntity[][];
	clauseName: string;
	selectedClause: ClauseEntity;
	setSelectedClause: React.Dispatch<React.SetStateAction<ClauseEntity>>;
}
function ComparableClauseRender({
	comparableClauses,
	selectedClause,
	setSelectedClause,
	clauseName
}: ComparableClauseRenderProps) {
	const {
		inputValues,
		fileNames,
		beneficialsMap,
		segmentsOverrides,
	} = useContext(InputValuesContext);
	const [selectedClauseIndex, setSelectedClauseIndex] = useState<number>(null);
	const onChange = (clause: ClauseEntity, index) => {
		setSelectedClause(clause);
		setSelectedClauseIndex(index);
	}
	const { t, language } = useTranslation()
	return (<div style={{ maxWidth: "100%" }}>
		<div className="clause-comparison-select-container">
			{comparableClauses.length > 0 && comparableClauses.map((clauses, index) => {
				const candidateClause = clauses.filter(clause => clause.name === clauseName)[0];
				return <div className={`clause-comparison-select-element ${index === selectedClauseIndex && 'selected-comparison'}`} key={index} onClick={() => onChange(candidateClause, index)}>
					<span className="clause-comparison-select-element-text">{t("modals.clauseComparaison.version")}
						{index + 1}</span>
				</div>
			}
			)}
		</div>
		{selectedClauseIndex === null && <div>
			{t("modals.clauseComparaison.chooseVersion")}
		</div>}
		{!selectedClause && selectedClauseIndex !== null && <div>
			{t("modals.clauseComparaison.empty")}
		</div>}
		{selectedClause && <div className="clause-comparison-chosen">
			<div className="clause-comparison-header" style={{ marginBottom: '20px' }}>
				<span className="clause-comparison-cummon-title">
					{t("modals.clauseComparaison.clauseClausier")}
				</span>
				<br />
				<span className="clause-comparison-actual-title half-border-bottom">
					{selectedClause.name}
				</span>
			</div>
			<div className="clause-comparison-body">
				<ClauseText
					segmentation={selectedClause.segmentation}
					fileNames={fileNames}
					beneficialsMap={beneficialsMap}
					inputValues={inputValues}
					segmentsOverrides={segmentsOverrides}
				/>
				{selectedClause.subClauses.map((subClause) => {
					return (
						<div
							key={subClause.id}
							className="my-2"
							id={`${selectedClause.id}-${subClause.id}`}
						>
							<h5 className="fw-bold">{subClause.name}</h5>
							<div>
								{subClause.segmentation?.segmentedText?.length > 0 && (
									<ClauseText
										segmentation={subClause.segmentation}
										fileNames={fileNames}
										beneficialsMap={beneficialsMap}
										inputValues={inputValues}
										segmentsOverrides={segmentsOverrides}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
		}
	</div>)
}

export default ComparableClauseRender;
