import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectDocument from './ProjectDocument';
import apiClientWithLoading from '../../services/api/ApiClient';
import { enumToString } from '../../domain/Project';
import "./project.scss";
import { ProjectEntity, UserEntity } from '../../domain/entities';
import { useTranslation } from '../../contexts/TranslationProvider';
import { ProjectClient } from '../../services/api/ProjectClient';
import { UserClient } from '../../services/api/UserClient';
const Project = () => {
    const { t , language,setLanguage} = useTranslation();
    const [project, setProject] = useState<ProjectEntity | null>(null);
    const [users, setUsers] = useState<UserEntity[]>([]);
    const navigate = useNavigate()
    const { projectId } = useParams();
    const apiClient = apiClientWithLoading();
    const projectClient = new ProjectClient(apiClient)
    const userClient = new UserClient(apiClient)
    useEffect(() => {
        if (projectId) {
            (async () => {
                const row = await projectClient.getById(parseInt(projectId));
                const excludedRequirements = row.excludedRequirements ?? [];
                const updatedRequirements = row?.template?.requirements?.filter(req => {
                    return !excludedRequirements.includes(req.id);
                });
                const updatedProject = {
                    ...row,
                    template: {
                        ...row?.template,
                        requirements: updatedRequirements,
                    },
                };
                setProject(updatedProject);
               const arabicRegex = /[\u0600-\u06FF]/;
                if (arabicRegex.test(row.name)) {
                   setLanguage("ar")
                  }
            })();
        }
    }, [projectId]);
    const getNextResource = (type, idDocument) => {
        if (type == 'CONTRACTAI') {
            return project?.linkedRessources['DOCUMENT']?.find(item => {
                return idDocument?.id === item.id;
            });
        }
        const ressource = project?.linkedRessources[type]?.find(item => {
            return idDocument?.id === item.id;
        });
        return ressource;
    };
    const onUpdateProject = (idToRemove) => {
        setProject(prevProject => {
            const updatedProject = { ...prevProject };
            updatedProject.values = Object.fromEntries(
                Object.entries(updatedProject.values)
                    .filter(([key, value]) => value.id !== idToRemove)
            );
            return updatedProject;
        });
    };
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const usersResponse = await userClient.getAll();
                setUsers(usersResponse?.rows);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        fetchUser();
    }, []);
    return (
        <div className="project px-3 pt-2 w-100">
            <section id='display-project'>
                <div className='container-paths'>
                    <span className='previous-pathname' onClick={() => navigate("/projets")}>
                        {t("sidebar.myProjects")}
                    </span>
                    <span className="path-separator"> {">"} </span>
                    <span className='current-pathname'>{project?.name}</span>
                </div>
                <h3 className='project-title py-1'>{project?.name}</h3>
                <div className='document-container'>
                    {project?.template?.requirements?.map((req, index) => {
                        let type = project?.values && enumToString(project?.values[req?.id]?.type);
                        const resource = getNextResource(type, project?.values[req?.id]);
                        return (
                            <ProjectDocument project={project} typeRessource={type} documentReq={req} ressource={resource} key={index} onUpdateProject={onUpdateProject} user={users.find(user => user?.id === resource?.clientId)} />
                        );
                    })}
                </div>
            </section>
        </div>
    );
}

export default Project;
