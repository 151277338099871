import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "../../config";
import { GroupEntity, UserEntity } from "../../domain/entities";
import { RestrictedUserEntity } from "../../domain/entities/RestrictedUser";

export async function signUp(userData: UserEntity) {
  try {
    const response = await axios.post<{ token: string, user: UserEntity }>(`${API_BASE_URL}/auth/SignUp`, userData);
    // Extract the user data and the access token from the response
    const { user, token } = response.data;
    // Store the access token in local storage, or use any other suitable storage method
    localStorage.setItem("user-token", token);
    window.dispatchEvent(new Event("login"));
    return user;
  } catch (error) {
    console.error("Error Signingup user :" + error);
    throw error;
  }
};

export async function signIn(email: UserEntity['email'], password: UserEntity['password']) {
  try {
    const response = await axios.post<{ token: string, user: UserEntity }>(`${API_BASE_URL}/auth/SignIn`, {
      email: email,
      password: password,
    });
    // Extract the access token from the response
    const { token, user } = response.data;
    // Store the access token in local storage, or use any other suitable storage method
    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);
    window.dispatchEvent(new Event("login"));
    // window.location.reload();
    return { token, user };
  } catch (error) {
    console.error("Error Authenticating user :" + error);
    throw error;
  }
};
export function getAuthUser() {
  const userToken = localStorage.getItem("user-token")
  const userId = localStorage.getItem("userId")
  const user = userId ? JSON.parse(localStorage.getItem("user")) as UserEntity : null
  return { userToken, userId, user };
}

export async function getRestrictedUser(restrictedToken: string) {
  const response = await axios.get<{ user: RestrictedUserEntity }>(`${API_BASE_URL}/auth/restricted`, {
    headers: {
      'user-token': restrictedToken
    }
  });
  return response.data.user
}
export async function migrateRestrictedUser(restrictedToken: string, userData: UserEntity) {
  try {
    const response = await axios.post<{ token: string, user: UserEntity }>(`${API_BASE_URL}/auth/migrateRestrictedUser`,
      userData,
      {
        headers: {
          'user-token': restrictedToken
        }
      });
    const { user, token } = response.data;

    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);

    window.dispatchEvent(new Event("login"));
    return user;
  } catch (error) {
    console.error("Error Signingup user :" + error);
    throw error;
  }
}

export async function getUserGroups(apiClient: AxiosInstance) {
  const response = await apiClient.get<{ rows: GroupEntity[] }>(`${API_BASE_URL}/User/me/groups`);
  return response.data
}
export async function forgetPassword(email: UserEntity['email']): Promise<string> {
  try {
    const response = await axios.post<{ message: string }>(
      `${API_BASE_URL}/auth/forgetPassword`,
      {
        email: email,
      }
    );
    if (response.data && response.data.message) {
      return response.data.message;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error in forgetPassword:', error);
    throw error
  }
}
export async function resetPassword(password: UserEntity['password'], resetPasswordToken: string) {
  try {
    const response = await axios.post<{ token: string, user: UserEntity }>(`${API_BASE_URL}/auth/resetPassword?resetPasswordToken=${resetPasswordToken}`, {
      password: password,
    });
    const { token, user } = response.data;
    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);
    return { token, user };
  } catch (error) {
    console.error("Error in post reset password for the user :" + error);
    throw error;
  }
};
export async function getResetPassword(resetPasswordToken: string) {
  try {
    const response = await axios.get<{ user: UserEntity }>(
      `${API_BASE_URL}/auth/resetPassword?resetPasswordToken=${resetPasswordToken}`
    );

    const { user } = response.data;
    return { user };
  } catch (error) {
    console.error("Error in get reset password for the user: " + error);
    throw error;
  }
};
export function logout() {
  localStorage.removeItem("user-token");
  localStorage.removeItem("user");
  localStorage.removeItem("userId");
  window.dispatchEvent(new Event("logout"));
}
export const getAllUsers = async (apiClient: AxiosInstance) => {
  try {
    const response = await apiClient.get<{ rows: UserEntity[] }>(
      `${API_BASE_URL}/user/all`
    );

    const { rows } = response.data;
    return { rows };
  } catch (error) {
    console.error("Error in get all users " + error);
    throw error;
  }
};
export async function addUser(apiClient: AxiosInstance, firstName: UserEntity['firstName'], lastName: UserEntity['lastName'], email: UserEntity['email'], UserType: UserEntity['UserType']) {
  try {
    const response = await apiClient.post<{ user: UserEntity }>(`${API_BASE_URL}/auth/admin/addUser`, {
      firstName,
      lastName,
      email,
      UserType
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const { user } = response.data;
    return { user };
  } catch (error) {
    console.error("Error in adding user:", error);
    throw error;
  }
};
