import { useRef, useState } from "react";
import FileInput from "../../../common/FileInput";
import ShowCase from "../../../ShowCase";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import apiClientWithLoading from "../../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { FormTemplateClient } from "../../../../services/api/FormTemplateClient";
import { useNavigate } from "react-router-dom";
import PendingAi from "../../../common/newDesign/PendingAi";

function ImportJSON({ onClose , loading, setLoading}) {
  const [file, setFile] = useState<File>(null);
  const { t, language } = useTranslation();
  const modalContentRef = useRef(null);

  const apiClient = apiClientWithLoading(setLoading);
  const formTemplateClient = new FormTemplateClient(apiClient);
  const navigate = useNavigate();
  const handleSave = async () => {
    if (file) {
      setLoading(true);
      try {
        const response = await formTemplateClient.import(file, "json");
        onClose();
        navigate(`/edition-form-template/${response?.rows[0]?.id}`);
        toast.success(t("modals.importContractTemplate.messages.success"));
      } catch (error) {
        setLoading(false);
        toast.error(
            t("modals.importContractTemplate.messages.error")
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading ? (
          <PendingAi height="100%" />
      ) : (
        <div ref={modalContentRef}>
          <div className="modal-body pb-5" style={{ minHeight: "200px" }}>
            <div className="form-floating mb-3 pt-3">
              <FileInput
                onChange={(file) => {
                  setFile(file);
                }}
                value={file}
                borderClassName={file ? "green-border" : "grey-border"}
              />
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ left: "4.5%" }}
                onClick={handleSave}
              >
                {t("modals.importContractTemplate.import")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImportJSON;
