import { createContext } from "react";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, GroupEntity, InitialContractTemplateEntity, SubClauseEntity } from "../domain/entities";
import { ClauseParam, EnumOption, ListOption, SegmentedClauseParam, TableLigne } from "../domain/types/ClauseParams";


export interface EditContractTemplateContextType {
	contractTemplate: ContractTemplateEntity;
	setContractTemplate: React.Dispatch<React.SetStateAction<ContractTemplateEntity>>;
	combinedTemplateParams: ClauseParam[];
	groups:GroupEntity[];
	setCombinedTemplateParams: React.Dispatch<React.SetStateAction<ClauseParam[]>>;
	previewValues: ContractEntity['paramValues'];
	setPreviewValues: React.Dispatch<React.SetStateAction<ContractEntity['paramValues']>>;
	onParamChanged: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam) => void;
	onParamDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, index?: number) => void;
	onOptionAdd: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, option: EnumOption | ListOption | TableLigne | boolean) => void;
	onSegmentChange: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, text: string) => void
	onAddParam: (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, newParam: SegmentedClauseParam, textBefore: string, textAfter: string, field: string) => void;
	contractListParentRef: React.MutableRefObject<any>,
	onOptionChanged: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, option: any, index: number) => void;
	onOptionDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, index: number) => void;
	onParamTypeChange: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: ClauseParam, type: string) => void;
	onParamReorder: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], oldIndex: number, newIndex: number) => void;
	onClauseDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], applyMerge: Boolean) => Promise<ContractTemplateEntity>;
	onSegmentDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string) => void;
	insertClause: (index: number, name: string) => Promise<ContractTemplateEntity>;
	onClauseNameChange : (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], name: string) => void;
	insertSubClause : (insertIndex: number, clauseId: ClauseEntity['id'], subClauseName: string) => Promise<ContractTemplateEntity>;
	initialTemplate: InitialContractTemplateEntity;
	setInitialTemplate: React.Dispatch<React.SetStateAction<InitialContractTemplateEntity>>;
	updateInitialTemplate: () => void;
	insertImportedClause: (insertIndex: number, clauseId: ClauseEntity['id'], newName: string) => Promise<ContractTemplateEntity>;
	insertSubClauseWithContent: (insertIndex: number, clauseId: ClauseEntity['id'], subClause: SubClauseEntity) => Promise<ContractTemplateEntity>;
	onClauseSwap: (index:number, localId: number, externalId: number, externalName: string) => Promise<ContractTemplateEntity>;
	onSelectGroupInClause: (index:string, group:GroupEntity[]) =>void;

}

const EditContractTemplateContext = createContext<EditContractTemplateContextType>({

} as any);

export default EditContractTemplateContext;
