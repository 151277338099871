import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import "./fileInput.scss";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { useTranslation } from "../../../contexts/TranslationProvider";
import FilePreviewModal from "../../modals/FilePreviewModal";
import { DocumentClient } from "../../../services/api/DocumentClient";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { fetchTextFileUrl } from "../../../helpers/helper";
const FileInput = forwardRef<any, any>(
  (
    {
      onChange,
      value,
      label,
      id,
    }: {
      onChange: (file: File) => void;
      value: File | { name: string };
      label?: string;
      id?: number;
    },
    ref
  ) => {
    const fileInputRef = useRef(null);
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileToPreview, setFileToPreview] = useState<File | null>(null);
    const apiClient = apiClientWithLoading();
    const documentClient = new DocumentClient(apiClient);
    const [text, setText] = useState<string>(null);
    useEffect(() => {
      console.log("FileInput:", value);
    }, [value]);
    const triggerFileInput = useCallback(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }, [fileInputRef]);

    useImperativeHandle(ref, () => ({
      triggerFileInput,
    }));

    const handleFileChange = (e) => {
      const files: FileList = e.target.files;
      const file = files[0];
      if (file) {
        onChange(file);
      }
    };

    const deleteFile = () => {
      onChange(null);
      setText("")
    };
    const openFilePreview = () => {
      setFileToPreview(value as File);
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
      setFileToPreview(null);
    };
    useEffect(() => {
      (async () => {
        if (id && Number(id)) {
          try {
            const row = await documentClient.getById(id);
            console.log(row);
            const text = await fetchTextFileUrl(row?.textFile);
            setText(text);
          } catch (err) {
            if (err.response.status == 400) {
              setText("")
            }
          }
        }
      })();
    }, [id, value]);
    return (
      <div
        className="file-input-component"
        style={{
          display: "flex",
          alignItems: "center",

          padding: "5px 4px 5px 10px",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex ms-1">
          {value?.name && (
            <AiFillDelete
              style={{
                marginRight: "0.5rem",
                color: "#2f14e5",
                cursor: "pointer",
              }}
              onClick={deleteFile}
            />
          )}
          <AiOutlineUpload
            style={{
              marginRight: "0.5rem",
              color: "#2f14e5",
              cursor: "pointer",
            }}
            onClick={triggerFileInput}
          />
        </div>
        <p
          onClick={value?.name && openFilePreview}
          className="input-type-file"
          style={{ margin: 0 }}
        >
          {value?.name || label || t("pages.editionContract.params.file")}
        </p>
        <input
          type="file"
          style={{ display: "none", border: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
          value={value ? undefined : ""}
        />
        <FilePreviewModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          file={fileToPreview}
          text={text}
        />
      </div>
    );
  }
);

export default FileInput;
