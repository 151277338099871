import "./navbar.scss";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import EditionContractSaveButton from "../Buttons/EditionContractSaveButton";
import AiContractSaveButton from "../Buttons/AiContractSaveButton";
import React, { useEffect, useState } from "react";
import EditionContractValidateButton from "../Buttons/EditionContractValidateButton";
import { getAuthUser, getRestrictedUser, signIn } from "../../../services/api/UserAuth";
import CustomCombobox from "../CustomCombobox";
import FlagCombobox from "../../common/CustomCombobox/FlagCombobox";
import ExportButton from "../Buttons/ExportButton";
import AuditButton from "../Buttons/AuditButton";
import { logo } from "../../../assets/icons/logo";
import EditFormSaveButton from "../Buttons/EditFormSaveButton";
import ValidationResponseSaveButton from "../Buttons/ValidationResponseSaveButton";
import EditionContractTemplateValidateButton from "../Buttons/EditionContractTemplateValidateButton";
import { flags } from "../../../assets/icons/flags";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { REACT_APP_DEV_MODE } from "../../../config";
import { socket } from "../../../services/SocketServer";
import { toast } from "react-toastify";
import { NotificationEntity, Notification_UserEntity, UserEntity } from "../../../domain/entities";
import apiClientWithLoading from "../../../services/api/ApiClient";
import NotificationPopup from "../../modals/NotificationPopup";
import UserPopup from "../../modals/UserPopup";
import { Notification_UserClient } from "../../../services/api/Notification_UserClient";
import ExportFormButton from "../Buttons/ExportFormButton";
import ActivateTemplateButton from "../Buttons/ActivateTemplate";
import PreviewTemplate from "../Buttons/PreviewTemplate";
import { getMessage } from "../../../helpers/Translation";
import { UserClient } from "../../../services/api/UserClient";
import DocumentSummaryButton from "../Buttons/DocumentSummaryButton";
import SaveSummaryButton from "../Buttons/SaveSummaryButton";
import ShareButton from "../Buttons/ShareButton";
import EditFormTemplateSaveButton from "../Buttons/EditFormTemplateSaveButton";
import EditFormTemplateCommitButton from "../Buttons/EditFormTemplateCommitButton";
import ExportTemplateButton from "../Buttons/ExportContractTemplate";
import ExportFormTemplateButton from "../Buttons/ExportFormTemplate";
const { default: NotificationOn } = require('../../../assets/notificationOn.svg');
const { default: NotificationOff } = require('../../../assets/notificationOff.svg');

function Navbar({ expanded = false, restricted=false }) {
  const location = useLocation();
  const { setLanguage, language } = useTranslation();
  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")
  const [restrictedUser, setRestrictedUser] = useState(null);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const [notificationsData, setNotificationsData] = useState<{
    notifications: Notification_UserEntity[];
    notificationsCount: number | undefined;
  }>({
    notifications: [],
    notificationsCount: undefined,
  });
  const [showNotification, setShowNotification] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const apiClient = apiClientWithLoading()
  const notification_UserClient = new Notification_UserClient(apiClient)
  const userClient = new UserClient(apiClient)
  useEffect(() => {
    if (restricted && restrictedToken) {
      const fetchRestrictedUser = async () => {
        try {
          const user = await getRestrictedUser(restrictedToken);
          setRestrictedUser(user);
        } catch (error) {
          console.error("Failed to fetch restricted user:", error);
        }
      };

      fetchRestrictedUser();
    }
  }, [restricted, restrictedToken]);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await notification_UserClient.getAll();
        setNotificationsData({
          notifications: response?.rows,
          notificationsCount: response?.count,
        });
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    fetchNotifications();
    const onNotification = (notification: NotificationEntity) => {
      console.log('notification', notification);

      toast(getMessage(notification, language)!)
    }
    socket?.on('notification', onNotification)
    return () => {
      socket?.off('notification', onNotification)
    }
  }, [socket])
  const handleNotificationClick = (id: NotificationEntity["id"]) => {
    const result = notificationsData?.notifications.find((item) => item?.Notification?.id == id)
    if (result) {
      result.seenDate = new Date()
      setNotificationsData({ ...notificationsData })
    }
  }
  const { user, userId, userToken } = getAuthUser();
  const storedLanguage = localStorage.getItem("language") || "fr";
  const [flag, setFlag] = React.useState({
    language: storedLanguage,
    flag: flags[storedLanguage],
  });

  useEffect(() => {
    setFlag(languages.find((element) => element.language == language));
  }, [language]);

  const users: UserEntity[] = [
    {
      email: "contractzlab.admin@yopmail.com",
      password: "admin",
    },
    {
      email: "fares@contractzlab.com",
      password: "123456",
    },
    {
      email: "rafik@contractzlab.com",
      password: "123456",
    },
  ];
  const handleFlagChange = async (value) => {
    setLanguage(value);
    userClient.setLanguage(value)
    setFlag(languages.find((element) => element.language == value));
  }
  const languages = [
    {
      language: "fr",
      flag: flags.fr,
    },
    {
      language: "en",
      flag: flags.en,
    },
    {
      language: "ar",
      flag: flags.ar,
    },
  ];
  const handleInputChange = async (value) => {
    console.log({ user, userId, userToken });
    const userOption = users.find((user) => user.email == value);
    if (userOption) {
      await signIn(userOption.email, userOption.password);
    }
  };
  const isEditionPath = ["/edition-contrat", "/ai-edition-contrat", "/edit-form", "/ai-document-response", "/edition-contrat-template"].includes(location.pathname);

  return (
    <div className="navbar-custom d-flex justify-content-between align-items-center px-2 position-sticky top-0">
      <Link to={"/"}>
        {expanded||restricted ? logo.full : logo.default}
      </Link>
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        {location.pathname.includes("/edition-contrat")&& !location.pathname.includes("/edition-contrat-template") ? (
          <React.Fragment>
            {!restrictedToken && (
              <React.Fragment>
                <EditionContractValidateButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}

            {!restrictedToken && (
              <React.Fragment>
                <AuditButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}

            <EditionContractSaveButton />
            <div className="vertical-line me-5"></div>

            {!restrictedToken && (
              <React.Fragment>
                <ExportButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}
              {!restrictedToken && (
              <React.Fragment>
                <ShareButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : location.pathname.includes("/ai-edition-contrat") ? (
          <React.Fragment>
            <AiContractSaveButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/edit-form") ? (
          <div className="d-flex" style={{ marginLeft: "25%" }}>
            <React.Fragment>
              <div className="vertical-line me-5"></div>
              <EditFormSaveButton />
              <div className="vertical-line me-5"></div>
              <ExportFormButton />
              <div className="vertical-line me-5"></div>
            </React.Fragment>
          </div>
        ) : location.pathname.includes("/ai-document-response") ? (
          <React.Fragment>
            <ValidationResponseSaveButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/edition-contrat-template")  ? (
          <React.Fragment>
            <EditionContractTemplateValidateButton />
            <div className="vertical-line me-5"></div>
            <ExportTemplateButton />
            <div className="vertical-line me-5"></div>
            <ActivateTemplateButton />
            <div className="vertical-line me-5"></div>
            <PreviewTemplate />
          </React.Fragment>
        ) : location.pathname.includes("/document/") ? (
          <React.Fragment>
            <DocumentSummaryButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/document-summary/") ? (
          <React.Fragment>
            <SaveSummaryButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>): location.pathname.includes("/edition-form-template/") ? (
          <React.Fragment>
            <EditFormTemplateSaveButton />
            <div className="vertical-line me-5"></div>
            <EditFormTemplateCommitButton />
            <div className="vertical-line me-5"></div>
            <ExportFormTemplateButton/>
          </React.Fragment>):null}
      </div>
      <div>
        <div>
          <FlagCombobox
            optionValue={(option) => option?.language || null}
            optionDisplay={(option) => option?.flag || ""}
          />
        </div>
      </div>
      {!restricted&&(<div className="notification-container">
        <img src={notificationsData?.notificationsCount == undefined || 0 ? NotificationOff : NotificationOn} width={notificationsData?.notificationsCount !== undefined || 0 ? 31 : 22} className="notification-icon" onClick={() => setShowNotification(true)} />
        {showNotification && (
          <NotificationPopup onNotificationClick={handleNotificationClick} notifications={notificationsData?.notifications} onClose={() => setShowNotification(false)} />
        )}
      </div>)}
      {!restricted&&<div className="vertical-line me-5"></div>}
      {isEditionPath || !REACT_APP_DEV_MODE ? (
        <div className="user-display">
          {restricted===false ? (
          <><div className="avatar-user" onClick={() => setIsPopupOpen(true)}>{user?.email ? user.email.charAt(0).toUpperCase() : ''}</div>
          <div className="username-role">
            <h6>{user?.firstName}</h6>
            <h6>{user?.lastName}</h6>
          </div>
          {isPopupOpen && <UserPopup onClose={() => setIsPopupOpen(false)} />}
          </>) : (<>
          {restrictedUser?.email}
            </>)}
        </div>
      ) : (
        <div>
          <div>
            <CustomCombobox
              label=""
              onDropdownOpen={() => { }}
              options={users}
              value={user}
              onChange={handleInputChange}
              optionValue={(option) => option?.email || null}
              optionDisplay={(option) => option?.email || ""}
              isOpen={openCombobox==="login"}
              setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "login" : null)}
            />
          </div>
          <div className="vertical-line me-5"></div>
        </div>
      )}

      <div className="vertical-line me-5"></div>
    </div>
  );
}

export default Navbar;
