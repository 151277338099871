import React, { useEffect, useState } from "react";
import "./ImportRules.scss";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { useTranslation } from "../../contexts/TranslationProvider";
import FileInput from "../../components/common/FileInput";
import { toast } from "react-toastify";
import { RuleClient } from "../../services/api/RulesClient";
import { RuleCronEntity, RuleEntity, RulePenalityEntity } from "../../domain/entities/Rule";
import { useNavigate } from "react-router-dom";
import { getMessage, getName } from "../../helpers/Translation";

function ImportRulesPage() {
  const apiClient = apiClientWithLoading();
  const RulesClient = new RuleClient(apiClient)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState<File>(null);
  const { t, language } = useTranslation();
  const cronTranslated = t("pages.rules.tableCron")
  const penalityTranslated = t("pages.rules.tablePenality")
  const [rulesCron, setRulesCron] = useState<RuleCronEntity[]>([])
  const [rulesPenality, setRulesPenality] = useState<RulePenalityEntity[]>([])
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await RulesClient.getAll();
      const cronRules = data?.rows.filter((rule) => rule.type === 'Cron');
      const penaltyRules = data?.rows.filter((rule) => rule.type === 'Penality');

      setRulesCron(cronRules as RuleCronEntity[]);
      setRulesPenality(penaltyRules as RulePenalityEntity[]);
    };
    setLoading(false);

    fetchData();
  }, []);

  const handleSave = async () => {
    if (file) {
      const apiClient = apiClientWithLoading(setLoading, "multipart/form-data");
      setLoading(true)
      try {
        await RulesClient.import(file)
        toast.success(t("modals.importRules.messages.success"));
      } catch (error) {
        toast.error(error?.message ?? error ?? t("modals.importRules.messages.error"));
      } finally {
        setLoading(false)
      }
    }
  };


  return (

    <section className="w-100 p-3">
      <div style={{ marginBottom: "40px", marginTop: "3px" }}>
        <span>
          <span className='previous-pathname' onClick={() => navigate("/admin")}>
            {t("sidebar.admin")}
          </span>
          <span className="path-separator"> {">"} </span>
        </span>
        <span className='current-pathname'>{t("modals.importRules.title")}</span>
      </div>
      {loading ? (
        <Loading height="50vh" />
      ) : (
        <div className="">
          <div className="scroll-table table-import-rules">
            <h5>{t(cronTranslated.title)}</h5>
            <table style={{ borderCollapse: "collapse", width: "100%" }} className="table-cron">
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.code)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(cronTranslated.headers.name)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(cronTranslated.headers.level1)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.level2)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.level3)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.activated)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(cronTranslated.headers.message)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.cronSchedule)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.targetParam)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(cronTranslated.headers.targetValue)}</th>
                </tr>
              </thead>
              <tbody>
                {rulesCron?.map((rule) => (
                  <tr key={rule.id}>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.code}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level1, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level2, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level3, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.activated?.toString()}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getMessage(rule, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.cronSchedule}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.targetParam}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.targetValue}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h5 className="mt-3">{t(penalityTranslated.title)}</h5>
            <table style={{ borderCollapse: "collapse", width: "100%" }} className="table-penality">
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.code)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(penalityTranslated.headers.name)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(penalityTranslated.headers.level1)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.level2)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.level3)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.activated)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}>{t(penalityTranslated.headers.message)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.penalityParam)}</th>
                  <th style={{ border: "1px solid black", paddingLeft: "4px", textAlign: "center" }}> {t(penalityTranslated.headers.applicationNameParam)}</th>
                </tr>
              </thead>
              <tbody>
                {rulesPenality?.map((rule) => (
                  <tr key={rule.id}>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.code}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level1, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level2, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getName(rule.level3, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.activated?.toString()}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{getMessage(rule, language)}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.penalityParam}</td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>{rule.applicationNameParam}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="actions-import-rules">
            <div className="form-floating mb-3 pt-3" style={{ width: "60vh" }}>
              <FileInput
                onChange={(file) => setFile(file)}
                value={file}
                borderClassName={file ? "green-border" : "grey-border"}
              />
            </div>

            <div style={{ paddingBottom: "3%", width: "60vh" }}>
              {
                <button type="submit" className=" action-import-rules" onClick={handleSave}>
                  {t("modals.importRules.import")}
                </button>
              }
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default ImportRulesPage;
