import React, { useEffect, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import { getAllUsers } from "../../services/api/UserAuth";
import { UserEntity } from "../../domain/entities";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import "./usersPage.scss";
import AddUser from "../../components/modals/AddUser";
import { AiFillDelete } from "react-icons/ai";
import ConfirmDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
const { default: EditIcon } = require("../../assets/edit_icon.svg");

function Users() {
  const { t, language } = useTranslation();
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const apiClient = apiClientWithLoading();
  const [showModal, setShowModal] = useState(false);
  const [newUserAdded, setNewUser] = useState(false);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [userToHandle, setUserToHandle] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { rows: validUsers } = await getAllUsers(apiClient);
        setUsers(validUsers);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
    setNewUser(false);
  }, [newUserAdded]);

  return (
    <>
      {loading ? <Loading /> : <section className="users-list w-100">
        <span className="current-pathname">{t("pages.users.title")}</span>
        <div className="table-container">
          <table
            className={`fixed-header-table ${language == "ar" && "arabic-table"
              } `}
          >
            <thead>
              <tr>
                <th>{t("pages.users.firstname")}</th>
                <th>{t("pages.users.lastname")}</th>
                <th>{t("pages.users.email")}</th>
                <th>{t("pages.users.status.label")}</th>
                <th>{t("pages.users.userRole")}</th>
                <th>{t("pages.users.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr key={user.id} id={`user_${user.id}`} className="user-row">
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{t(`pages.users.status.${user.activated}`)}</td>
                  <td>{t(`pages.users.userType.${user.UserType}`)}</td>

                  <td>
                    <img
                      src={EditIcon}
                      alt="edit"
                      className="edit-user"
                      onClick={() => { setUserToHandle(user); setShowModal(true) }}
                    />
                    <AiFillDelete
                      className="delete-user"
                      style={{
                        color: "#e5142f",
                        cursor: "pointer",
                        marginTop: "1px",
                        fontSize: "18px"
                      }}
                      onClick={() => { setUserToHandle(user); setConfirmDelete(true) }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="button-add-new-user">
          <div className="vertical-line me-3"></div>
          <button className="action-add-user" onClick={() => { setUserToHandle({}); setShowModal(true) }}>
            {t("pages.users.addUser")}
          </button>
        </div>
        <div className="import-document-modal">
          {showModal && (
            <AddUser
              user={userToHandle}
              titleEdit="edit user"
              setNewUser={setNewUser}
              onClose={() => setShowModal(false)}
            />
          )}
          {showConfirmDelete && (
            <ConfirmDeleteMessage
              setNewUser={setNewUser}
              msgDeleteUser={t("pages.users.msgDeleteUser")}
              onClose={() => setConfirmDelete(false)}
              user={userToHandle}
            />
          )}
        </div>
      </section>}
    </>
  );
}
export default Users;
