import React, { useMemo, useCallback, useRef, useEffect, useState, useContext } from 'react'
import { Editor, Transforms, Range, createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
} from 'slate-react'
import { MentionElement } from './custom-types'
import "./stale.scss"
import AddParamDialog from './AddParamDialog'
import Element from './Element'
import Leaf from './Leaf'
import EditContractTemplateContext from '../../../../contexts/EditContractTemplateContext'
import { ClauseEntity, SubClauseEntity } from "../../../../domain/entities";
import { buildBlocks, useOutsideAlerter } from './helper'
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { set } from 'react-hook-form'
import { SegmentedTextType } from '../../../../domain/types/ClauseParams'

interface SlateEditorProps {
  clauseId: ClauseEntity['id'];
  subClauseId?: SubClauseEntity['id'];
  params: any;
  onSegmentChange: (id: string, text: string) => void;
  segments: any;
  setOpenedPopups: (num: any) => void;
  openPopups: Boolean
}

const SlateEditor = ({ segments, params, onSegmentChange, clauseId, subClauseId, setOpenedPopups, openPopups }: SlateEditorProps) => {

  const { onSegmentDelete, onAddParam, combinedTemplateParams } = useContext(EditContractTemplateContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const [blocks, setBlocks] = useState(buildBlocks(segments ? segments : [], params));
  const [chars, setChars] = useState(combinedTemplateParams)
  const [target, setTarget] = useState<Range | undefined>(undefined)
  const [index, setIndex] = useState(0)
  const divRef = useRef(null);
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const [currentSegment, setCurrentSegment] = useState<any | null>({ id: "", text: "" })
  const [search, setSearch] = useState('')
  const { language } = useTranslation()

  const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
      return element.type === 'mention' ? true : isInline(element)
    }

    editor.isVoid = element => {
      return element.type === 'mention' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
      return element.type === 'mention' || markableVoid(element)
    }

    return editor
  }
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  )

  useEffect(() => {
    const updateEditorContent = (editor, segments, params) => {
      const newBlocks = buildBlocks(segments, params) as any;
      // reset state editor to new 
      setBlocks(newBlocks);
      if (editor.selection) {
        if (editor.selection.anchor.path.length > 2) {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        const selectedIdx = editor.selection.anchor.path[1]
        const selectedNewBlock = newBlocks[0].children[selectedIdx]
        const selectedOld = Editor.node(editor, editor.selection)[0] as any
        const offsets = [editor.selection.anchor.offset, editor.selection.focus.offset]

        if (!selectedNewBlock) {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        if (selectedNewBlock.type === "mention") {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        offsets.forEach((offset, idx) => {
          if (offset > selectedNewBlock.text.length - 1) {
            editor.selection = null;
            setCurrentSegment({ id: "", text: "" })
            editor.children = newBlocks;
            return;
          }
        })
        editor.children = newBlocks;
      } else {
        editor.children = newBlocks;
      }
    };
    setChars(combinedTemplateParams)
    updateEditorContent(editor, segments, params);
  }, [segments, params]);

  useEffect(() => {
    setChars(combinedTemplateParams)
  }, [combinedTemplateParams])

  const cleanComments = () => {
    try{
       const segmentationComments = segments.filter((segment) => segment.type === SegmentedTextType.COMMENT)
    segmentationComments.forEach(element => {
      const [paragraphIdx, selectionIdx] = editor.selection.anchor.path
      const actualParagraph: any = editor.children[paragraphIdx]
      const children = actualParagraph.children
      if(children){
        const found = children.find((child) => child.id === element.id)
        if(!found){
          onSegmentDelete(clauseId, subClauseId, element.id)
        }
      }
    });
    }catch(err){
      console.log("error cleaning comments: " + err)
    }
   
  }

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text/plain')
    const { selection } = editor

    // insert text at the current selection
    if (selection) {
      Transforms.insertText(editor, text, { at: selection })
    }
    event.preventDefault()
  }

  const onKeyDown = useCallback(
    event => {
      if (target && chars.length > 0) {

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            handleInsert(chars[index], "")
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        Transforms.insertText(editor, '\n');
      }
      // handle deletion of mention when backspace is pressed on a mention element. dont check for target
      if (event.key === 'Backspace' && editor.selection && editor.selection.anchor.path.length > 2) {
        // get the parent
        const parent: any = Editor.parent(editor, editor.selection)
        if (parent[0].type === 'mention') {
          onSegmentDelete(clauseId, subClauseId, parent[0].id)
        }
      }
      // prevent deletion of segments from text editor
      if (event.key === 'Backspace' && editor.selection && (editor.selection.anchor.offset === 0 || editor.selection.focus.offset === 0)) { 
        event.preventDefault()
        return;
      }
      // prevent deletion of segments from text editor
      if (event.key === 'Backspace') {
        if (!editor.selection) {
          event.preventDefault()
          return;
        }
        const currentNode: any = Editor.node(editor, editor.selection)
        if (!currentNode[0].text) {
          event.preventDefault()
        } else if (currentNode[0].text.length === 1) {
          const newSelection = { anchor: { offset: 0, path: editor.selection.anchor.path }, focus: { offset: 0, path: editor.selection.focus.path } }
          // after event is concluded, insert space
          setTimeout(() => {
            Editor.insertText(editor, ' ', { at: editor.selection })
          }, 0)
        }
      }

    },
    [chars, editor, index, target]
  )

  const updatePopupPosition = (rect, el) => {
    try {
      if (rect && el && divRef.current) {
        //update position of dialog inside the divRef
        const divRect = divRef.current.getBoundingClientRect()
        const top = rect.top - divRect.top
        const left = rect.left - divRect.left
        const divRectWidth = divRect.width
        el.style.display = 'block'
        el.style.top = `${top + 20}px`
        if (language === 'ar') {
          if (left < 270) {
            el.style.left = `${left}px`
            el.style.right = 'auto'
          } else {
            el.style.left = `${left - 260}px`
            el.style.right = 'auto'
          }
        } else {
          //el.style.left = `${left + 15}px`
          if (left + 270 > divRectWidth) {
            el.style.left = `${left - 250}px`
          } else {
            el.style.left = `${left + 15}px`
          }
          el.style.right = 'auto'
        }
      }
    } catch (e) {
      console.log(e)
    }

  }

  useEffect(() => {
    if (target) {
      // check if target is present in editor
      let domRange = null
      try {
        domRange = ReactEditor.toDOMRange(editor, target)
      } catch (e) {
        console.log(e)
      }
      if (!domRange) {
        setTarget(null)
        setOpenedPopups(false)
        return
      }
      setOpenedPopups(true)
      const rect = domRange.getBoundingClientRect()
      const el = ref.current
      updatePopupPosition(rect, el)
    } else {
      const el = ref.current
      if (el) {
        el.style.display = 'none'
      }
      setOpenedPopups(false)
    }
  }, [chars.length, editor, index, target, language])


  // not used for now, maybe used later
  const insertMention = (editor, param) => {
    const mention: MentionElement = {
      type: 'mention',
      character: param.label,
      children: [{ text: param.name }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  }


  const clickOutsideHandler = useCallback(() => {
    if (currentSegment && currentSegment.id != "" && currentSegment.type != "mention") {
      onSegmentChange(currentSegment.id, currentSegment.text)
      setCurrentSegment({ id: "", text: "" })
    }
  }, [currentSegment])

  const handleInsert = (char, field) => {
    if (currentSegment.id != "" && currentSegment.type != "mention" && target.anchor && target.focus) {

      let textBefore: string = ""
      let textAfter: string = ""
      if (language === 'ar') {
        textBefore = currentSegment.text.substring(0, target.focus.offset - 1)
        textAfter = currentSegment.text.substring(target.focus.offset)
      } else {
        textBefore = currentSegment.text.substring(0, target.anchor.offset)
        textAfter = currentSegment.text.substring(target.focus.offset)
      }
      onAddParam(clauseId, subClauseId, currentSegment.id, char, textBefore, textAfter, field)
      editor.selection = null;
      setCurrentSegment({ id: "", text: "" })
      setTarget(null)
    }
  }

  const handleClose = () => {
    setOpenedPopups(false)
    setTarget(null)
  }


  // if click outside set selection null
  useOutsideAlerter(divRef, clickOutsideHandler, currentSegment);
  return (
    <div ref={divRef} style={{ backgroundColor: "white" }} className='stale'>
      <Slate
        editor={editor}
        initialValue={blocks}
        onValueChange={value => {
          if (!currentSegment) return;
          if (editor.selection) {
            const [paragraphIdx, selectionIdx] = editor.selection.anchor.path
            const actualParagraph: any = editor.children[paragraphIdx]
            const actualSegment: any = actualParagraph.children[selectionIdx]
            if (actualSegment) {
              setCurrentSegment(actualSegment)
            }
          }
        }}
        onSelectionChange={(selection) => {
          const [paragraphIdx, selectionIdx] = selection.anchor.path
          const actualParagraph: any = editor.children[paragraphIdx]
          const actualSegment: any = actualParagraph.children[selectionIdx]
          if (!actualSegment.id) return;
          if (currentSegment.id == actualSegment.id && currentSegment.text == actualSegment.text) return;
          if (currentSegment.id == "" && currentSegment.text == "") {
            setCurrentSegment(actualSegment)
            return;
          }
          if (currentSegment.type != 'mention') {
            onSegmentChange(currentSegment.id, currentSegment.text)
          }
          setCurrentSegment(actualSegment)
        }}
        onChange={(value) => {
          const { selection } = editor
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const node = Editor.node(editor, start)
            const enteredChar = (node[0] as any).text.charAt(start.offset - 1)
            // change logic for arabic language
            if (enteredChar == "@" && language === 'ar') {
              setTarget(editor.selection)
              setIndex(0)
              return
            }
            if (enteredChar !== "@" && language === 'ar') {
              setTarget(null)
              return
            }
            const beforeMatch = beforeText && beforeText.match(/@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }
          setTarget(null)
          cleanComments()
        }}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
          placeholder="Enter some text..."
        />
        <div
          ref={ref}
          style={{
            position: 'absolute',
            zIndex: 9999,
            padding: '3px',
            background: 'white',
            borderRadius: '4px',
            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
            border: '1px solid #2F14E5',
            width: '270px',
            display: 'none'
          }}
          data-cy="mentions-portal"
        >
          <AddParamDialog handleClose={handleClose} search={search} chars={chars} insertMention={handleInsert} index={index} />
        </div>
      </Slate>
    </div>
  )
}
export default SlateEditor
