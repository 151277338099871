import { AxiosInstance } from "axios";
import { FormEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";
import { CreateFormFromDocRequest } from "../../domain/Form";

export class FormClient extends ModelClient<FormEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Form')
  }

  async generateFormFromDocAndTemplate(req: CreateFormFromDocRequest){
    const url = new URL(this.modelUrl + "/generateWithAIAndFormTemplate")

    const formData = new FormData();
    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    url.searchParams.append("templateId", String(req.templateId))
    const response = await this.apiClient.post<{ row: FormEntity }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
}