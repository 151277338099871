import "./ressources.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import RessourcesList from "./RessourcesList";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractEntity } from "../../domain/entities";
import { ContractClient } from "../../services/api/ContractClient";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { FormClient } from "../../services/api/FormClient";
import CustomCombobox from "../../components/common/CustomCombobox";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import calculate from "../../assets/new-design-icons/Calculate.svg"
import chat from "../../assets/new-design-icons/Chat.svg"
import { set } from "react-hook-form";
const docIcon = require('../../assets/new-design-icons/doc.png')
function MyRessources() {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const [loading, setLoading] = useState(true);
  const [combinedRessources, setCombinedRessources] = useState<any[]>([]);
  const [combinedFilteredRessources, setCombinedFilteredRessources] = useState<any[]>([]);
  const [resultRessources, setResultRessources] = useState<any[]>([]);
  const [appliedFilters, setAppliedFilters] = useState({ type: null });
  const [typeFilter, setTypeFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const { t } = useTranslation();
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const contractAIClient = new ContractAIClient(apiClient)
  const documentClient = new DocumentClient(apiClient)
  const formClient = new FormClient(apiClient)
  const translationPath = "pages.ressources."
  const types = [
    {
      name: t(translationPath + "types.contract"),
      id: "Contract",
      icon: contractIcon
    },
    {
      name: t(translationPath + "types.document"),
      id: "Document",
      icon: docIcon
    },
    {
      name: t(translationPath + "types.form"),
      id: "Form",
      icon: formIcon
    }
  ]
  const loadRessources = useCallback(async () => {
    try {
      const allContracts = await contractClient.getAll();
      //const allContractAI = await contractAIClient.getAll()
      const allDocuments = await documentClient.getAll("full");
      const allForms = await formClient.getAll();
      const contractResult = allContracts.rows.map(c => ({ ...c, id: c.id, status: c.status as ContractEntity['status'], type: 'Contract' }))
      //const contractAIResult = allContractAI.rows.map(c => ({ ...c, id: c.id, type: 'ContractAI' }))
      const documentResult = allDocuments.rows.map(d => {
        if(d.summary && d.summary.id){
          return { ...d, type: 'Summary'}
        }else{
          return { ...d, type: 'Document'}
        }
      }
      )
      // reset filter
      setTypeFilter(null);
      const formResult = allForms.rows.map(f => ({ ...f, id: f.id, type: 'Form' }))
      const combinedRessources = [...documentResult, ...formResult, ...contractResult]
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      setCombinedRessources(combinedRessources);
      setCombinedFilteredRessources(combinedRessources);
      setResultRessources(combinedRessources);
      setLoading(false); // Set loading to false after contracts are fetched
    } catch (error) {
      console.error("Error fetching contracts: ", error);
      setLoading(false); // Also set loading to false in case of error
    }
  }, [])

  useEffect(() => {
    loadRessources();
  }, [loadRessources]);

  useEffect(() => {
    setResultRessources(combinedFilteredRessources
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .filter((r) => r!.name?.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm])

  const handleTypeFilterChange = (type) => {
    setAppliedFilters(prev => {
      return { ...prev, type: type }
    });
    setTypeFilter(types.find(t => t.id === type));
  }
  useEffect(() => {
    const typeFilter = appliedFilters.type;
    let filteredRessources = combinedRessources;
    if (typeFilter) {
      if(typeFilter === 'Contract'){
        filteredRessources = filteredRessources.filter(r => (r.type === 'Contract' || r.type === 'Summary'));
      }else{
         filteredRessources = filteredRessources.filter(r => r.type.includes(typeFilter));
      }
    }
    setCombinedFilteredRessources(filteredRessources);
  }, [appliedFilters])

  useEffect(() => {
    setResultRessources(combinedFilteredRessources);
  }, [combinedFilteredRessources])
  return (
    <div  style={{width: "100%", flex: 1}} className="w-100">
      <section id="contracts" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.ressources.title")}</span>
        <div className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => { }}
                options={types}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (<>
                  {option ? <div className="filter-type-item">
                    <div className='icon'>
                      {
                        option.id === 'Form' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                          option.id === 'Contract' || option.id === 'ContractAI' || option.id === 'Summary' ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                            option.id === 'Document' ? <img src={docIcon} alt="icon" style={{ width: "25px", height: "25px" }} /> :
                              <></>
                      }
                    </div>
                    <span>
                      {option.name}
                    </span>
                  </div> :
                    <span>
                      {t(translationPath + "type")}
                    </span>
                  }
                </>)}
                fullWidth={true}
                isOpen={openCombobox === "ressourceFilter"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "ressourceFilter" : null)}
              />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="ressources-list">
          {loading ? (
            <div style={{width: "83vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Loading height="75vh" />
            </div>
          ) : (
            <RessourcesList
              ressources={resultRessources}
              reloadRessources={loadRessources}
            />
          )}
        </div>
      </section>
    </div>
  );
}

export default MyRessources;
