import React from 'react'
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { forgetPassword } from '../../services/api/UserAuth';
import CarouselContainer from '../../components/common/Carousel';
import "./forgetPassword.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
interface FormData {
    email: string,
}
const ForgetPassword = () => {
    const {t, language}=useTranslation()
    const translationPath=t("pages.forgetPassword")
    const [formData, setFormData] = useState<FormData>({
        email: ''
    });

    const [errors, setErrors] = useState<Record<string, string>>({
        email: ''
    });
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newErrors: Record<string, string> = {};

        if (!formData.email.trim()) {
            newErrors.email = t(translationPath.errorMessages.emptyEmail);
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const message = await forgetPassword(formData.email);

                console.log('Password reset request successful:', message);
                setPasswordResetSuccess(true);
            } catch (error) {
                console.error('Error sending password reset request:', error);
            }
        }
    };
    return (
        <div className='forget-password-content'  style={{paddingRight:language=="ar"&&"100px"}}>
            <div className='sign-up-sub-content'>
                <div className='logo-contractzlab'></div>
                <h1 className='title-sign-up'>{t(translationPath.title)}  <p className='password-reset-instructions'>
                    {t(translationPath.instructions)}
                </p></h1>
                {passwordResetSuccess && (
                    <div className="alert alert-success" style={{ width: "76%" }}>
                        {t(translationPath.successMessage)}
                    </div>
                )}
                <form onSubmit={handleSubmit} style={{ marginTop: '18%' }}>
                    <div className="form-group signup-group">
                        <label htmlFor="email" className="label-sign-up-form">
                            {t(translationPath.emailLabel)}
                        </label>
                        <input
                            type="email"
                            className={` input-sign-up form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            placeholder="name@example.com"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}

                    </div>
                    <button type="submit" className="btn btn-primary btn-signup">
                        {t(translationPath.sendButton)}
                    </button>
                </form>
            </div>
            <CarouselContainer/>
        </div>
    )
}

export default ForgetPassword