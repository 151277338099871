import  { createContext, Dispatch, SetStateAction } from 'react';
import { ProjectEntity, ProjectTemplateEntity } from '../domain/entities';

export interface ProjectContextType {
  projects: ProjectEntity[]; 
  projectTemplate: ProjectTemplateEntity[]; 
  setProjects: Dispatch<SetStateAction<ProjectEntity[]>>; 
  setProjectTemplate: Dispatch<SetStateAction<ProjectTemplateEntity[]>>; 
}

const ProjectContext = createContext<ProjectContextType>({
  
}as any);

export default ProjectContext;