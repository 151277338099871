
import { useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import "./analytics.scss";
import GroupBarChart from "./charts/GroupBarChart";
import TypesBarChart from "./charts/TypesPieChart";
import StatusBarChart from "./charts/StatusBarChart";
import ContractTable from "./ContractTable";
import CustomCombobox from "../../components/common/CustomCombobox";
import CPAGraphs from "./CPAGraphs";
import useTranslation from "../../hooks/useTranslation";
import { TypeLevel1Client } from "../../services/api/TypeLevel1Client";
import { ContractClient } from "../../services/api/ContractClient";
import { GroupClient } from "../../services/api/GroupClient";
import { ContractEntity, GroupEntity } from "../../domain/entities";
import { getName } from "../../helpers/Translation";

function Analytics() {
    const [loading, setLoading] = useState(true);
    const [contracts, setContracts] = useState([]);
    const [groups, setGroups] = useState<GroupEntity[]>([]);
    const [typeLevels, setTypeLevels] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([])
    const [contractList, setContractList] = useState([])
    const [status, setStatus] = useState("")
    const [filterGroup, setFilterGroup] = useState<GroupEntity['id']>(null)
    const [filterStatus, setFilterStatus] = useState<ContractEntity['status']>(null)
    const [appliedFilters, setAppliedFilters] = useState([])
    const apiClient = apiClientWithLoading();
    const typeLevel1Client = new TypeLevel1Client(apiClient)
    const contractClient = new ContractClient(apiClient)
    const groupClient = new GroupClient(apiClient)
    const { t, language } = useTranslation();
    const translationPath = "pages.analytics";
    const [openCombobox, setOpenCombobox] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const types = await typeLevel1Client.getAllWithContractTemplate(language);
                const data = await contractClient.getAll();
                const groupData = await groupClient.getAll();
                const contrats = data.rows;
                const groups = groupData.rows;
                setGroups(groups);
                setContracts(contrats);
                setContractList(contrats);
                setFilteredContracts(contrats);
                setTypeLevels(types.rows);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching contracts: ", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (status === "") {
            setFilteredContracts(contracts)
        }
        else {
            setFilteredContracts(contracts.filter((contract) => contract?.status?.toLowerCase().includes(status.toLowerCase())))
        }
    }, [status])

    useEffect(() => {
        applyFilters()
    }, [appliedFilters])

    const statuses: {
        value: ContractEntity['status'];
        display: string;
    }[] = [
            {
                value: "Draft",
                display: t("status.Draft")
            },
            {
                value: "Negotiation",
                display: t("status.Negotiation")
            },
            {
                value: "ToBeSigned",
                display: t("status.ToBeSigned")
            },
            {
                value: "Signed",
                display: t("status.Signed")
            },
            {
                value: "Done",
                display: t("status.Done")
            },
        ]
    const applyFilters = () => {
        let filtered = contracts;
        appliedFilters.forEach((filter) => {
            if (filter.type === "group") {
                filtered = filtered.filter((contract) => contract?.assignedToGroupId === parseInt(filter.value))
            }
            else if (filter.type === "status") {
                filtered = filtered.filter((contract) => contract?.status?.toLowerCase().includes(filter.value.toLowerCase()))
            }
        })
        setContractList(filtered)
    }
    const handleGroupChange = (value: GroupEntity['id']) => {
        setFilterGroup(value);
        if (!value) {
            setAppliedFilters([...appliedFilters.filter(param => param.type !== "group")])
        }
        else {
            setAppliedFilters([...appliedFilters.filter(param => param.type !== "group"), { type: "group", value: value, display: getName(groups.find((group) => group.id === value), language) }])
        }
        applyFilters()
    }
    const handleStatusChange = (value: ContractEntity['status']) => {
        setFilterStatus(value);
        if (!value) {
            setAppliedFilters([...appliedFilters.filter(param => param.type !== "status")])
        }
        else {
            setAppliedFilters([...appliedFilters.filter(param => param.type !== "status"), { type: "status", value: value, display: statuses.find((status) => status.value === value).display }])
        }
        applyFilters()
    }

    const removeFilter = (filter) => {
        if (filter.type === "group") {
            setFilterGroup(null);
        }
        else if (filter.type === "status") {
            setFilterStatus(null);
        }
        setAppliedFilters([...appliedFilters.filter(param => param.type !== filter.type)])
    }

    return (
        <div style={{ width: "95vw" }} className="analytics">
            {loading ? (
                <div className="contract-loading-container">
                    <Loading height="90vh" />
                </div>
            ) : (
                <div className="px-3">
                    <section id="greetings">
                        <div className="mt-2">
                        <span className="current-pathname">
                            {t(`${translationPath}.title`)}
                        </span>
                        </div>
                    </section>
                    <div className="borderB" style={{ display: "flex", justifyContent: "left", alignItems: "left", marginTop: "20px" }}>
                        <StatusBarChart contracts={contracts} statuses={['Draft', "Done", "Negotiation", "ToBeSigned", "Signed"]} />
                        <div className="status-group">
                            <div className={`status-btn ${status == "" && "active-status"}`} onClick={() => setStatus("")}>
                                {t("status.all")}
                            </div>
                            <div className={`status-btn ${status == "Draft" && "active-status"}`} onClick={() => setStatus("Draft")}>
                                {t("status.Draft")}
                            </div>
                            <div className={`status-btn ${status == "Negotiation" && "active-status"}`} onClick={() => setStatus("Negotiation")}>
                                {t("status.Negotiation")}
                            </div>
                            <div className={`status-btn ${status == "ToBeSigned" && "active-status"}`} onClick={() => setStatus("ToBeSigned")}>
                                {t("status.ToBeSigned")}
                            </div>
                            <div className={`status-btn ${status == "Signed" && "active-status"}`} onClick={() => setStatus("Signed")}>
                                {t("status.Signed")}
                            </div>
                            <div className={`status-btn ${status == "Done" && "active-status"}`} onClick={() => setStatus("Done")}>
                                {t("status.Done")}
                            </div>
                        </div>
                        <div className="charts" id="main-function">
                            <GroupBarChart contracts={filteredContracts} groups={groups} />
                            <TypesBarChart contracts={filteredContracts} typeLevels={typeLevels} />
                        </div>
                    </div>
                    <div className="custom-charts-container borderB">
                        <CPAGraphs contracts={contracts} typeLevels={typeLevels} />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <h6>{t(translationPath + ".contracts.title")}</h6>
                        <div className="contracts-filters">
                            <div className="contracts-filter">
                                <span className="filter-label">{t(translationPath + ".contracts.filters.group")}</span>
                                <CustomCombobox
                                    label=""
                                    onDropdownOpen={() => { }}
                                    options={groups}
                                    value={groups.find(g => g.id == filterGroup)}
                                    onChange={handleGroupChange}
                                    optionValue={(option) => option?.id || null}
                                    optionDisplay={(option) => getName(option, language)}
                                    fullWidth={true}
                                    isOpen={openCombobox === "group"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "group" : null)}
                                />
                            </div>
                            <div className="contracts-filter">
                                <span className="filter-label">{t(translationPath + ".contracts.filters.status")}</span>
                                <CustomCombobox
                                    label=""
                                    onDropdownOpen={() => { }}
                                    options={statuses}
                                    value={statuses.find(s => s.value == filterStatus)}
                                    onChange={handleStatusChange}
                                    optionValue={(option) => option?.value || null}
                                    optionDisplay={(option) => option?.display || ""}
                                    fullWidth={true}
                                    isOpen={openCombobox === "status"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "status" : null)}
                                />
                            </div>
                        </div>
                        <div className="contracts-applied-filters">
                            {appliedFilters.map((filter, index) => (
                                <div key={index} className="applied-filter">
                                    {filter.display}
                                    <span className="filter-remove" onClick={() => removeFilter(filter)}>
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                        <ContractTable contracts={contractList} groups={groups} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Analytics;