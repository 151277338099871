import moment from "moment";
import { LanguageType } from "../contexts/TranslationProvider";
import { BeneficialCompanyEntity, BeneficialEntity, BeneficialPersonEntity } from "../domain/entities";
import { getCountryByA3 } from "../utils/country";
import { getCurrencyByCode } from "../utils/currency";
import { getDeepValue } from "../utils/object";


export const virtualbeneficialGetters = {
  honorific(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    switch (language) {
      case 'fr':
        return beneficial.gender == 'F' ? "Mme." : "M."
      case 'en':
        return beneficial.gender == 'F' ? "Ms." : "Mr."
      case 'ar':
        return beneficial.gender == 'F' ? "السيدة" : "السيد"
    }
  },
  nationality(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const country = getCountryByA3(beneficial.nationality || 'FRA')
    return country[`demonym_${language}`] || country.demonym_en
  },
  maritalStatus(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    if (beneficial?.maritalStatus == 'Other')
      return ""
    return t(`pages.editionContract.popups.benificial.options.maritalStatusOptions.${beneficial?.maritalStatus}`)
  },
  socialCapitalCurrency(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    if (beneficial.type == 'Company') {
      const currency = getCurrencyByCode(beneficial.socialCapitalCurrency || 'EUR')
      return currency.specialCharacter ?? currency.code
    }
    return ""
  },
}

function fillBeneficialData(template: string, beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
  const regex = /{(.*?)}/g;
  return template.replace(regex, (_, match) => {
    const key = match.trim() as keyof BeneficialCompanyEntity | keyof BeneficialPersonEntity
    let value: any
    if (key in virtualbeneficialGetters) {
      value = virtualbeneficialGetters[key](beneficial, t, language)
    } else {
      value = getDeepValue(beneficial, key) || ''
    }
    if (
      value &&
      (key == 'cinDeliveryDate'
        || key == 'dateOfBirth'
        || key == 'createdAt'
        || key == 'updatedAt')
    ) {
      value = moment(new Date(value)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY")
    }
    return value
  })
}
export function getBeneficialDescritption(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
  const descriptionTemplate = beneficial.type == 'Company' ? t("beneficial.description.Company") : t("beneficial.description.Person")
  const description = fillBeneficialData(descriptionTemplate, beneficial, t, language)
  return description

}