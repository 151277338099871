
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ClauseEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import apiClientWithLoading from "../../../services/api/ApiClient";
import CustomCombobox from "../../common/CustomCombobox";
import Loading from "../../common/Loading";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { getName } from "../../../helpers/Translation";

interface ComparableClauseSelectorProps {
  modalComparableRef?: any;
  setComparableClauses?: (v: ClauseEntity[][]) => void;
  clauseName?: string;
}
function ComparableClauseSelector({
  modalComparableRef,
  setComparableClauses,
  clauseName
}: ComparableClauseSelectorProps) {
  const { t, language } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);


  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [nameLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = apiClientWithLoading(setLoading);
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);

  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => getName(item, language) === value);

    if (selectedLevel1) {

      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", t("modals.clauseComparaison.selectors.fields.level2"));
      setValue("Type3", t("modals.clauseComparaison.selectors.fields.level3"));
    }
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => getName(item, language) === value);
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: Array.isArray(selectedLevel2.levels3) && selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", t("modals.clauseComparaison.selectors.fields.level3"));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => getName(item, language) === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalComparableRef.current.scrollTo({
        top: modalComparableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async () => {
    setLoading(true);
    console.log(levelsFilter)
    const contractsTemplates = await contractTemplateClient.getAllByFilter(levelsFilter, 'clauses');
    setComparableClauses(contractsTemplates.rows.map(row => row.clauses))
  };

  return (
    <>
      {loading ? (
        <Loading height="35vh" />
      ) : (<>
        {clauseName && (
          <form action="" style={{width:"70%"}} className="form-group " onSubmit={handleSubmit(onSubmit)}>
            <p className="fw-bolder">{t("modals.clauseComparaison.selectors.fields.type")}</p>
            <div className="form-input-content">
              <div style={{ width: '100%' }}>
                <div style={{ paddingLeft: '-30px' }}>
                  <Controller
                    name="Type1"
                    control={control}
                    defaultValue={t("modals.clauseComparaison.selectors.fields.level1")}
                    rules={{
                      required: true,
                      validate: (value) => value !== t("modals.clauseComparaison.selectors.fields.level1"),
                    }}
                    render={({ field }) => (
                      <CustomCombobox
                        label={t("modals.clauseComparaison.selectors.fields.level1")}
                        options={options.level1.map((item) => getName(item, language))}
                        value={field.value}
                        fullWidth={true}
                        onDropdownOpen={handleComboboxClick}
                        onChange={(value) => {
                          handleLevel1Change(value);
                          field.onChange(value);
                        }}
                        isOpen={openCombobox === "level1"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                      />
                    )}
                  />
                </div>
                {errors.Type1 && <p className="text-danger py-0 my-0">{t("modals.clauseComparaison.selectors.fields.error")}</p>}
                {nameLevels.level2 && (
                  <div style={{ paddingLeft: '-30px' }}>
                    <Controller
                      name="Type2"
                      control={control}
                      defaultValue={t("modals.clauseComparaison.selectors.fields.level2")}
                      rules={{
                        required: true,
                        validate: (value) => value !== t("modals.clauseComparaison.selectors.fields.level2"),
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          label={t("modals.clauseComparaison.selectors.fields.level2")}
                          onDropdownOpen={handleComboboxClick}
                          options={options.level2.map((item) => getName(item, language))}
                          value={field.value}
                          fullWidth={true}
                          onChange={(value) => {
                            handleLevel2Change(value);
                            field.onChange(value);
                          }}
                          isOpen={openCombobox === "level2"}
                          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                        />
                      )}
                    />
                  </div>
                )}
                {options.level2.length > 0 && errors.Type2 && <p className="text-danger py-0 my-0">{t("modals.clauseComparaison.selectors.fields.error")}</p>}
                {nameLevels.level3 && (
                  <div style={{ paddingLeft: '-30px' }}>
                    <Controller
                      name="Type3"
                      control={control}
                      defaultValue={t("modals.clauseComparaison.selectors.fields.level3")}
                      rules={{
                        required: true,
                        validate: (value) => value !== t("modals.clauseComparaison.selectors.fields.level3"),
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          label={t("modals.clauseComparaison.selectors.fields.level3")}
                          onDropdownOpen={handleComboboxClick}
                          options={options.level3.map((item) => getName(item, language))}
                          value={field.value}
                          fullWidth={true}
                          onChange={(value) => {
                            handleLevel3Change(value);
                            field.onChange(value)
                          }}
                          isOpen={openCombobox === "level3"}
                          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                        />
                      )}
                    />
                  </div>
                )}
                {options.level3.length > 0 && errors.Type3 && <p className="text-danger py-0 my-0">{t("modals.clauseComparaison.selectors.fields.error")}</p>}
              </div>
            </div>
            <div className="">
              <button type="submit" className="confirm-comparison-button">
                {t("modals.clauseComparaison.selectors.submit")}
              </button>
            </div>
          </form>
        )}</>)}
    </>
  );
}

export default ComparableClauseSelector;
