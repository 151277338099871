import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Loading from "../../common/Loading";
import { toast } from "react-toastify";
import "./addGroup.scss";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { GroupClient } from "../../../services/api/GroupClient";
import { GroupEntity, Group_UserEntity, UserEntity } from "../../../domain/entities";
import InputField from "../../common/newDesign/InputField";
import { set } from "react-hook-form";
import { error } from "console";

interface AddGroupProps {
  onClose: (refresh?) => void;
  groups: GroupEntity[];
}

function AddGroup({ onClose, groups }: AddGroupProps) {
  const { t, language } = useTranslation();
  const translationPath = 'modals.addGroup.';
  const [loading, setLoading] = useState(false);
  const apiClient = apiClientWithLoading()
  const groupClient = new GroupClient(apiClient);
  const [newTranslations, setNewTranslations] = useState({name: "", language: ""});
  const [addLanguage, setAddLanguage] = useState(false);
  const languageOptions = [
    { value: "en", label: "English" },
    { value: "ar", label: "Arabic" },
    { value: "fr", label: "French"}
  ]

  const [formData, setFormData] = useState({
    code: "",
    name: "",
    translations: [],
  });

  const UnusedLanguages = useMemo(() => {
    return languageOptions.filter((option) => 
      !formData.translations.find((translation) => translation.language === option.value)
    );
  }, [formData.translations]);


  const [errors, setErrors] = useState({
    code: [],
    name: [],
    translations: [],
  });

  const handleNameChange = (name: string) => {
    const validation = validateName(name);
    setFormData({ ...formData, name: name });
    if (validation) {
      setErrors({ ...errors, name: [validation] });
    } else {
      setErrors({ ...errors, name: [] });
    }
  };

  const handleCodeChange = (code: string) => {
    const validation = validateCode(code);
    setFormData({ ...formData, code: code });
    if (validation) {
      setErrors({ ...errors, code: [validation] });
    } else {
      setErrors({ ...errors, code: [] });
    }
  }

  const validateCode = (code: string) => {
    if (code.length === 0) {
      return t(translationPath + 'codeRequired');
    }
    if (groups.find((group) => group.code === code)) {
      return t(translationPath + 'codeExists');
    }
    return "";
  }

  const validateName = (name: string) => {
    if (name.length === 0) {
      return t(translationPath + 'nameRequired');
    }
    return "";
  }

  const handleSubmit = async () => {
    handleCodeChange(formData.code)
    handleNameChange(formData.name)
    const newGroup: GroupEntity = {
      name: formData.name,
      code: formData.code,
      translations: formData.translations
    }
    try{
      const newGrp = await groupClient.createWithTranslation(newGroup);
      toast.success("Group added successfully");
      onClose(true);
      setFormData({
        code: "",
        name: "",
        translations: [],
      });
    }catch(err){
      console.log(err)
      toast.error("Error adding group");
    }
  }

  const handleAddLanguage = () => {
    if (newTranslations.name.length === 0) {
      return;
    }
    setFormData({
      ...formData,
      translations: [
        ...formData.translations,
        {
          name: newTranslations.name,
          language: newTranslations.language ? newTranslations.language : UnusedLanguages[0]?.value
        }]
    });
    setNewTranslations({ name: "", language: "" });
    setAddLanguage(false);
  }

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="type-add-popup" className="type-add-popup">
        <div className="modal modal-add-type d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t(translationPath + 'title')}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "75%" }}
                ></button>
              </div>
              <div className="group-modal-body">
                <div className='group-form'>
                  <InputField
                    value={formData.name}
                    onChange={(e)=>handleNameChange(e.target.value)}
                    placeholder={t(translationPath + 'name')}
                    type='text'
                    label={t(translationPath + 'name')}
                    name='name'
                    error={errors.name}
                    required
                  />

                  <InputField
                    value={formData.code}
                    onChange={(e)=>handleCodeChange(e.target.value)}
                    placeholder={t(translationPath + 'code')}
                    type='text'
                    label={t(translationPath + 'code')}
                    name='code'
                    error={errors.code}
                    required
                  />

                  <div className="languages">
                    {t(translationPath + 'languages')}
                    {
                      formData.translations.length > 0 && (
                        <div className="language-header" key={-1}>
                        <div>{t(translationPath + "name")}</div>
                        <div>{t(translationPath + "language")}</div>
                      </div>
                      )
                    }
                    {formData.translations.map((translation, index) => (
                      <div className="language" key={index}>
                        <div>{translation.name}</div>
                        <div>{translation.language}</div>
                      </div>
                    ))}
                  </div>

                  {
                    addLanguage ? (
                      <div className="language-add-form">
                  <div>
                    <input 
                      type="text"
                      placeholder="Name"
                      value={newTranslations.name}
                      className="language-input"
                      onChange={(e) => {
                        setNewTranslations({ ...newTranslations, name: e.target.value });
                      }}
                    />
                  </div>
                  <select
                  style={{ height: "30px", width: "30%" }}
                  className="language-select"
                  value={newTranslations.language}
                  onChange={(e) => {
                    setNewTranslations({ ...newTranslations, language: e.target.value });
                  }}
                >
                  {
                    UnusedLanguages.map((language) => (
                      <option key={language.value} value={language.value}>
                        {language.label}
                      </option>
                    ))
                  }
                </select>
                <button
                  className="add-language"
                  onClick={() => handleAddLanguage()}
                >
                  {t(translationPath + "add")}
                  </button>
                  <button
                  className="add-language"
                  onClick={() => setAddLanguage(false)}
                >
                  {t(translationPath + "cancel")}
                  </button>
                  </div>
                    ) : (
                      <button
                      disabled={UnusedLanguages.length === 0}
                      className={`add-language ${UnusedLanguages.length === 0 && "disabled"}`}
                      onClick={() => setAddLanguage(true)}>
                        {t(translationPath + "addLanguage")}
                      </button>
                    )
                  }

                  <div className="footer">
                    <button
                    disabled={errors.code.length !== 0 || errors.name.length !== 0 || formData.code.length === 0 || formData.name.length === 0 || loading}
                    className={`create-account ${(errors.code.length !== 0 || errors.name.length !== 0 || formData.code.length === 0 || formData.name.length === 0 || loading) && "disabled"}`}
                    onClick={handleSubmit}
                  >
                    {t(translationPath + 'create')}
                  </button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddGroup;
