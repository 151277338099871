import React, { useState, useEffect } from 'react';
import './generateAIportal.scss';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { toast } from 'react-toastify';
import { getName } from '../../../helpers/Translation';

const GroupsClause = ({ clauseGroup, groups, close, onSelectGroup, clauseIndex }) => {
  const [groupsSelected, setGroupsSelected] = useState([]);
  const { t, language } = useTranslation();
  const translationPath = "pages.contractTemplateParam.addGroups";

  const handleChange = (e, groupSelected, clauseIndex) => {
    if (e.target.checked) {
      let group = groupsSelected.find(g => g.id === groupSelected.id);
      if (group) {
        if (!group.Group_ContractTemplate.clauseCodes.includes(clauseIndex)) {
          group.Group_ContractTemplate.clauseCodes.push(clauseIndex);
        }
        setGroupsSelected(prevGroups => [...prevGroups]);
      } else {
        setGroupsSelected(prevGroups => [
          ...prevGroups,
          {
            ...groupSelected,
            Group_ContractTemplate: {
              clauseCodes: [clauseIndex]
            }
          }
        ]);
      }
    } else {
      setGroupsSelected(prevGroups =>
        prevGroups.filter(group => group.id !== groupSelected.id)
      );
    }
  };
  
  const handleSelectGroup = async () => {
    try {
      await onSelectGroup(clauseIndex.toString(), groupsSelected);
            toast.success(t(translationPath + ".toasts.success"));
      close()
    } catch (error) {
      console.error("Error selecting groups:", error);
      toast.error(t(translationPath + ".toasts.error")); 
      close()
    }
  }

  useEffect(() => {
    if (clauseGroup) {
      const isConditionMet = clauseGroup.some(group =>
        group.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
      );
      if (isConditionMet) {
        const newSelectedGroup = clauseGroup.filter(group =>
          group.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
        );
        setGroupsSelected(newSelectedGroup);
      } else {
        setGroupsSelected([]);
      }
    } else {
      setGroupsSelected([]);
    }
  }, [clauseIndex]);

  return (
    <div>
      <div className="generate-ai-portal">
        <div className="generate-ai-portal-header">
          <span className='close-dialog' onClick={() => close()}>X</span>
        </div>
        <div className="generate-ai-portal-content">
          {groups.map((group, index) => {
            const isChecked = groupsSelected.some(selectedGroup =>
              selectedGroup.id === group.id &&
              selectedGroup.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
            );
            return (
              <div key={index} className="generate-ai-portal-content-checkbox">
                <input
                  type="checkbox"
                  id={group.id}
                  name={group.name}
                  checked={isChecked}
                  onChange={(e) => handleChange(e, group, clauseIndex)}
                />
                <label className='generate-ai-portal-label' htmlFor={group.id}>{getName(group, language)}</label>
              </div>
            );
          })}
        </div>
        <div className='generate-ai-portal-footer'>
          <button className='generate-ai-portal-button add-groups' onClick={handleSelectGroup}>
            {t(translationPath + ".addGroups")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GroupsClause;
