import React, { useState, useRef, useContext, useEffect } from "react";
import { ClauseEntity } from "../../../domain/entities";
import "./SubClauseSelect.scss"
import ComparisonClause from "./ComparisonClause";
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import EventManager from "../../../services/EventManager";

function ClauseSelectModal({
  onClose,
  addNewClause,
  clauseId
}: {
  onClose: () => void;
  addNewClause: any;
  clauseId: number;
}) {
  const modalComparableRef = useRef(null);
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [comparableClauses, setComparableClauses] = useState<ClauseEntity[][]>([]);
  const { t, language } = useTranslation()

  const translationPath = t("modals.selectFromExisting")

  const [currentClause, setCurrentClause] = useState(null)
  const [searchClause, setSearchClause] = useState("")
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([])
  const [loading, setLoading] = useState(false)
  const lookup = require("../../../assets/lookup.png")
  const { insertSubClauseWithContent } = useContext(EditContractTemplateContext);
  const apiClient = apiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const searchRef = useRef(null);
  const handleInputChange = (event) => {
    setSearchClause(event.target.value)
    setShowDropdown(true)
  }
  const handleDropdownSelect = (item) => {
    setCurrentClause(item)
    setSearchClause(item.name)
    setFilteredClauses([])
    setShowDropdown(false)
  }
  useEffect(() => {
    if (!searchClause) return;
    const searchSubClauses = async (name: string) => {
      try {
        setLoading(true)
        const result = await contractTemplateClient.searchSubClauses(searchClause);
        const { rows: clauses } = result;
        setFilteredClauses(clauses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error searching clauses:', error);
        toast.error("Error searching clauses");
      }
    };
    searchSubClauses(searchClause)
  }, [searchClause])

  const handleConfirm = async () => {
    try {
      insertSubClauseWithContent(addNewClause, clauseId, currentClause)
      onClose()
    } catch (err) {
      console.error('Error inserting clause:', err);
      toast.error("Error inserting clause");
    }
  }

  // handle click outside of searchRef
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [searchRef])

  return (
    <>
      <>
        <div className="modal-backdrop subClause-select"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{translationPath.title}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "90%" }}
                ></button>
              </div>
              <div className="body-comparison">
                <div className="clause-comparison-choice" ref={modalComparableRef} style={{ position: 'relative' }}>
                  <div ref={searchRef} className='project-beneficial-name'>
                    <input
                      type="text"
                      className=""
                      value={searchClause}
                      onChange={(event) => handleInputChange(event)}
                      style={{ width: "80vw" }}
                    />
                    <img src={lookup} alt="#" className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />
                    {
                      currentClause && (
                        <span onClick={() => {
                          setCurrentClause(null)
                          setSearchClause("")
                          setShowDropdown(false)
                        }} className={`x-img ${language == "ar" && "x-img-arabic"}`}>X</span>
                      )
                    }

                    {showDropdown && (
                      <div className="list-beneficial-project">
                        {filteredClauses && filteredClauses?.length > 0 ? filteredClauses?.map((item, index) => (
                          <div className="item-beneficial-project" key={index} onClick={() => handleDropdownSelect(item)}>
                            <span>{item.name}</span>
                            <span className="item-template-name">
                              {item.clause?.template ? item.clause.template.name: ""}
                              <span className="item-template-name-seperator">{' > '}</span>
                              {item.clause?.name}
                            </span>
                            {/*item?.profilePhoto && <img className='beneficial-project-img' src={item?.profilePhoto?.url} alt='photo' />*/}
                          </div>
                        )) : (
                          <></>

                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="clause-comparison-container">
                  {
                    currentClause&&!showDropdown ? (
                      <ComparisonClause
                        clause={currentClause}
                      />
                    ) : <span>{translationPath.noSelection}</span>
                  }
                </div>
              </div>
              <button
                disabled={!currentClause}
                className={`confirm-button ${!currentClause && "disabled-confirm-button"}`}
                onClick={handleConfirm}
              >
                {translationPath.btn}
              </button>
            </div>

          </div>
        </div>
      </>
    </>
  );
}

export default ClauseSelectModal;
