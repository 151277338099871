import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../contexts/TranslationProvider';
import "./project.scss"
import { formatDate } from '../../helpers/helper';
import { useNavigate } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import apiClientWithLoading from '../../services/api/ApiClient';
import { FiMoreHorizontal } from 'react-icons/fi';
import ContractCardActionsModal from '../../components/modals/ContractCardActionsModal';
import { ProjectClient } from '../../services/api/ProjectClient';
import { ContractClient } from '../../services/api/ContractClient';
import { ContractAIClient } from '../../services/api/ContractAIClient';
import { DocumentClient } from '../../services/api/DocumentClient';
import { FormClient } from '../../services/api/FormClient';
import { getName } from '../../helpers/Translation';
const ProjectDocument = ({ documentReq, ressource, typeRessource, project, onUpdateProject, user }) => {
    const { t, language } = useTranslation();
    const navigate = useNavigate()
    const handleClick = (type, ressourceId) => {
        switch (type) {
            case 'FORM':
                return navigate(`/edit-form/${ressourceId}`, {
                    state: {
                        previousPathname: [
                            { link: '/projets', label: t('sidebar.myProjects') },
                            { link: `/projet/${project?.id}`, label: project?.name }
                        ]
                    }
                })
            case 'CONTRACT':
                return navigate(`/edition-contrat/${ressourceId}`, {
                    state: {
                        previousPathname: [
                            { link: '/projets', label: t('sidebar.myProjects') },
                            { link: `/projet/${project?.id}`, label: project?.name }
                        ]
                    }
                });
            case 'CONTRACTAI':
                return navigate(`/document-summary/${ressourceId}`, {
                    state: {
                        previousPathname: [
                            { link: '/projets', label: t('sidebar.myProjects') },
                            { link: `/projet/${project?.id}`, label: project?.name }
                        ],
                    }
                })
            case 'DOCUMENT':
                return navigate(`/document/${ressourceId}`, {
                    state: {
                        previousPathname: [
                            { link: '/projets', label: t('sidebar.myProjects') },
                            { link: `/projet/${project?.id}`, label: project?.name }
                        ]
                    }
                })
        }
    };
    const apiClient = apiClientWithLoading();
    const projectClient = new ProjectClient(apiClient)
    const contractClient = new ContractClient(apiClient)
    const contractAIClient = new ContractAIClient(apiClient)
    const documentClient = new DocumentClient(apiClient)
    const formClient = new FormClient(apiClient)
    const handleDeleteDocument = async (id, type) => {
        const valueKey = Object.keys(project?.values).find(key => project?.values[key]?.id === id);
        if (valueKey) {
            const updatedValues = { ...project?.values };
            delete updatedValues[valueKey];
            const updatedProject = await projectClient.update(project?.id, {
                values: updatedValues,
                excludedRequirements: []
            });
            onUpdateProject(id);
            switch (type) {
                case 'FORM':
                    await formClient.delete(id);
                    break;
                case 'CONTRACT':
                    await contractClient.delete(id);
                    break;
                case 'CONTRACTAI':
                    await contractAIClient.delete(id);
                    break;
                case 'DOCUMENT':
                    await documentClient.delete(id)
                    break;
            }
        } else {
            console.log(`Value with id ${id} not found in the project's values.`);
        }
    }
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);
    const cardRef = useRef(null);
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const handleCardClick = () => {
        if (currentCard !== cardRef.current) {
            setIsPopupOpen(false);
            setCurrentCard(cardRef.current);
        }
    };

    const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            setIsPopupOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setCurrentCard(cardRef.current);
    }, [isPopupOpen]);
    return (
        <div
            className="card-document px-3"
            ref={cardRef}
        >
            {ressource ? (
                <div className='container-content' onClick={handleCardClick}>
                    <div className='content pt-2'>
                        {documentReq?.level1?.name && <span className='level-types'>
                            {getName(documentReq?.level1, language) +
                                (documentReq?.level2 ? "/" + getName(documentReq.level2, language) : "") +
                                (documentReq?.level3 ? "/" + getName(documentReq.level3, language) : "")}
                        </span>}
                        <div className="d-flex justify-content-between w-80">
                            <h3 className='document-name'>{ressource?.name}</h3>
                            <div style={{ position: "relative" }}>
                                <button className="btn btn-light rounded-circle" onClick={() => togglePopup()}>
                                    <FiMoreHorizontal size={20} />
                                </button>
                                {isPopupOpen && <ContractCardActionsModal onView={() => handleClick(typeRessource, ressource?.id)} onDelete={() => handleDeleteDocument(ressource?.id, typeRessource)} />}
                            </div>
                        </div>
                        <span className='document-type'>{typeRessource}</span>
                        <p className="document-date"><span>{t("pages.viewProject.document.creationDate")} :</span> <span> {ressource && formatDate(new Date(ressource?.createdAt).toISOString())}</span></p>
                        {user?.display && <p className="document-creator"><span>{t("pages.viewProject.document.creator")} :</span> <span> {user?.display}</span></p>}
                        {ressource?.status && <h3 className="document-status"><span>{t("pages.viewProject.document.status")} :</span><span>{t(`status.${ressource?.status}`) ?? ""}</span></h3>}
                        <div className='actions-document'>
                            <AiFillDelete
                                style={{
                                    marginTop: "10px",
                                    marginRight: language=="ar"? "0" :"0.5rem",
                                    marginLeft:language=="ar"&&"0.5rem",
                                    color: "#e5142f",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleDeleteDocument(ressource?.id, typeRessource)}
                            />
                            <button className='see-document' onClick={() => handleClick(typeRessource, ressource?.id)}>{t("pages.viewProject.document.viewDocument")}</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='content pt-2'>
                    {documentReq?.level1?.name && <span className='level-types'>
                        {getName(documentReq?.level1, language) +
                            (documentReq?.level2 ? "/" + getName(documentReq.level2, language) : "") +
                            (documentReq?.level3 ? "/" + getName(documentReq.level3, language) : "")}
                    </span>}
                    <h3 className='document-name'>{getName(documentReq, language)}</h3>
                    <button className='create-document' onClick={() => navigate(`/createDocumentRequirement/${documentReq?.id}`, {
                        state: {
                            req: documentReq,
                            project: project,
                            previousPathname: [
                                { link: '/projets', label: t('sidebar.myProjects') },
                                { link: `/projet/${project?.id}`, label: project?.name }
                            ]
                        }
                    })}>{t("pages.viewProject.document.createDocument")}</button>
                </div>
            )}
        </div>
    );
};

export default ProjectDocument;
