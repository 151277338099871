//this function is used to calculate the completionPercentage of VariableInput
// updated so it does not concider calculating the checkboxes as a false and true are considered inputs

import { ContractEntity, FormEntity, FormTemplatePageEntity } from "../domain/entities";
import { ParsedClauseParam } from "./ParseTemplateData";

export function isValidInputValue(value: any) {
  if (value === null || value === undefined || value === "")
    return false
  return true
}
export function calculateFormCompletionPercentage(
  paramValues: FormEntity['paramValues'],
  params: FormTemplatePageEntity['params'],
) {
  let filledVariablesCount = 0;
  const filteredParams = params.filter(
    (param) =>
      param.type !== "list" &&
      param.type !== "boolean"
  );
  let totalVariablesCount = filteredParams.length;
  filteredParams.forEach((param) => {
    let value = paramValues[param.name]
    if (isValidInputValue(value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}
export function calculateCompletionPercentage(
  inputValues: ContractEntity['paramValues'],
  combinedParams: ParsedClauseParam[],
  excludedClauses: ContractEntity['excludedClauses'],
  excludedSubClauses: ContractEntity['excludedClauses'],
) {
  let filledVariablesCount = 0;

  const filteredCombinedParams = combinedParams.filter(
    (param) =>
      param.type !== "list" &&
      param.type !== "boolean" &&
      !excludedClauses.includes(param.clauseId) &&
      !(param.subClauseId && excludedSubClauses.includes(param.subClauseId))
  );

  let totalVariablesCount = filteredCombinedParams.length;

  filteredCombinedParams.forEach((param) => {
    const value = inputValues[param.name]
    if (isValidInputValue(value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}
