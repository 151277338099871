import { text } from "stream/consumers";
import { ContractTemplateEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "./entities";
import { ClauseParam, EnumParam, SegmentedBooleanParam, SegmentedClauseParam, SegmentedEnumParam, SegmentedText, SegmentedTextType } from "./types/ClauseParams";

var counter = 0
export const genIdFactory = (code: string) => {
	counter = 0
	return () => `${code}-${++counter}`
}

export function fixTemplateIndexation(contractTemplate: ContractTemplateEntity): ContractTemplateEntity {
	/*
	clauseCodes should store the index of the clause instead if code
	*/
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.index == cc)?.code))
	})
	contractTemplate.clauses.forEach((clause, index) => {
		clause.index = (index + 1).toString()
		clause.segmentation = SegmentTextAndParams(clause.rawText[0], clause.params, genIdFactory((index + 1).toString()))
		return clause
	})
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.code == cc)?.index))
	})
	return { ...contractTemplate }
}

export function SegmentText(text: string, genId: () => string, params: ClauseParam[]): SegmentedText {
	const splits = text.split(/(\$[\w]+\.[\w]+)|(\$[\w]+)|(#{[^#]+})/g).filter(str => str);
	let segmentedText = [] as SegmentedText

	for (const split of splits) {
		if (split.match(/\$[\w\.]+/g,)) {
			const param = params.find(param => param.name === split.slice(1).split('.')[0])
			if (param) {
				segmentedText.push([genId(), split.slice(1), SegmentedTextType.PARAM])
			} else {
				segmentedText.push([genId(), split.slice(1), SegmentedTextType.STATIC])
			}
		} else if (split.match(/#{[^\]]+}/g,)) {
			segmentedText.push([genId(), split.slice(2, -1), SegmentedTextType.COMMENT])
		} else {
			segmentedText.push([genId(), split, SegmentedTextType.STATIC])
		}
	}
	return segmentedText
}

/**
 * CompileSegmentedText
 */
export function compileSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): string {
	return segmentedText.map(([id, value, type], index) => {
		const nextSegment = segmentedText[index + 1]
		switch (type) {
			case SegmentedTextType.PARAM:
				const [paramName] = value.split('.')
				const param = segmentedParams.find(param => param.name === paramName)
				// if the param is not found then return the value as is
				if (!param) {
					return value
				}
				// in case the following segment is a text and not starting with space
				if ((nextSegment && nextSegment[2] === SegmentedTextType.PARAM) || (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" "))) {
					return `\$${value} `
				}
				return `\$${value}`;
			case SegmentedTextType.COMMENT:
				if (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" ")) {
					return `#{${value}} `
				}
				return `#{${value}}`;
			case SegmentedTextType.STATIC:
				return value;
			default:
				throw new Error(`Unsupported SegmentedTextType: ${type}`);
		}
	}).join('');
}

export function removeNotFoundParams(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.filter(param => {
			switch (param.type) {
				case 'boolean':
					return removeNotFoundParams(param.args?.textIfFalse, []).segmentedText.length
						&& removeNotFoundParams(param.args?.textIfTrue, []).segmentedText.length
				case 'enum':
					return param.args?.filter(arg => removeNotFoundParams(arg.text, []).segmentedText.length).length
				default:
					return true
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return type !== SegmentedTextType.PARAM || segmentedParams.find(param => param.name === text.slice(1).split('.')[0])
	})
	return { segmentedText: newSegmentedtext, segmentedParams }
}

export function SegmentTextAndParams(text: string, params: ClauseParam[], genId: () => string): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	let segmentedText = SegmentText(text, genId, params)
	let segmentedParams = [] as SegmentedClauseParam[]
	for (const param of params) {
		switch (param.type) {
			case 'beneficial[]':
				segmentedParams.push({
					...param,
					args: {
						beneficialTypes: param.args.beneficialTypes,
						textPerBeneficial: SegmentText(param.args?.textPerBeneficial ?? "", genId, params)
					},
				})
				break;
			case 'boolean':
				segmentedParams.push({
					...param,
					args: {
						textIfFalse: SegmentText(param.args?.textIfFalse ?? "", genId, params),
						textIfTrue: SegmentText(param.args?.textIfTrue ?? "", genId, params)
					},
				})
				break;
			case 'enum':
				segmentedParams.push({
					...param,
					args: param.args?.map((arg => {
						return {
							option: arg.option,
							text: SegmentText(arg.text, genId, params)
						}
					})),
				})
				break;
			default:
				segmentedParams.push(param)
				break;
		}

	}
	return { segmentedText, segmentedParams }
}

/**
 * CompileSegmentedText
 */
export function compileSegmentationAndParams(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { text: string, params: ClauseParam[] } {
	const compiledText = compileSegmentation(segmentedText, segmentedParams);

	const compiledParams = segmentedParams.map(param => {
		switch (param.type) {
			case 'beneficial[]':
				return {
					...param,
					args: {
						beneficialTypes: param.args.beneficialTypes,
						textPerBeneficial: compileSegmentation(param.args?.textPerBeneficial ?? [], segmentedParams),
					},
				};
			case 'boolean':
				return {
					...param,
					args: {
						textIfFalse: compileSegmentation(param.args?.textIfFalse ?? [], segmentedParams),
						textIfTrue: compileSegmentation(param.args?.textIfTrue ?? [], segmentedParams),
					},
				};
			case 'enum':
				return {
					...param,
					args: param.args?.map(arg => {
						return {
							option: arg.option,
							text: compileSegmentation(arg.text, segmentedParams),
						};
					}),
				};
			default:
				return param;
		}
	});

	return { text: compiledText, params: compiledParams };
}

/**
 * search segment by id and change the text
 */
export function updateSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, text: string): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	const updatedSegmentedText: SegmentedText = segmentedText.map(([segmentId, segmentText, segmentType]) => {
		return segmentId === id ? [segmentId, text, segmentType] : [segmentId, segmentText, segmentType];
	});

	const updatedSegmentedParams = segmentedParams.map(param => {
		switch (param.type) {
			case 'beneficial[]':
				param.args.textPerBeneficial = updateSegment(param.args.textPerBeneficial, [], id, text).segmentedText;
				return param;
			case 'boolean':
				param.args.textIfFalse = updateSegment(param.args.textIfFalse, [], id, text).segmentedText;
				param.args.textIfTrue = updateSegment(param.args.textIfTrue, [], id, text).segmentedText;
				return param;

			case 'enum':
				param.args = param.args.map(arg => {
					return {
						option: arg.option,
						text: updateSegment(arg.text, [], id, text).segmentedText
					};
				})
				return param;
			default:
				return param;
		}
	});

	return { segmentedText: updatedSegmentedText, segmentedParams: updatedSegmentedParams };

}

/**
 * search insert a parameter inside a segment
 */

export const insertParamInSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, newParam: SegmentedClauseParam, definition: number, textBefore: string, textAfter: string, field: string): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	const generateNewId = (baseId: string, index: number): string => {
		const parts = baseId.split('-');
		parts[parts.length - 1] = index.toString();
		return parts.join('-');
	};
	const getIndexFromId = (id: string): number => {
		const parts = id.split('-');
		return parseInt(parts[parts.length - 1]);
	}

	const segmentToUpdateIndex = segmentedText.findIndex(([segmentId]) => segmentId === id);

	// Segment with given id not found in root segmented then text search in the params
	if (segmentToUpdateIndex === -1) {
		// insert the new param in the segmented params text
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: insertParamInSegment(param.args?.textIfFalse, [], id, newParam, definition, textBefore, textAfter, field).segmentedText,
							textIfTrue: insertParamInSegment(param.args?.textIfTrue, [], id, newParam, definition, textBefore, textAfter, field).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: insertParamInSegment(arg.text, [], id, newParam, definition, textBefore, textAfter, field).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
		switch (newParam.type) {
			case 'enum':
				const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
				segmentedParams.push({
					name: newParam.name,
					label: newParam.label,
					type: newParam.type,
					args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
						return {
							option: arg.option,
							text: SegmentText("[ ]", genIdFactory(arg.option), [])
						}
					}) : [],
					detected: true,
					definition: definition
				})
				break;
			case 'boolean':
				segmentedParams.push({
					name: newParam.name,
					label: newParam.label,
					type: newParam.type,
					args: {
						textIfTrue: SegmentText("[ ]", genIdFactory("textIfTrue"), []),
						textIfFalse: SegmentText("[ ]", genIdFactory("textIfFalse"), []),
					},
					detected: true,
					definition: definition
				})
				break;
			default:
				const exists = segmentedParams.find((par) => par.name == newParam.name)
				if (!exists)
					segmentedParams.push(newParam)
				break;
		}
		return { segmentedText, segmentedParams };
	}

	// Split the segment at the insertion index
	const [segmentId, segmentText, segmentType] = segmentedText[segmentToUpdateIndex];

	// manage the changed elements
	const updatedSegment = [segmentId, textBefore + " ", segmentType];
	let paramSegment: SegmentedText[number]
	switch (newParam.type) {
		case 'enum':
			paramSegment = [generateNewId(segmentId, getIndexFromId(segmentId) + 1), `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
					return {
						option: arg.option,
						text: SegmentText("[ ]", genIdFactory(arg.option), [])
					}
				}) : [],
				detected: true,
				definition: definition
			})
			break;
		case 'boolean':
			paramSegment = [generateNewId(segmentId, getIndexFromId(segmentId) + 1), `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: {
					textIfTrue: SegmentText("[ ]", genIdFactory("textIfTrue"), []),
					textIfFalse: SegmentText("[ ]", genIdFactory("textIfFalse"), []),
				},
				detected: true,
				definition: definition
			})
			break;
		case 'beneficial':
			paramSegment = [generateNewId(segmentId, getIndexFromId(segmentId) + 1), newParam.name + "." + field, SegmentedTextType.PARAM];
			const oldBeneficial = segmentedParams.find((par) => par.name == newParam.name)
			if (!oldBeneficial)
				segmentedParams.push(newParam)
			break;
		default:
			paramSegment = [generateNewId(segmentId, getIndexFromId(segmentId) + 1), newParam.name, SegmentedTextType.PARAM];
			const exists = segmentedParams.find((par) => par.name == newParam.name)
			if (!exists)
				segmentedParams.push(newParam)
			break;
	}
	const createdSegment = [generateNewId(segmentId, getIndexFromId(segmentId) + 2), " " + textAfter, segmentType];

	// Update the segmented text
	const updatedSegmentedText = [
		...segmentedText.slice(0, segmentToUpdateIndex),
		updatedSegment,
		paramSegment,
		createdSegment,
		...segmentedText.slice(segmentToUpdateIndex + 1).map(([segmentId, segmentText, segmentType]) => {
			return [generateNewId(segmentId, getIndexFromId(segmentId) + 2), segmentText, segmentType];
		}),
	];
	return { segmentedText: updatedSegmentedText, segmentedParams: segmentedParams };

}
export type ValidationWarning = {
	message: string;
	templateCode: string;
	clauseCode: string;
	subClauseCode: string;
	paramName: string;
}
export type GenerateTemplateFromDocumentRequest = {
	file: Blob & { name: string };
	name: string;
	isScanned: boolean;
	level1Id?: TypeLevel1Entity['id'];
	level2Id?: TypeLevel2Entity['id'];
	level3Id?: TypeLevel3Entity['id'];
}

export function DetectParamsInText(params: ClauseParam[], text: string) {
	for (const param of params!) {
		if (param.detected)
			continue
		if (param.name == "BilingMethod2")
			console.log();
		let found
		switch (param.type) {
			case 'beneficial[]':
				found = text.includes(`$${param.name}`)
				if (found) {
					param.detected = true
					DetectParamsInText(params, param.args.textPerBeneficial)
				}
				break;
			case 'boolean':
				found = text.includes(`$${param.name}.`)
				if (found) {
					param.detected = true
					DetectParamsInText(params, param.args.textIfFalse)
					DetectParamsInText(params, param.args.textIfTrue)
				}
				break;
			case 'enum':
				found = text.includes(`$${param.name}.`)
				if (found) {
					param.detected = true
					param.args.forEach(arg => DetectParamsInText(params, arg.text))
				}
				break;
			case 'beneficial':
				found = text.includes(`$${param.name}.`)
				if (found) {
					param.detected = true
				}
				break;
			default:
				found = text.includes(`$${param.name}`)
				if (found) {
					param.detected = true
				}
				break;
		}
	}

}
export function ValidateContractTemplate(data: ContractTemplateEntity): ValidationWarning[] {
	let warnings: ValidationWarning[] = []
	data.clauses?.forEach(clause => {
		clause.params?.forEach(param => {
			param.detected = false
		});
		clause.subClauses?.forEach(subClause => {
			subClause.params?.forEach(param => {
				param.detected = false
			});
		})
	})
	data.clauses?.forEach(clause => {
		if (clause.rawText?.[0] && clause.params?.length) {
			DetectParamsInText(clause.params, clause.rawText?.[0])
		}
		clause.subClauses?.forEach(subClause => {
			if (subClause.rawText?.[0])
				DetectParamsInText([...(clause.params ?? []), ...(subClause.params ?? [])], subClause.rawText?.[0])
			for (const param of subClause.params ?? []) {
				if (!param.detected) {
					warnings.push({
						message: "Undetected Param",
						templateCode: data.code!,
						clauseCode: clause.code!,
						subClauseCode: subClause.code!,
						paramName: param.name
					})
				}
			}
		})
	})
	return warnings
}

export function deleteParamInSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], parameter: ClauseParam) {
	if (segmentedParams.length) {
		const filterSegParams = (p: SegmentedClauseParam) => parameter.name !== p.name;
		segmentedParams = segmentedParams.filter(filterSegParams);
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteParamInSegment(param.args?.textIfFalse, [], parameter).segmentedText,
							textIfTrue: deleteParamInSegment(param.args?.textIfTrue, [], parameter).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteParamInSegment(arg.text, [], parameter).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [id, text, type] = e
		if (parameter.type === "enum" || parameter.type === 'boolean') {
			// remove all the segments that contain the parameter name
			return type !== SegmentedTextType.PARAM || !text.includes(`${parameter.name}.`)
		}
		// default case
		return type !== SegmentedTextType.PARAM || text !== parameter.name
	})
	// handle beneficial case
	const cleanedSegmentedText = newSegmentedtext.map((segment) => {
		const [id, text, type] = segment
		if (type === SegmentedTextType.PARAM && text.includes(`${parameter.name}.`)) {
			const newType = SegmentedTextType.STATIC
			const newText = "(" + text.replace(`${parameter.name}.`, "") + ")"
			segment = [id, newText, newType]
		}
		return segment
	})

	return { segmentedText: cleanedSegmentedText, segmentedParams }
}

export function cleanSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: cleanSegmentation(param.args?.textIfFalse, []).segmentedText,
							textIfTrue: cleanSegmentation(param.args?.textIfTrue, []).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: cleanSegmentation(arg.text, []).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	// if first or last segments are not static add static segments
	if (segmentedText.length) {
		const firstSegment = segmentedText[0]
		const lastSegment = segmentedText[segmentedText.length - 1]
		if (firstSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.unshift(["id-first", " ", SegmentedTextType.STATIC])
		}
		if (lastSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
		}
	}
	// if two consecutive segments are params then add a static segment between them
	const newSegmentedText = [] as SegmentedText
	for (let i = 0; i < segmentedText.length; i++) {
		const currentSegment = segmentedText[i]
		const nextSegment = segmentedText[i + 1]
		newSegmentedText.push(currentSegment)
		if (currentSegment[2] === SegmentedTextType.PARAM && nextSegment && nextSegment[2] === SegmentedTextType.PARAM) {
			newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
		}
	}
	return { segmentedText: newSegmentedText, segmentedParams }
}

export const deleteSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string) => {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteSegment(param.args?.textIfFalse, [], id).segmentedText,
							textIfTrue: deleteSegment(param.args?.textIfTrue, [], id).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteSegment(arg.text, [], id).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return segmentId !== id
	})
	return { segmentedText: newSegmentedtext, segmentedParams }
}