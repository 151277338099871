import React, { useContext, useEffect, useState } from "react";
import EditContractTemplateContext from "../../../contexts/EditFormTemplateContext";
import { MdOutlineAddBox } from "react-icons/md";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { FiEdit2 } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import "./editFormTemplateSidebar.scss";
import { FormTemplateEntity, FormTemplatePageEntity } from "../../../domain/entities";
const {
  default: EditFormPageIcon,
} = require("../../../assets/edit_form_page.svg");
const {
  default: EditFormPageOnIcon,
} = require("../../../assets/edit_form_page_on.svg");
const { default: VLineIcon } = require("../../../assets/v_line.svg");
const { default: VLineOnIcon } = require("../../../assets/v_line_on.svg");

function EditFormTemplateSidebar({ currentIndexPage, setIndexCurrentPage }) {
  const { formTemplate, addPage, onPageRename, onPageDelete } = useContext(
    EditContractTemplateContext
  );
  const [newPageName, setNewPageName] = useState("");
  const handlePageNameChange = (e) => {
    setNewPageName(e.target.value);
  };
  const [reload, setReload] = useState(false);
  // setShowAdd of type nnumber | null
  const [showAdd, setShowAdd] = useState(null);
  const [showEdit, setShowEdit] = useState(null);
  const editionTemplateTranslation = "pages.editionFormTemplate";
  const { t, language, setLanguage } = useTranslation();
  const handleAddPage = (index: FormTemplatePageEntity['index']) => {
    addPage(index, newPageName);
    setShowAdd(-1);
    setNewPageName("");
  };
  useEffect(()=>{
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(formTemplate?.name)) {
       setLanguage("ar")
      }
  },[])
  useEffect(() => {
    setReload(true);
  }, [language, formTemplate?.pages?.length]);

  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        if (formTemplate?.pages?.length === 0) {
            addPage("0", t(editionTemplateTranslation + ".defaultPage"));
        }
        setReload(false); 
      }, 100);
    }
  }, [reload, formTemplate?.pages?.length, language, t, addPage, editionTemplateTranslation]);
  
  const handlePageSelect = (index) => {
    setIndexCurrentPage(index);
  };
  const length = formTemplate?.pages?.length ?? 0
  return (
    <div className=" edit-form-template-sidebar">
      {formTemplate?.pages?.map((page, index) => {
        const isChecked = index <= currentIndexPage;
        const isNextChecked = index + 1 <= currentIndexPage;
        return (
          <>
            <div
              key={index + "add"}
              className="edit-form-step d-flex align-items-start gap-2 pt-3 pb-2"
            >
              <span
                onClick={() => setShowAdd(index)}
                style={{ cursor: "pointer" }}
              >
                <MdOutlineAddBox size={24} color={"#D3D3D3"} />
              </span>
              <img
                src={isNextChecked ? VLineOnIcon : VLineIcon}
                alt="#"
                className={`edit-form-step-line ${language == "ar" && "edit-form-step-line-arabic"
                  }`}
              />

              {showAdd === index ? (
                <div className="edit-form-add-page">
                  <input
                    onChange={handlePageNameChange}
                    type="text"
                    placeholder={t(editionTemplateTranslation + ".addPage")}
                  />
                  <button
                    style={{ marginRight: "4px" }}
                    onClick={() => handleAddPage(page.index)}
                  >
                    {t(editionTemplateTranslation + ".actions.add")}
                  </button>
                  <button onClick={() => setShowAdd(null)}>
                    {t(editionTemplateTranslation + ".actions.cancel")}
                  </button>
                </div>
              ) : (
                t(editionTemplateTranslation + ".addPage")
              )}
            </div>
            <div
              key={index}
              className="edit-form-step d-flex align-items-start gap-2 pt-3 pb-2"
            >
              <img
                style={{ cursor: "pointer" }}
                src={isChecked ? EditFormPageOnIcon : EditFormPageIcon}
                height="24px"
                width="auto"
                alt=""
                onClick={() => handlePageSelect(index)}
              ></img>
              <img
                src={isNextChecked ? VLineOnIcon : VLineIcon}
                alt="#"
                className={`edit-form-step-line ${language == "ar" && "edit-form-step-line-arabic"
                  }`}
              />

              <div className="page-title">
                {showEdit === index ? (
                  <input
                    type="text"
                    className="page-title-input"
                    value={page.name}
                    onChange={(e) => onPageRename(page.id, e.target.value)}
                  />
                ) : (
                  <span className="page-title-label">{page.name}</span>
                )}
                <FiEdit2
                  style={{ cursor: "pointer" }}
                  color={`${showEdit === index ? "blue" : "gray"}`}
                  onClick={() =>
                    setShowEdit((prev) => {
                      if (prev === index) {
                        return null;
                      }
                      return index;
                    })
                  }
                />
                <FaTrashAlt
                  style={{ cursor: "pointer", color: "gray" }}
                  onClick={() => {
                    if (currentIndexPage === index) {
                      setIndexCurrentPage(0);
                    }
                    onPageDelete(page.index);
                  }}
                />
              </div>
            </div>
          </>
        );
      })
      }
      <>
        <div
          className="edit-form-step d-flex align-items-start gap-2 pt-3 pb-2"
        >
          <span
            onClick={() => setShowAdd(length + 1)}
            style={{ cursor: "pointer" }}
          >
            <MdOutlineAddBox size={24} color={"#D3D3D3"} />
          </span>
          {showAdd === (length + 1) ? (
            <div className="edit-form-add-page">
              <input
                onChange={handlePageNameChange}
                type="text"
                placeholder={t(editionTemplateTranslation + ".addPage")}
              />
              <button
                style={{ marginRight: "4px" }}
                onClick={() => handleAddPage(String(length + 1))}
              >
                {t(editionTemplateTranslation + ".actions.add")}
              </button>
              <button onClick={() => setShowAdd(null)}>
                {t(editionTemplateTranslation + ".actions.cancel")}
              </button>
            </div>
          ) : (
            t(editionTemplateTranslation + ".addPage")
          )}
        </div>
      </>
    </div>
  );
}

export default EditFormTemplateSidebar;
