import { useNavigate } from "react-router-dom";
import { Ressource } from "../../components/common/RessourceCard";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import apiClientWithLoading from "../../services/api/ApiClient";
import { ContractAIEntity, ContractEntity, TypeLevel1Entity } from "../../domain/entities";
import './myContracts.scss'
import { ContractClient } from "../../services/api/ContractClient";
import { getName } from "../../helpers/Translation";
import { useTranslation } from "../../contexts/TranslationProvider";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { DocumentClient } from "../../services/api/DocumentClient";

function Contractslist({ contracts, reloadContracts }:
  {
    contracts: (any)[];
    reloadContracts: () => Promise<void>;
  }
) {
  const { language } = useTranslation()
  const navigate = useNavigate();
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const contractAIClient = new ContractAIClient(apiClient)
  const documentClient = new DocumentClient(apiClient)
  type AbstractContract = (
    ContractEntity & { type: 'Contract' }
    | ContractAIEntity & { type: 'ContractAI' }
  )

  const handleClick = (contract: Ressource) => {
    switch (contract.type) {
      case 'Contract':
        navigate(`/edition-contrat/${contract.id}`)
        break;
      case 'ContractAI':
        navigate(`/ai-edition-contrat/${contract.id}`)
        break;
      case 'Summary':
        navigate(`/document-summary/${contract.id}`)
        break;
      default:
        break;
    }
  };
  const handleDelete = async (ressource: Ressource) => {
    let deleted
    switch (ressource.type) {
      case 'Contract':
        deleted = (await contractClient.delete(ressource.id)).deleted;
        break;
      case 'ContractAI':
          deleted = (await contractAIClient.delete(ressource.id)).deleted;
        break;
      case 'Summary':
        deleted = (await documentClient.delete(ressource.id)).deleted;
        break;
      default:
        break;
    }
    if (deleted) {
      await reloadContracts();
    }
  };

  return (
    <section id="contracts-list" className="contracts-list">
    <div className="ressources-list-container">
      {
        contracts.map((contract) => (
          <RessourceCard
            key={contract.id + "-" + contract.type}
            data={contract}
            onDelete={() => handleDelete(contract)}
            onClick={() => handleClick(contract)}
          />
        ))
      }
    </div>
  </section>
  );
}

export default Contractslist;
