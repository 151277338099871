import React from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";

const ContractCardActionsModal = ({ onDelete, onView, onDesactivate}: {
  onDelete: () => void;
  onView: () => void;
  onDesactivate?: () => void;
}) => {
  const { t, language } = useTranslation();
  const actionCaller = (e, action)=>{
    e.stopPropagation();
    action();
  }
  return (
    <div className="contract-card-actions-modal" style={{marginRight:language=="ar"&&"-30px"}}>
      <ul>
        <li onClick={(e)=>actionCaller(e, onView)}>{t("pages.ressources.actions.view")}</li>
        <li onClick={(e)=>actionCaller(e, onDelete)}>{t("pages.ressources.actions.delete")}</li>
        {
          onDesactivate && <li onClick={onDesactivate}>{t("pages.ressources.actions.deactivate")}</li>
        }
      </ul>
    </div>
  );
};

export default ContractCardActionsModal;
