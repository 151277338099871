import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";

function BooleanRender({param, page, isEditing, value, onChange}) {
  const { onValueChange, paramValues } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");

  return (
    <div className={`input-param`}>
    <div className="input-type">{param.type}</div>
    <div>
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label" htmlFor={param.name}>
          {param.label}
        </label>
      ) : (
        <textarea
          className="custom-form-textarea"
          name={param.name}
          value={value}
          onChange={onChange}
        />
      )}
      <div className="radio-group boolean-group">
        <label className="form-check-label">
          <input
            type="radio"
            value={"true"}
            checked={paramValues[param.name] !== undefined && paramValues[param.name] === (true as any)}
            className="form-check-input boolean-check"
            onChange={() => {
              onValueChange(page.id, param, true)
            }}
            name={param.name}
          />
          {t(translationPath.boolean.oui)}
        </label>
        <label className="form-check-label">
          <input
            type="radio"
            value="false"
            checked={paramValues[param.name] !== undefined && paramValues[param.name] === (false as any)}
            className="form-check-input boolean-check"
            onChange={()=>{
              onValueChange(page.id, param, false)
            }}
            name={param.name}
          />
          {t(translationPath.boolean.non)}
        </label>
      </div>
    </div>
  </div>

  )
}

export default BooleanRender