import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Loading from "../../common/Loading";
import "./edit.scss";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { GroupEntity } from "../../../domain/entities";
import AddTranslation from "../../common/AddTranslation";
const { default: Delete } = require("../../../assets/delete.svg");

interface EditGroupProps {
  onClose: () => void;
  inComingGroup: GroupEntity;
  onUpdate: (group: GroupEntity) => void;
}

function EditGroup({ onClose, inComingGroup, onUpdate }: EditGroupProps) {
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<GroupEntity>(inComingGroup);
  const [translations, setTranslations] = useState(inComingGroup.translations);
  const apiClient = apiClientWithLoading();
  const { t, language } = useTranslation();
  const translationAdd = t("pages.typeLevels.addPopup");
  const translationPath = t("modals.listGroups.")
  const translationEdit = t("pages.typeLevels.editPopup");
  const handleDeleteTranslation = (index) => {
    setGroup(prev => ({...prev, translations: prev.translations.filter((_, i) => i !== index)}))
  }
  const handleLanguageChange = (updatedValue) => {
    console.log(updatedValue)
    setGroup(prev => ({...prev, translations: updatedValue}))
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      onUpdate(group);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="type-add-popup" className="type-add-popup">
        <div className="modal modal-add-type d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t(translationPath + "title")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "75%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-add-type">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{ position: "relative" }}>
                      <label htmlFor="level1">{t(translationAdd.type1)}</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control"
                          id="level1"
                          name="level1"
                          value={group.name}
                          onChange={(e) => {
                            setGroup({ ...group, name: e.target.value });
                          }}
                        />
                      </div>
                      <AddTranslation translationList={translations} handleLanguageChange={handleLanguageChange}
                        handleDeleteTranslation={handleDeleteTranslation} />
                    </div>
                    <div className="actions-popup-type">
                      <button type="submit" className="add-signle-type">
                        {t(translationPath + "save")}
                      </button>
                      <button
                        type="reset"
                        className="cancel-adding-type"
                        onClick={onClose}
                      >
                        {t(translationPath + "cancel")}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditGroup;
