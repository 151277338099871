import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import PilotPage from '../../pages/PilotPage'
import AdminPage from '../../pages/AdminPage'
import MyContracts from '../../pages/MyContracts'
import MyRessources from '../../pages/Ressources'
import GroupContracts from '../../pages/GroupContracts'
import EditContractPage from '../../pages/EditContractPage'
import EditFormPage from '../../pages/Form'
import AiContractConfiguration from '../../pages/AiContractConfiguration'
import ResponseConfiguration from '../../pages/ResponseValidationUseCase'
import UseCasePage from '../../pages/UseCasePage'
import DocumentPage from '../../pages/Document'
import Analytics from '../../pages/Analytics'
import ProjectList from '../../pages/ProjectList'
import CreateProject from '../../pages/CreateProject'
import Project from '../../pages/Project'
import CreateDocument from '../../pages/CreateProjectRequirement'
import NotFound from '../../pages/NotFound'
import DocumentSummaryPage from '../../pages/DocumentSummary'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './transition.scss'

function UserRouter() {
  const location = useLocation();
  return (
        <Routes>
          <Route path="/" element={<PilotPage />} />
          <Route path="/contrats" element={<MyContracts />} />
          <Route path="/ressources" element={<MyRessources />} />
          <Route path="/group-contrats" element={<GroupContracts />} />
          <Route path="/edition-contrat/:contractId?" element={<EditContractPage />} />
          <Route path="/edit-form/:selectedFormId?" element={<EditFormPage />} />
          <Route path="/ai-edition-contrat/:contractId?" element={<AiContractConfiguration />} />
          <Route path="/ai-document-response/:usecaseId" element={<ResponseConfiguration />} />
          <Route path="/use-cases" element={<UseCasePage />} />
          <Route path="/document/:documentId?" element={<DocumentPage />} />
          <Route path="/document-summary/:documentId?" element={<DocumentSummaryPage />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/projets" element={<ProjectList />} />
          <Route path="/create-project" element={<CreateProject />} />
          <Route path="/projet/:projectId?" element={<Project />} />
          <Route path="/createDocumentRequirement/:requirementId" element={<CreateDocument />} />
          <Route path="/openAi" element={<NotFound />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
  )
}

export default UserRouter