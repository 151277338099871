import "./projectList.scss";
import "../index.scss"
import { BsSearch } from "react-icons/bs";
import { useEffect, useMemo, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import { useTranslation } from '../../contexts/TranslationProvider';
import Loading from "../../components/common/Loading";
import ProjectContext, { ProjectContextType } from "../../contexts/ProjectContext";
import { useNavigate } from "react-router-dom";
import { ProjectEntity, ProjectTemplateEntity, TypeLevel1Entity } from "../../domain/entities";
import { ProjectClient } from "../../services/api/ProjectClient";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { getName } from "../../helpers/Translation";
import ProjectCard from "../../components/common/newDesign/ProjectCard";
const ProjectList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectEntity[]>([]);
  const [projectTemplate, setProjectTemplate] = useState<ProjectTemplateEntity[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [updateInProjectsList, setProjectListChanged] = useState(false);

  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const projectClient = new ProjectClient(apiClient)
  const projectTemplateClient = new ProjectTemplateClient(apiClient)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await projectClient.getAll();
        const dataTemplate = await projectTemplateClient.getAll();
        setProjectTemplate(dataTemplate?.rows);
        setProjects(data?.rows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false);
      }
    };
    fetchData();
    setProjectListChanged(false)
  }, [updateInProjectsList]);
  const projectContext: ProjectContextType = useMemo(
    () => ({
      projects,
      setProjects,
      projectTemplate,
      setProjectTemplate
    }),
    [projectTemplate, projects, setProjects, setProjectTemplate]
  );

  const filteredProjects = projects?.filter((project) =>
    project?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedProjects = filteredProjects.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const projectsPerLevel1: Record<TypeLevel1Entity['name'], ProjectTemplateEntity[]> = {}

  const levels1Names = [...new Set(filteredProjects.map(template => getName(template.level1, language)))]
  levels1Names.forEach(level1Name => projectsPerLevel1[level1Name] = [])

  sortedProjects.forEach(template => {
    projectsPerLevel1[getName(template.level1, language)].push(template)
  })
  return (
    <div className="projects px-3 pt-2">
      <section id="projets">
        <button className="btn-create-project" onClick={() => navigate("/create-project", {
          state: {
            projectTemplate: projectTemplate,
          },
        })}>{t("pages.projects.createProject")}</button>
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar w-75">
              <BsSearch className="search-icon search-project" style={{marginRight:language=="ar"&&"89%"}} />
              <input
                type="text"
                placeholder={t("pages.projects.search")}
                className="search-input"
                style={{minWidth: "300px"}}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) : (
            <ProjectContext.Provider value={projectContext}>
              <div className="row gap-5">
                {levels1Names?.map(level1Name => (
                  <div className="col projects-space" key={level1Name}>
                    <h3 className="m-2 projects-space-title">{level1Name}</h3>
                    <div className="project-group-container">
                      <div className="projects-per-level">
                        {projectsPerLevel1[level1Name]?.map((item) => (
                          <div key={item?.id} className="m-2">
                            <div className='project-group'>
                              <ProjectCard data={item} key={item?.id} setProjectListChanged={setProjectListChanged} />
                            </div> 
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ProjectContext.Provider>
          )}
        </div>
      </section>
    </div>
  );
}

export default ProjectList;
