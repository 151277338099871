import React, { useState } from 'react';
import { useTranslation } from '../../../contexts/TranslationProvider';
import Loading from '../../common/Loading';
import { UserEntity } from '../../../domain/entities';
import { addUser } from '../../../services/api/UserAuth';
import { toast } from "react-toastify";
import "./addUser.scss";
import apiClientWithLoading from '../../../services/api/ApiClient';
import { UserClient } from '../../../services/api/UserClient';
interface AddUserProps {
  onClose: () => void;
  titleEdit?: string;
  setNewUser?: (value: boolean) => void
  user?:UserEntity
}
function AddUser({ onClose, setNewUser , titleEdit, user}:AddUserProps) {
  const [loading, setLoading] = useState(false);
  const apiClient = apiClientWithLoading();
  const { t, language } = useTranslation();
  const userClient = new UserClient(apiClient);
  const [userData, setUserData] = useState<UserEntity>({
    firstName: user.firstName||'',
    lastName: user.lastName||'',
    email: user.email||'',
    UserType: user.UserType || 'Client' 
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }) as Partial<UserEntity>);
    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = {} as typeof validationErrors;
    if (!userData.firstName) {
      errors.firstName = t('pages.users.popup.validation.required');
    }
    if (!userData.lastName) {
      errors.lastName = t('pages.users.popup.validation.required');
    }
    if (!userData.email) {
      errors.email = t('pages.users.popup.validation.required');
    }
    if (!userData.UserType) {
      errors.role = t('pages.users.popup.validation.required');
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      if(Object.keys(user).length ===0){
      const response = await addUser(apiClient, userData.firstName, userData.lastName, userData.email, userData.UserType);
      setLoading(false);
      setNewUser(true);
      toast.success(t("pages.users.popup.messages.success"));
      onClose();
    }
    else{
      const response = await userClient.update(user.id,userData);
      setLoading(false);
      setNewUser(true);
      toast.success(t("pages.users.popup.messages.successUpdate"));
      onClose();
    }
    } catch (error) {
      console.error('Error adding user:', error);
      Object.keys(user).length ===0 ? toast.error(t("pages.users.popup.messages.error")) :  toast.error(t("pages.users.popup.messages.errorUpdate"));
      setLoading(false);
    }
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="user-add-popup" className='user-add-popup'>
        <div className="modal modal-add-user d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{Object.keys(user).length ===0 ? t("pages.users.popup.title"):t("pages.users.popup.editTitle")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language === "ar" && "60%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-add-user">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="firstName">{t('pages.users.popup.firstname')}</label>
                      <input type="text" className="form-control" id="firstName" name="firstName" value={userData.firstName} onChange={handleChange} />
                      {validationErrors.firstName && <div className="error-message">{validationErrors.firstName}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">{t('pages.users.popup.lastname')}</label>
                      <input type="text" className="form-control" id="lastName" name="lastName" value={userData.lastName} onChange={handleChange} />
                      {validationErrors.lastName && <div className="error-message">{validationErrors.lastName}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{t('pages.users.popup.email')}</label>
                      <input type="email" className="form-control" id="email" name="email" value={userData.email} onChange={handleChange} />
                      {validationErrors.email && <div className="error-message">{validationErrors.email}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">{t('pages.users.popup.userRole')}</label>
                      <select disabled={Object.keys(user).length >0 ? true :false} className="user-role-select" id="role" name="UserType" value={userData.UserType} onChange={handleChange}>
                      <option id='Client' value="Client">{t('pages.users.popup.userType.Client')}</option>
                        <option id='Admin' value="Admin">{t('pages.users.popup.userType.Admin')}</option>
                      </select>
                      {validationErrors.role && <div className="error-message">{validationErrors.role}</div>}
                    </div>
                    <div className='actions-popup-user'>
                      <button type="submit" className="add-signle-user">{Object.keys(user).length ===0 ? t('pages.users.popup.addUser'):t('pages.users.popup.editUser') }</button>
                      <button type="reset" className="cancel-adding-user" onClick={onClose}>{t('pages.users.popup.cancelAddUser')}</button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
