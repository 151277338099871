import { useRef, useState } from "react";
import FileInput from "../../../common/FileInput";
import ShowCase from "../../../ShowCase";
import { ValidationWarning } from "../../../../domain/ContractTemplate";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import apiClientWithLoading from "../../../../services/api/ApiClient";
import { ContractTemplateClient } from "../../../../services/api/ContractTemplateClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PendingAi from "../../../common/newDesign/PendingAi";

function ImportCSV({onClose, setLoading, loading}) {
  const [Errors, setErrors] = useState([]);
  const [contractId, setContractID] = useState(null);
  const [Warnings, setWarnings] = useState<ValidationWarning[]>([]);
  const [showWarningsModal, setShowWarningsModal] = useState(false);
  const [file, setFile] = useState<File>(null);
  const { t, language } = useTranslation();
  const modalContentRef = useRef(null);
  const navigate = useNavigate();

  const apiClient = apiClientWithLoading(setLoading);
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const handleSave = async () => {
    if (file) {
      setLoading(true);
      try {
        const response = await contractTemplateClient.import(file);
        if (response.warnings && response.warnings.length > 0) {
          setWarnings(response.warnings);
          setShowWarningsModal(true);
          setContractID(response?.rows[0]?.id)
        } else {
         navigate(`/edition-contrat-template/${response?.rows[0]?.id}`);
          onClose();
        }
        toast.success(t("modals.importContractTemplate.messages.success"));
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data) {
          const { errors, warnings } = error.response.data;
          setErrors(errors);
          setWarnings(warnings);
          toast.error(
              t("modals.importContractTemplate.messages.error")
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <div ref={modalContentRef}>
          <div className="modal-body pb-5" style={{ minHeight: "200px" }}>
            <div className="form-floating mb-3 pt-3">
              <FileInput
                onChange={(file) => {
                  setFile(file);
                  setErrors([]);
                  setWarnings([]);
                }}
                value={file}
                borderClassName={file ? "green-border" : "grey-border"}
              />
            </div>
            <div
              style={{
                maxHeight: "350px",
                overflowY: "auto",
                marginBottom: "8px",
              }}
            >
              {Errors.length > 0 && (
                <div>
                  {Errors?.map((error, index) => (
                    <p
                      key={index}
                      className="alert alert-danger error-after-import"
                      role="alert"
                    >{`Template [${error.templateCode}] : ${error.error}`}</p>
                  ))}
                </div>
              )}
              {Warnings.length > 0 && (
                <div>
                  <p>
                    {Warnings.map((warning, index) => (
                      <p
                        key={index}
                        className="alert alert-warning error-after-import"
                        role="alert"
                      >
                        {t(
                          "modals.importContractTemplate.warnings.message.template"
                        )}{" "}
                        [{warning.templateCode}] : {warning?.message}{" "}
                        <strong>{warning?.paramName}</strong>{" "}
                        {t(
                          "modals.importContractTemplate.warnings.message.clause"
                        )}
                        {warning?.clauseCode}{" "}
                        {t(
                          "modals.importContractTemplate.warnings.message.subclause"
                        )}
                        {warning?.subClauseCode}.
                      </p>
                    ))}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ left: "4.5%" }}
              onClick={handleSave}
            >
              {t("modals.importContractTemplate.import")}
            </button>
          </div>
        </div>
      )}
      {showWarningsModal && (
        <>
          <div className="modal d-flex justify-content-center align-items-center">
            <div style={{ width: "50%" }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t("modals.importContractTemplate.warnings.title")}
                  </h5>
                  <button
                    className="btn-close"
                    onClick={() => {
                      setShowWarningsModal(false);
                      onClose();
                    }}
                    aria-label="Close"
                    style={{ marginRight: language == "ar" && "90vh" }}
                  ></button>
                </div>
                <div
                  className="d-flex"
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                    marginBottom: "8px",
                    justifyContent: "center",
                  }}
                >
                  {Warnings?.length > 0 && (
                    <div style={{ width: "90%", height: "" }}>
                      <p>
                        {Warnings?.map((warning, index) => (
                          <p
                            key={index}
                            className="alert alert-warning error-after-import"
                            role="alert"
                          >
                            {t(
                              "modals.importContractTemplate.warnings.message.template"
                            )}{" "}
                            [{warning.templateCode}] : {warning?.message}{" "}
                            <strong>{warning?.paramName}</strong>{" "}
                            {t(
                              "modals.importContractTemplate.warnings.message.clause"
                            )}
                            {warning?.clauseCode},{" "}
                            {t(
                              "modals.importContractTemplate.warnings.message.subclause"
                            )}
                            {warning?.subClauseCode}.
                          </p>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <div
                    className="btn-warning"
                    onClick={() => setShowWarningsModal(false)}
                  >
                    {t("modals.importContractTemplate.warnings.btn-fix")}
                  </div>
                  <div
                    className="btn-warning"
                    onClick={() => {
                      setShowWarningsModal(false);
                      contractId&&navigate(`/edition-contrat-template/${contractId}`)
                      onClose();
                    }}
                  >
                    {t("modals.importContractTemplate.warnings.continue")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImportCSV;
