import "../commonmodal.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";

import ShowCase from "../../ShowCase";
import CustomCombobox from "../../common/CustomCombobox";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ProjectEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { numberToEnumList } from "../../../domain/Project";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { ProjectClient } from "../../../services/api/ProjectClient";
import { getName } from "../../../helpers/Translation";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { DocumentClient } from "../../../services/api/DocumentClient";
import CheckBoxInput from "../../common/CheckBoxInput";
import { on } from "events";
import { toast } from "react-toastify";
import PendingAi from "../../common/newDesign/PendingAi";
interface FillDocumentSummaryModalType {
  onClose: () => void;
  dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
  typeReq?: number
}
function FillDocumentSummaryModal({ onClose, dataProject, typeReq }: FillDocumentSummaryModalType) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] = useState<NodeJS.Timeout>(null);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const modalContentRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isScanned, setIsScanned] = useState(false);
  const { t, language } = useTranslation();
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  const deleteFile = (onChange) => {
    setFile(null);

    // Create a new Event
    const event = new Event("input", { bubbles: true });

    // Reset the value of the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;

      // Dispatch the event
      fileInputRef.current.dispatchEvent(event);
    }

    // Call onChange with the event
    onChange(event);
  };
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [displayLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }
  >({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = apiClientWithLoading();
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const documentClient = new DocumentClient(apiClient)
  const projectClient = new ProjectClient(apiClient)
  useEffect(() => {
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find(
      (item) => getName(item, language as any) === value
    );
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", t("pages.listener.popups.contract.inputs.type2.value"));
      setValue("Type3", t("pages.listener.popups.contract.inputs.type3.value"));
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find(
      (item) => getName(item, language as any) === value
    );
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3:
          Array.isArray(selectedLevel2.levels3) &&
          selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", t("pages.listener.popups.contract.inputs.type3.value"));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => getName(item, language as any) === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };
  const fillDocumentSummary = useCallback(
    async (file, name) => {
      setLoading(true);
      const {
        row: { id: documentId },
      } = await documentClient.upsertMultiForm({ file, name, isScanned });
      const { row: template } = await contractTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'pure');
      const templateId = template?.id;
      if (templateId) {
        const summary = await documentClient.fillSummaryWithAIAndContractTemplate(documentId, templateId);
        if (dataProject?.project) {
          const updatedProject = await projectClient.update(dataProject?.project?.id, {
            values: {
              ...dataProject.project?.values,
              [dataProject?.requirement.id]: {
                type: numberToEnumList(typeReq)[0],
                id: summary?.documentId,
              },
            },
            excludedRequirements: [...dataProject.project.excludedRequirements]
          });
        }
        return summary;
      }
      return;
    },
    [apiClient, setLoading]
  );
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    let selectedLevel, selectedValue;
    // Check which level the user has selected
    if (data.Type3 !== "Type Niveau 3" || dataProject?.requirement?.level3?.name) {
      selectedLevel = "level3.name";
      selectedValue = dataProject ? dataProject?.requirement?.level3?.name : data.Type3;
      if (dataProject) {
        data.Type3 = selectedValue
      }
    } else if (data.Type2 !== "Type Niveau 2" || dataProject?.requirement?.level2?.name) {
      selectedLevel = "level2.name";
      selectedValue = dataProject ? dataProject?.requirement?.level2?.name : data.Type2;
      if (dataProject) {
        data.Type2 = selectedValue
      }
    } else if (data.Type1 !== "Type Niveau 1" || dataProject?.requirement?.level1?.name) {
      selectedLevel = "level1.name";
      selectedValue = dataProject ? dataProject?.requirement?.level1?.name : data.Type1;
      if (dataProject) {
        data.Type1 = selectedValue
      }
    }
    try {
      const summary = await fillDocumentSummary(file, data.contractName);
      setLoading(false);
      navigate(`/document-summary/${summary.documentId}`,
      );
    } catch (error) {
      console.error('Error fetching AI contract data:', error);
      toast.error(t("pages.pilot.popups.contract.errorImport"));
    }finally{
      setLoading(false);
      onClose();
    }
  };
  const contractName = watch("contractName");
  useEffect(() => {
    if (checkContractExistsTimeout) {
      clearTimeout(checkContractExistsTimeout)
    }
    setCheckContractExistsTimeout(setTimeout(async () => {
      try {
        const count = await documentClient.countAllByFilter({ "name": contractName });
        setContractExistsError(count >= 1);
      } catch (error) {
        console.error(error);
      } finally {
        setCheckContractExistsTimeout(null)
      }
    }, 500))
  }, [contractName]);
  return (
    <>
      <div
        className="modal-backdrop fade show"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.listener.popups.contract.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  disabled={loading}
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div style={{ overflowY: "hidden" }} className="modal-body modal-body-pilotage" ref={modalContentRef}>
                {!loading && (
                  <p>
                    {t("pages.listener.popups.contract.description")}
                  </p>
                )}
                {loading === true ? (
                  <PendingAi height="100%" />
                ) : (
                  <form
                    action=""
                    className="form-group"
                    style={{ maxHeight: "500px", marginBottom: "70px", overflowY: "auto", overflowX: "hidden", paddingLeft: "20px" }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <p className="fw-bolder">
                      {t("pages.listener.popups.contract.required")}
                    </p>
                    <div className="form-input-content">
                      <div className="form-input-first-content w-50">
                        <label htmlFor="contractName" className="case-form-labelName">
                          {t("pages.listener.popups.contract.inputs.contractName.name")} {" "}
                        </label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input input-popup"
                            id="contractName"
                            placeholder={t("pages.listener.popups.contract.inputs.contractName.placeholder")}
                            onChange={() => {
                              setContractExistsError(false);
                            }}
                            {...register("contractName", { required: true })}
                          />
                        </div>
                        {errors.contractName && (
                          <p className="text-danger py-0 my-0 py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.contractName.error1")}
                          </p>
                        )}
                        {contractExistsError && (
                          <p className="text-danger py-0 my-0 py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.contractName.error2")}
                          </p>
                        )}
                      </div>
                      {dataProject ? (
                        <div className="content-levels">
                          <label className="case-form-labelName">{t("pages.listener.popups.contract.inputs.type1.name")}</label>
                          <select value={dataProject?.requirement?.level1?.name} disabled>
                            <option>{dataProject?.requirement?.level1?.name}</option>
                          </select>
                          {dataProject?.requirement?.level2 && (
                            <>
                              <label className="case-form-labelName">{t("pages.listener.popups.contract.inputs.type2.name")}</label>
                              <select value={dataProject?.requirement?.level2?.name} disabled>
                                <option>{dataProject?.requirement?.level2?.name}</option>
                              </select>
                            </>
                          )}
                          {dataProject?.requirement?.level3 && (
                            <>
                              <label className="case-form-labelName">{t("pages.listener.popups.contract.inputs.type3.name")}</label>
                              <select value={dataProject?.requirement?.level3?.name} disabled>
                                <option>{dataProject?.requirement?.level3?.name}</option>
                              </select>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className=""
                          style={{ width: "40%" }}
                        >
                          <Controller
                            name="Type1"
                            control={control}
                            defaultValue={t("pages.listener.popups.contract.inputs.type1.value")}
                            rules={{
                              required: true,
                              validate: (value) => value !== t("pages.listener.popups.contract.inputs.type1.value"),
                            }}
                            render={({ field }) => (
                              <CustomCombobox
                                fullWidth
                                label={t("pages.listener.popups.contract.inputs.type1.name")}
                                options={options.level1.map(
                                  (item) => getName(item, language as any)
                                )}
                                value={field.value}
                                onDropdownOpen={handleComboboxClick}
                                onChange={(value) => {
                                  handleLevel1Change(value);
                                  field.onChange(value);
                                }}
                                isOpen={openCombobox === "level1"}
                                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                              />
                            )}
                          />

                          {errors.Type1 && (
                            <p className="text-danger py-0 my-0">
                              {t("pages.listener.popups.contract.inputs.type1.error")}
                            </p>
                          )}
                          {displayLevels.level2 && (
                            <Controller
                              name="Type2"
                              control={control}
                              defaultValue={t("pages.listener.popups.contract.inputs.type2.value")}
                              rules={{
                                required: true,
                                validate: (value) => value !== t("pages.listener.popups.contract.inputs.type2.value"),
                              }}
                              render={({ field }) => (
                                <CustomCombobox
                                  fullWidth
                                  label={t("pages.listener.popups.contract.inputs.type2.name")}
                                  onDropdownOpen={handleComboboxClick}
                                  options={options.level2.map(
                                    (item) => getName(item, language as any)
                                  )}
                                  value={field.value}
                                  onChange={(value) => {
                                    handleLevel2Change(value);
                                    field.onChange(value);
                                  }}
                                  isOpen={openCombobox === "level2"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                                />
                              )}
                            />
                          )}
                          {options.level2.length > 0 && errors.Type2 && (
                            <p className="text-danger py-0 my-0">
                              {t("pages.listener.popups.contract.inputs.type2.error")}
                            </p>
                          )}
                          {displayLevels.level3 && (
                            <Controller
                              name="Type3"
                              control={control}
                              defaultValue={t("pages.listener.popups.contract.inputs.type3.value")}
                              rules={{
                                required: true,
                                validate: (value) => value !== t("pages.listener.popups.contract.inputs.type3.value"),
                              }}
                              render={({ field }) => (
                                <CustomCombobox
                                  fullWidth
                                  label={t("pages.listener.popups.contract.inputs.type3.name")}
                                  onDropdownOpen={handleComboboxClick}
                                  options={options.level3.map(
                                    (item) => getName(item, language as any)
                                  )}
                                  value={field.value}
                                  onChange={(value) => {
                                    handleLevel3Change(value);
                                    field.onChange(value)
                                  }
                                  }
                                  isOpen={openCombobox === "level3"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                                />
                              )}
                            />
                          )}

                          {options.level3.length > 0 && errors.Type3 && (
                            <p className="text-danger py-0 my-0">
                              {t("pages.listener.popups.contract.inputs.type3.error")}
                            </p>
                          )}
                        </div>)}
                    </div>
                    <Controller
                      name="file"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "dashed 2px #ccc",
                            padding: "0.5rem",
                            cursor: "pointer",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                          }}
                          className="mt-3"
                        >
                          <div className="d-flex ms-1">
                            {file?.name && (
                              <AiFillDelete
                                style={{
                                  marginRight: "0.5rem",
                                  color: "#2f14e5",
                                  cursor: "pointer",
                                }}
                                onClick={() => deleteFile(field.onChange)}
                              />
                            )}
                            <AiOutlineUpload
                              style={{
                                marginRight: "0.5rem",
                                color: "#2f14e5",
                              }}
                              onClick={triggerFileInput}
                            />
                          </div>
                          <p style={{ margin: 0 }}>
                            {file?.name || t("pages.listener.popups.contract.inputs.file.placeholder")}
                          </p>

                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileChange(e);
                              field.onChange(e);
                            }}
                          />
                        </div>
                      )}
                    />
                    {errors.file && (
                      <p className="text-danger py-2 my-0 py-0 my-0">
                        {t("pages.listener.popups.contract.inputs.file.error")}
                      </p>
                    )}
                    <CheckBoxInput
                      checked={isScanned}
                      name={t("pages.listener.popups.contract.inputs.isScanned")}
                      onChange={() => setIsScanned(!isScanned)}
                      disabled={false}
                    />

                    <div className="modal-footer footer-form-pilotage">
                      <button
                        disabled={contractExistsError || !!checkContractExistsTimeout}
                        type="submit" className="btn btn-primary" style={{ left: "4.5%" }}>
                        {t("pages.listener.popups.contract.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FillDocumentSummaryModal;
