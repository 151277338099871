import { useEffect, useState } from "react";
import "./sidebar.scss";
import { useLocation } from "react-router-dom";
import { getAuthUser } from "../../../services/api/UserAuth";
import SidebarItem from "./SidebarItem";
import SidebarUser from "./SidebarUser";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { icons } from "../../../assets/icons/sidebarIcon"
import { REACT_APP_LISTENER_ON } from "../../../config";
import SidebarList from "./SidebarList";

function
  Sidebar({ expanded }) {
  const location = useLocation();
  const { user } = getAuthUser();
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();
  const handleMouseOver = () => {
    if (!expanded)
      setIsHovering(true);
  };
  const handleMouseOut = () => {
    if (!expanded)
      setIsHovering(false);
  };
  useEffect(() => {
    const handlePathnameChange = () => {
      handleMouseOver();
      handleMouseOut();
    };
    handlePathnameChange();
  }, [location.pathname]);

  return (
    <div
      className={"sidebar-container"}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ position: "relative" }}
    >
      <div className="ps-3 sidebar-wrapper">
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-column align-items-start gap-3 sidebar-list ">
            {user?.UserType == 'Admin' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin" location={location.pathname} text={t("sidebar.admin")} icon={icons.admin} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/users" location={location.pathname} text={t("sidebar.users")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/contract-templates" location={location.pathname} text={t("sidebar.contractTemplates")} icon={icons.templates} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/form-templates" location={location.pathname} text={t("sidebar.formTemplates")} icon={icons.templates} />
              </>
              || user?.UserType == 'Client' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/" location={location.pathname} text={t("sidebar.pilotage")} icon={icons.admin} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/projets" location={location.pathname} text={t("sidebar.myProjects")} icon={icons.projects} />
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.documents")} location={location.pathname} destinations={["/contrats", "/group-contrats", "/ressources"]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/contrats" location={location.pathname} text={t("sidebar.myContracts")} icon={icons.contracts} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/group-contrats" location={location.pathname} text={t("sidebar.ourContracts")} icon={icons.groupContracts} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources" location={location.pathname} text={t("sidebar.myResources")} icon={icons.ressources} />
                </SidebarList>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/analytics" location={location.pathname} text={t("sidebar.analytics")} icon={icons.analytics} />
                {REACT_APP_LISTENER_ON &&
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/use-cases" location={location.pathname} text={t("sidebar.listener")} icon={icons.listener} />
                }
              </>
              || user?.UserType == 'CoContractor' &&
              <>
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.documents")} location={location.pathname} destinations={["/contrats", "/group-contrats", "/ressources"]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/contrats" location={location.pathname} text={t("sidebar.myContracts")} icon={icons.contracts} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources" location={location.pathname} text={t("sidebar.myResources")} icon={icons.ressources} />
                </SidebarList>
              </>
            }
          </div>
        </div>
        <div className="d-flex flex-column align-items-start gap-3">
          <SidebarItem isHovering={expanded ? true : isHovering} to="/settings" location={location.pathname} text={t("sidebar.support")} icon={icons.support} />
          <SidebarUser isHovering={expanded ? true : isHovering} user={user} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
