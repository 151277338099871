import { useContext, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { ContractTemplateParsedData, ParsedClauseParam, extractOptionalClauses, extractOptionalSubclauses, generateCombinedParams } from "../../../helpers/ParseTemplateData";
import InputValuesContext from "../../../contexts/InputValuesContext";
import SelectionContext, { SelectedType } from "../../../contexts/SelectionContext";
import { calculateCompletionPercentage } from "../../../helpers/CompletionPercentage";
import EditContractInputList from "./EditContractInputList";
import { isClauseExcluded, isSubClauseExcluded } from "../../../helpers/helper";
import { getRenderedParams } from "../../../helpers/renderedParams";
import { ContractEntity } from "../../../domain/entities";
import EditContractClauseList from "./EditContractClauseList";
import ProgressBar from "../../../components/common/ProgressBar";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { useLocation } from "react-router-dom";

//maintain the state of the fetched contract data and handle the interactions between the ClauseList and VariableInput components.
function EditContract({
  templateData,
  contractName,
  isEditing,
  paramValues,
  initialExcludedClauses,
  initialExcludedSubClauses,
}: {
  templateData: ContractTemplateParsedData,
  contractName: ContractEntity['name'],
  isEditing: boolean,
  paramValues: ContractEntity['paramValues'],
  initialExcludedClauses: ContractEntity['excludedClauses'],
  initialExcludedSubClauses: ContractEntity['excludedSubClauses'],
}) {
  const {
    inputValues,
    setInputValues,
    fileNames,
    beneficialsMap,
    completionPercentage,
    excludedClauses,
    setExcludedClauses,
    excludedSubClauses,
    setExcludedSubClauses,
    setCompletionPercentage,
    contractListParentRef,
  } = useContext(InputValuesContext);

  const [selected, setSelected] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });
  const { t } = useTranslation();
  const combinedParams: ParsedClauseParam[] = useMemo(() => {
    const renderedParamNames = []
    templateData
      ?.filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
      ?.map((clause) => {
        renderedParamNames.push(...getRenderedParams(clause.rawText[0], inputValues, fileNames, beneficialsMap, clause.params, clause.code))
        clause.subClauses
          .filter((subClause) => !isSubClauseExcluded(subClause.id, excludedSubClauses))
          .map((subClause) => {
            renderedParamNames.push(...getRenderedParams(subClause.rawText[0], inputValues, fileNames, beneficialsMap, subClause.params, clause.code, subClause.code))
          });
      });

    const combinedParams = generateCombinedParams(templateData, isEditing, paramValues).map((param) => {
      param.rendered = renderedParamNames.includes(param.name);
      return param;
    });
    return combinedParams;
  }, [templateData, isEditing, paramValues, inputValues, excludedClauses, excludedSubClauses]);

  const renderedParams = useMemo(() => combinedParams.filter((param) => param.rendered), [combinedParams]);
  const optionalClauses = useMemo(() => extractOptionalClauses(templateData), [templateData]);
  const optionalSubClauses = useMemo(() => extractOptionalSubclauses(templateData), [templateData]);

  // Memoize the value object for InputValuesContext.Provider
  useEffect(() => {
    const newCompletionPercentage = calculateCompletionPercentage(inputValues, renderedParams, excludedClauses, excludedSubClauses);
    setCompletionPercentage(newCompletionPercentage);
  }, [inputValues, renderedParams, excludedClauses, excludedSubClauses]);

  const [isClauseEditing, setIsClauseEditing] = useState(false)

  // Memoize the value object for SelectionContext.Provider
  const selectionContextValue = useMemo(
    () => ({
      selected, setSelected,
      isClauseEditing, setIsClauseEditing,
    }),
    [selected, setSelected, isClauseEditing, setIsClauseEditing]
  );

  useEffect(() => {
    if (isEditing) {
      const newInputValues = {};

      combinedParams.forEach((param) => {
        const { name, value } = param;
        newInputValues[name] = value;

      });

      setInputValues(newInputValues);
      setExcludedClauses(initialExcludedClauses);
      setExcludedSubClauses(initialExcludedSubClauses);
    }
  }, [isEditing]);

  const clauseListRef = useRef(null);
  const inputListRef = useRef(null);

  const titleRef = useRef(null);
  const location = useLocation()

  return (
    <SelectionContext.Provider value={selectionContextValue}>
      <div className="row edit-contract">
        <div className="col-8" ref={contractListParentRef} id="contractListParent" style={{ position: "relative" }}>
          <div className="contract-header d-flex justify-content-between align-items-center px-2">
            <h1 className="contract-name" ref={titleRef} id="contractName">
              {contractName}
            </h1>
            <div className="contract-tools">

            </div>
          </div>
          <div ref={clauseListRef} className="clause-list-container" id="clauseListContainer">
            <EditContractClauseList clauseListRef={clauseListRef} templateData={templateData} />
          </div>
        </div>
        <div className="col-4 contract-clause">
          <div className="d-flex px-3 contract-clause-header justify-content-between align-items-center">
            <div className="contract-clause-name">
              <h4>{t("pages.editionContract.edit")}</h4>
            </div>
            <div style={{width: "100%"}}>
            <ProgressBar expanded={false} progress={completionPercentage} />
            </div>
          </div>
          <div ref={inputListRef} className="variable-input-container">
            <EditContractInputList templateData={templateData} inputListRef={inputListRef} isEditing={isEditing} />
            <div style={{ height: 500 }} />
          </div>
        </div>
      </div>
    </SelectionContext.Provider>
  );
}
export default EditContract;
