import "./formParameterInput.scss";
import FileInput from "../../../components/common/FileInput";
import { useContext, useEffect, useState } from "react";
import AddBenificialModel from "../../../components/modals/AddBeneficialModal";
import EditTableModal from "../../../components/EditTableModal";
import { extractXLSXCells } from "../../../utils/csv";
import { transpose } from "../../../utils/array";
import { FormTemplatePageEntity } from "../../../domain/entities";
import { FormListInputItem } from "./FormListInputItem";
import { isFileType } from "../../../helpers/helper";
import EditFormContext from "../../../contexts/EditFormContext";
import FormDateInput from "./FormDateInput";
import { FormParam } from "../../../domain/types/FormParams";
import { useTranslation } from "../../../contexts/TranslationProvider";
import BeneficialForm from "./beneficialForm";
import { set } from "react-hook-form";
import FormNestedChildrenParams from "./FormNestedChildrenParams";

function FormParameterInput({
  page,
  param,
  handleInputChange,
  setShowBeneficial,
  triggeredParam
}: {
  page: FormTemplatePageEntity;
  param: FormParam;
  setShowBeneficial: any;
  handleInputChange(paramValue: any, paramName: string): void;
  triggeredParam: string | null;
}) {
  const { beneficialsMap, setBeneficialsMap, fileNames, paramValues } = useContext(EditFormContext);
  const currentValue = paramValues[param.name];
  const currentTranspose = !!currentValue?.[0]
  let currentCells = param.type == "csv" ? currentValue?.[1] ?? [] : []
  const { t, language } = useTranslation();

  currentCells = currentTranspose ? transpose(currentCells) : currentCells
  const [showTableModal, setShowTableModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [csvCells, setCsvCells] = useState(currentCells)
  const [isHighlighted, setIsHighlighted] = useState(false);

  const fieldName = `${page.id}_${param.name}`;
  useEffect(() => {
    if (!triggeredParam) {
      setIsHighlighted(false)
      return
    }
    if (triggeredParam === param.name) {
      setIsHighlighted(true)
    } else {
      if (param.condition && param.condition.length > 0) {
        const isNested = param.condition.find((con) => con.find((c) => c.name === triggeredParam))
        if (isNested)
          setIsHighlighted(true)
        if (!isNested)
          setIsHighlighted(false)
      }
    }
  }, [triggeredParam]);

  switch (param.type) {
    case 'beneficial':
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <BeneficialForm
            beneficialsMap={beneficialsMap}
            setBeneficialsMap={setBeneficialsMap}
            currentBenificialData={beneficialsMap[currentValue] || {}}
            onSave={(beneficialId) => {
              handleInputChange(beneficialId, param.name,);
            }}
            beneficialTypes={param?.args?.beneficialTypes ?? ['Company', 'Person']}
          />
        </div>
      )
    case 'table':
      const currentTableValues: (string | Date | number)[][] = currentValue ?? [];
      let rowsTable = param?.args?.length > 0
      return (
        rowsTable && <>
          <button
            type="button"
            className={`benificial-button ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
            onClick={() => setShowTableModal(true)}
          >
            {param.label}
          </button>
          {showTableModal && (
            <EditTableModal
              param={param}
              onClose={() => setShowTableModal(false)}
              currentTableValues={currentTableValues}
              onSave={(tableData) => {
                handleInputChange(tableData, param.name,);
                // setShowBenificialModel(false);
              }}
            />
          )}
        </>
      );

    case 'boolean':
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>

          <div className="radio-group boolean-group">
            <label className="form-check-label">
              <input
                type="radio"
                value="true"
                checked={currentValue}
                className="form-check-input boolean-check"
                onChange={() => {
                  handleInputChange(
                    true,
                    param.name,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionForm.yes")}
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                value="false"
                checked={!currentValue}
                className="form-check-input boolean-check"
                onChange={(e) => {
                  handleInputChange(
                    false,
                    param.name,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionForm.no")}
            </label>
          </div>
          <FormNestedChildrenParams
            page={page}
            param={param}
            setShowBeneficial={setShowBeneficial}
            handleInputChange={handleInputChange}
            triggeredParam={triggeredParam}
          />
        </div>
      );
    case 'date':
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label ?? "JJ/MM/AAAA"}
            </label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div>
            <div className="w-100">
              <FormDateInput
                name={fieldName}
                defaultValue={currentValue}
                onChange={(date) => {
                  if (date && !isNaN(date.getTime())) {
                    handleInputChange(date.toISOString().substring(0, 10), param.name,);
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      const fileId = currentValue && Number(currentValue) || undefined
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label}
            </label>

            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="d-flex gap-2">
            <div className="w-100">
              <FileInput
                onChange={(file) =>
                  handleInputChange(file, param.name)
                }
                value={displayFile}
                id={fileId}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>
          </div>
        </div>
      );
    case 'csv':
      const onCSVInputChange = (file: File) => {
        if (file) {
          extractXLSXCells(file, (cells) => {
            setCsvCells(cells)
            handleInputChange([false, cells, file.name], param.name,)
          })
        } else {
          setCsvCells([])
          handleInputChange(null, param.name,)
        }
      }
      const onTranspose = (value: boolean) => {
        handleInputChange([value, value ? transpose(csvCells) : csvCells, currentValue[2]], param.name,)
      }
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label}
            </label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="d-flex gap-2">
            {currentValue && (
              <div className="switch w-10">
                <input
                  type="checkbox"
                  id={`toggle_${page.id}`}
                  onChange={e => onTranspose(e.target.checked)}
                />
                <label htmlFor={`toggle_${page.id}`}></label>
              </div>
            )}
            <div className="w-100">
              <FileInput
                onChange={onCSVInputChange}
                value={currentValue ? { name: currentValue[2] } : null}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>

          </div>
        </div>
      );

    case 'list':
      const hasValueList = currentValue !== null && currentValue !== undefined && currentValue !== ""
      let rowsList = param?.args?.length > 0
      return (

        rowsList && <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100  justify-content-between">
            <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
            {hasValueList ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="wrapper-item-list-form">
            {param.args && param.args.map((arg, index) => (
              <FormListInputItem
                currentValue={currentValue}
                key={index}
                index={index}
                handleInputChange={handleInputChange}
                param={param}
                colorLabel="#212529"
              />
            ))}
          </div>
        </div>
      )



    case 'enum':
      const enumOptions = param.args && param.args.map((arg) => arg.option);
      const hasValue = currentValue !== null && currentValue !== undefined && currentValue !== "";
      let rowsEnum = param?.args?.length > 0
      return (
        rowsEnum && <>
          <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>

            <div className="d-flex w-100 justify-content-between">

              <label className="edit-label-form">{param.label}</label>
              {hasValue ? (
                <div className="input-progress-done ms-2">
                  {t("pages.editionForm.complete")}
                </div>
              ) : (
                <div className="input-inprogres ms-2">
                  {t("pages.editionForm.inProgress")}
                </div>
              )}
            </div>

            <div className="radio-group flex-column align-items-start w-100 pb-1" style={{ gap: "0rem" }}>

              {enumOptions && enumOptions.map((option, index) => (
                <div key={`${fieldName}_${index}`} className="form-check">
                  <div>
                    <input
                      type="radio"
                      className="form-check-input"
                      value={index}
                      checked={currentValue == index}
                      onChange={(e) => {
                        console.log(index, e.target.value);
                        handleInputChange(index, param.name);
                      }}
                    />
                    <label className="form-check-label" htmlFor={String(index)}>
                      {option}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <FormNestedChildrenParams
              page={page}
              param={param}
              setShowBeneficial={setShowBeneficial}
              handleInputChange={handleInputChange}
              triggeredParam={triggeredParam}
            />
          </div>
        </>
      );

    default:
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form">{param.label}</label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <input
            className="edit-input-form"
            value={currentValue}
            onChange={(e) =>
              handleInputChange(e.target.value, param.name)
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      );
  }
}
export default FormParameterInput;
