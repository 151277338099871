import { useState, useEffect, useContext } from "react";

import InputValuesContext from "../contexts/InputValuesContext";
import apiClientWithLoading from "../services/api/ApiClient";
import { getUserGroups } from "../services/api/UserAuth";
import parseTemplateData from "../helpers/ParseTemplateData";
import { LoadingContext } from "../contexts/LoadingContext";

import { ContractEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../domain/entities";
import { ContractClient } from "../services/api/ContractClient";
import { GroupClient } from "../services/api/GroupClient";
import { ContractTemplateClient } from "../services/api/ContractTemplateClient";
import { useTranslation } from "../contexts/TranslationProvider";
import { useSearchParams } from "react-router-dom";

export function useContractData(
  contractId: ContractEntity['id'],
  initialContractName: ContractEntity['name'],
  levelsFilter: {
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }
) {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { language, setLanguage } = useTranslation();
  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")

  const {
    templateData,
    contractTemplate,
    setContractTemplate,
    setContract,
    setInputValues,
    setFileNames,
    setBeneficialsMap,
    setTemplateId,
    setGroups,
    userGroup,
    setUserGroup,
    accessUser,
    setAccessUser,
    setContractId,
    groupContract,
    setGroupContract,
    setSegmentsOverrides,
    commentsOverrides,
    additionalClauses,
    setAdditionalClauses,
    setCommentsOverrides
  } = useContext(InputValuesContext);
  const [contractName, setContractName] = useState<ContractEntity['name']>(initialContractName);
  const [isEditing, setIsEditing] = useState(false);
  const [paramValues, setParamValues] = useState<ContractEntity['paramValues']>({});
  const [excludedClauses, setExcludedClauses] = useState<ContractEntity['excludedClauses']>([]);
  const [excludedSubClauses, setExcludedSubClauses] = useState<ContractEntity['excludedSubClauses']>([]);



  const apiClient = apiClientWithLoading(setLoading);
  const contractClient = new ContractClient(apiClient)
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const groupClient = new GroupClient(apiClient)
  useEffect(() => {
    let accessMap
    let usergroupIds
    let groupContract
    const fetchData = async () => {

      if (contractId) {
        setContractId(contractId);
        const row = !restrictedToken ?
          await contractClient.getById(contractId)
          : await contractClient.getByIdRestricted(contractId, restrictedToken)
        console.log(row)
        if (row.template) {
          const { languages } = row.template;
          if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
            setLanguage(languages[0]);
          }
        }
        const templateRow = row.template;
        groupClient.getAll().then(({ rows }) => {
          const foundGroup = rows?.find(group => group?.id === row.assignedToGroupId);
          if (foundGroup) {
            setGroupContract(foundGroup.name)
          }
        });


        !restrictedToken && await getUserGroups(apiClient)
          .then((usergroupdata) => {
            usergroupIds = usergroupdata.rows.map((group) => group.id);
            setUserGroup(usergroupIds);

            // Extract clause codes and group IDs from the template
            const clauseGroup = row.template.groups.map((group) => {
              return group.Group_ContractTemplate.clauseCodes.map((code) => ({
                code,
                groupId: group.Group_ContractTemplate.GroupId
              }));
            });

            // Create a mapping of clause codes to group IDs
            const clauseMapGroup = {};
            clauseGroup.forEach((codes) => {
              codes.forEach(({ code, groupId }) => {
                clauseMapGroup[code] = clauseMapGroup[code] || [];
                if (!clauseMapGroup[code].includes(groupId)) {
                  clauseMapGroup[code].push(groupId);
                }
              });
            });

            // Check user access for each clause code
            accessMap = {};
            usergroupIds.forEach((groupId) => {
              Object.keys(clauseMapGroup).forEach((code) => {
                const access = clauseMapGroup[code].includes(groupId);
                if (!accessMap[code]) {
                  accessMap[code] = false;
                }
                if (access) {
                  accessMap[code] = true;
                }
              });
            });
          })
          .catch((error) => {
            console.error('Error fetching user groups:', error);
          });
        setAdditionalClauses(row?.additionalClauses ?? [])
        setCommentsOverrides(row?.commentsOverrides ?? {})
        setSegmentsOverrides(row?.segmentsOverrides ?? {})
        setAccessUser(accessMap)
        setContract(row);
        setContractName(row?.name);
        setParamValues(row?.paramValues);
        setFileNames(row?.fileNames);
        setBeneficialsMap(row?.beneficialsMap)
        setExcludedClauses(row?.excludedClauses);
        setExcludedSubClauses(row?.excludedSubClauses);
        setContractTemplate(templateRow);
        setTemplateId(row?.templateId);
        console.log(row?.validationGroups);
        setGroups(row?.validationGroups);
        setIsEditing(true);
      } else if (contractName) {
        setLoading(true);
        console.log(levelsFilter);
        const { row } = await contractTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'full');

        setContractTemplate(row);
        const usergroupdata = await getUserGroups(apiClient)
          .then((usergroupdata) => {
            usergroupIds = usergroupdata.rows.map((group) => group.id);
            setUserGroup(usergroupIds);

            // Extract clause codes and group IDs from the template
            const clauseGroup = row.groups.map((group) => {
              return group.Group_ContractTemplate.clauseCodes.map((code) => ({
                code,
                groupId: group.Group_ContractTemplate.GroupId
              }));
            });

            // Create a mapping of clause codes to group IDs
            const clauseMapGroup = {};
            clauseGroup.forEach((codes) => {
              codes.forEach(({ code, groupId }) => {
                clauseMapGroup[code] = clauseMapGroup[code] || [];
                if (!clauseMapGroup[code].includes(groupId)) {
                  clauseMapGroup[code].push(groupId);
                }
              });
            });

            // Check user access for each clause code
            accessMap = {};
            usergroupIds.forEach((groupId) => {
              Object.keys(clauseMapGroup).forEach((code) => {
                const access = clauseMapGroup[code].includes(groupId);
                if (!accessMap[code]) {
                  accessMap[code] = false;
                }
                if (access) {
                  accessMap[code] = true;
                }
              });
            });
          })
          .catch((error) => {
            console.error('Error fetching user groups:', error);
          });
        setAccessUser(accessMap)
        console.log('accessUser', accessMap)
        setTemplateId(row?.id);
        setGroups(row?.groups!);
        setAdditionalClauses([])
        setCommentsOverrides({})
        setSegmentsOverrides({})
        setInputValues({
          contractName: contractName,
        });
        setLoading(false);
      }
    };
    fetchData();
  }, [contractId, initialContractName]);

  return {
    templateData,
    isLoading,
    contractName,
    isEditing,
    paramValues,
    excludedClauses,
    excludedSubClauses,
    userGroup,
    accessUser,
    groupContract,
    additionalClauses,
    commentsOverrides
  };
}
