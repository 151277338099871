import React, { useEffect, useMemo, useState, useContext } from 'react'
import { getAllRenderedParamNames, getAllRenderedParams } from "../../../domain/FormTemplate";
import FormTemplateParameterInput from './FormTemplateParameterInput';
import {DndContext} from '@dnd-kit/core';
import { FaTrashAlt } from "react-icons/fa";
import { SortableList } from '../../../components/common/dnd-kit/SortableList';
import EditContractTemplateContext from '../../../contexts/EditFormTemplateContext';
import { FiEdit2 } from "react-icons/fi";
import AddParamForm from './AddParamForm';
import "./formTemplatePageInput.scss"
import { useTranslation } from '../../../contexts/TranslationProvider';
import FormTemplateNestedChildrenParams from './FormTemplateNestedChildrenParams';
import { on } from 'events';

function FormTemplatePageInput({ page }) {
  const initialItems = (page?.params ?? []).map((param, index) => ({ id: param.name, ...param })).filter(p => !p.condition || !p.condition[0])
  const [items, setItems] = useState(initialItems);
  const [isEditingItems, setIsEditingItems] = useState({});
  const [showAddParam, setShowAddParam] = useState(false);
  const { formTemplate, onDeleteParam, onAddParam, onSetNewOrder} = useContext(EditContractTemplateContext);
  const editionTemplateTranslation = "pages.editionFormTemplate"
  const { setLanguage, language, t } = useTranslation();
  useEffect(() => {
        const newItems = (page?.params??[])?.map((param, index) => ({ index: index, id: param.name, ...param }))
        .filter(p => !p.condition || !p.condition[0])
        setItems(newItems);
  }, [formTemplate, page]);

  const triggerEdit = (name) => {
    setIsEditingItems(prevState => ({ ...prevState, [name]: !prevState[name] }));
  }
  const handleDelete = (param)=>{
    onDeleteParam(page.id, param)
  }
  const handleAdd = (param, id = page.id)=>{
    onAddParam(id, param)
  }
  const handleChangeItems = (items) => {
    setItems(items);
    onSetNewOrder(page.id, items.map((item, index) => ({name: item.name, index})))
  };
  
  return (
    <div className='formPageInput template-form' style={{ width: "100%"}}>
        <div className={`add-container`}>
          {showAddParam ? 
          		<AddParamForm params={items} handleAdd={handleAdd} handleCancel={()=>setShowAddParam(false)} />
           : <span className='add-param-tag' 
           onClick={()=>setShowAddParam(true)}>
            {t(editionTemplateTranslation + ".actions.addParam")}
            </span>}
        </div>
      <SortableList
        items={items}
        onChange={handleChangeItems}
        renderItem={(item) => 
          {
            return(
          <SortableList.Item id={item.id} className={`template-input ${["enum", "boolean"].includes((item as any).type) && "nested-border"}`}>
            <div  className={`template-input-body`}>
            <FormTemplateParameterInput
              page={page}
              param={item}
              isEditing={!!isEditingItems[item.name]}
            />
            <div className='template-input-actions'>
              <SortableList.DragHandle dragClassName='template-input-action grab-action' />
              <button onClick={()=>handleDelete(item!)} className='template-input-action'>
                <FaTrashAlt />
              </button>
              <button onClick={()=>triggerEdit(item.name)} className='template-input-action'>
                <FiEdit2 color={isEditingItems[item.name]  && "blue"}/>
              </button>
            </div>
            </div>
            {
              ["enum", "boolean"].includes((item as any).type) && (
                <FormTemplateNestedChildrenParams
                  page={page}
                  param={item}
                />
              )
            }
          </SortableList.Item>
        )}
        }
      >
      </SortableList>
    </div>
  );

  function handleDragEnd(event) {
    console.log(event)
  }
}

export default FormTemplatePageInput