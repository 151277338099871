import "./groupContracts.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import GroupContractsList from "./GroupContractsList";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractClient } from "../../services/api/ContractClient";
import { ContractEntity } from "../../domain/entities";
import CustomCombobox from "../../components/common/CustomCombobox";
import { isBuffer } from "util";

function GroupContracts() {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const statuses: ContractEntity["status"][] = ['Draft', 'Done', 'Negotiation', 'ToBeSigned', 'Signed']
  const translationPath = "pages.groupContracts."
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const fetchData = useCallback(async () => {
    try {
      const data = await contractClient.getAssignedGroupContracts();
      const contrats = data.rows;
      const groups = data.groups;
      setGroups(groups);
      setContracts(contrats);
      setFilteredContracts(contrats);
      setLoading(false); // Set loading to false after contracts are fetched
    } catch (error) {
      console.error("Error fetching contracts: ", error);
      setLoading(false); // Also set loading to false in case of error
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (!searchTerm) {
      setFilteredContracts(contracts);
      return;
    }else{
      setFilteredContracts(contracts.filter((contract) =>
        contract.name?.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
  }, [searchTerm]);
  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    if (status) {
      setFilteredContracts(contracts.filter((contract) => contract.status === status));
    } else {
      setFilteredContracts(contracts);
    }
  }
  return (
    <div className="group-contracts-page">
      <section id="contracts" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.groupContracts.title")}</span>
        <div style={{ padding: "20px" }} className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
            <CustomCombobox
          canDeselect
          label=""
          onDropdownOpen={() => { }}
          options={statuses}
          value={statusFilter}
          onChange={handleStatusFilterChange}
          optionDisplay={(option) => (<>
            {option ? <div className="filter-type-item">
              <span>
                {t(translationPath + option)}
              </span>
            </div> :
              <span>
                {t(translationPath + "status")}
              </span>
            }
          </>)}
          fullWidth={true}
          isOpen={openCombobox === "statusFilter"}
          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "statusFilter" : null)}
        />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="ressources-list">
          {loading ? (
            <div style={{ width: "83vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loading height="75vh" />
            </div>
          ) : (
            <div>
              <GroupContractsList reloadContracts={fetchData} contracts={filteredContracts} groups={groups} />
            </div>
          )}
        </div>
      </section>
    </div>
    // {loading ? <Loading height="75vh" /> : <GroupContractsList reloadContracts={fetchData} contracts={filteredContracts} groups={groups} />}</div>

  );
}

export default GroupContracts;