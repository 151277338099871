import { AxiosInstance } from "axios";
import { ProjectTemplateEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class ProjectTemplateClient extends ModelClient<ProjectTemplateEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ProjectTemplate')
  }

  async import(file: File, type: 'xlsx' | 'json' = 'xlsx') {
    const url = type == 'xlsx' ? new URL(this.modelUrl + "/importFromXlsx")
      : new URL(this.modelUrl + "/importFromJSON")
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.apiClient.post<{ errors: any[],
      rows: {
        id: ProjectTemplateEntity['id'], name: ProjectTemplateEntity['name'],
      }[]
     }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async export(id: ProjectTemplateEntity['id'], type: 'xlsx' | 'json' = 'xlsx') {
    try {
      const url = type == 'xlsx' ? new URL(this.modelUrl + "/exportToXlsx")
        : new URL(this.modelUrl + "/exportToJSON")
      url.searchParams.append("id", String(id))

      const response = await this.apiClient.get<{ url: string }>(url.href);
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
}