import "../commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import CustomCombobox from "../../common/CustomCombobox";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ProjectEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { ContractClient } from "../../../services/api/ContractClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { getName } from "../../../helpers/Translation";
interface CreateContractModalType {
  onClose: () => void;
  dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
  typeReq?: number
}

function CreateContractModal({ onClose, dataProject, typeReq }: CreateContractModalType) {
  const { t, language } = useTranslation();
  const { setContractId, setContract, setProject, setRequirement, setRequirementType } = useContext(InputValuesContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] = useState<NodeJS.Timeout>(null);
  const modalContentRef = useRef(null);
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [nameLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => getName(item, language) === value);
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", t("pages.pilot.popups.contract.inputs.type2.placeholder"));
      setValue("Type3", t("pages.pilot.popups.contract.inputs.type3.placeholder"));
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => getName(item, language) === value);
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: Array.isArray(selectedLevel2.levels3) && selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", t("pages.pilot.popups.contract.inputs.type3.placeholder"));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => getName(item, language) === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    if (dataProject) {
      setLoading(false);
      let selectedLevel, selectedValue;

      if (getName(dataProject?.requirement?.level3!, language)) {
        selectedLevel = "level3.name";
        selectedValue = dataProject.requirement.level3.name;
        data.Type3 = selectedValue
      } if (dataProject.requirement.level2?.name) {
        selectedLevel = "level2.name";
        selectedValue = dataProject.requirement.level2.name;
        data.Type2 = selectedValue
      } if (dataProject.requirement.level1?.name) {
        selectedLevel = "level1.name";
        selectedValue = dataProject.requirement.level1.name;
        data.Type1 = selectedValue
      }
      setContractId(null);
      setContract(null);
      setProject(dataProject?.project)
      setRequirement(dataProject?.requirement)
      setRequirementType(typeReq)
      navigate("/edition-contrat", {
        state: {
          contractDataFromModal: {
            ...data,
            [selectedLevel]: selectedValue,
          },
          levelsFilter,
        },
      });

    } else {
      setLoading(false);
      let selectedLevel, selectedValue;
      if (data.Type3 !== "Type Niveau 3") {
        selectedLevel = "level3.name";
        selectedValue = data.Type3;
      } else if (data.Type2 !== "Type Niveau 2") {
        selectedLevel = "level2.name";
        selectedValue = data.Type2;
      } else if (data.Type1 !== "Type Niveau 1") {
        selectedLevel = "level1.name";
        selectedValue = data.Type1;
      }

      setContractId(null);
      setContract(null);
      navigate("/edition-contrat", {
        state: {
          contractDataFromModal: {
            ...data,
            [selectedLevel]: selectedValue,
          },
          levelsFilter,
        },
      });

    }
  };

  const contractName = watch("contractName");
  useEffect(() => {
    if (checkContractExistsTimeout) {
      clearTimeout(checkContractExistsTimeout)
    }
    setCheckContractExistsTimeout(setTimeout(async () => {
      try {
        const count = await contractClient.countAllByFilter({ "name": contractName });
        setContractExistsError(count >= 1);
      } catch (error) {
        console.error(error);
      } finally {
        setCheckContractExistsTimeout(null)
      }
    }, 500))
  }, [contractName]);

  return (

    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.pilot.popups.contract.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <p>{t("pages.pilot.popups.contract.description")}</p>
                {loading ? (
                  <Loading height="35vh" />
                ) : (
                  <form action="" className="form-group" onSubmit={handleSubmit(onSubmit)}
                    style={{ maxHeight: '550px', marginBottom: "20%" }}>
                    <p className="fw-bolder">{t("pages.pilot.popups.contract.required")}</p>
                    <div className="form-input-content">
                      <div className="w-50">
                        <label htmlFor="contractName" className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.contractName.name")}</label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input input-popup"
                            id="contractName"
                            placeholder={t("pages.pilot.popups.contract.inputs.contractName.placeholder")}
                            onChange={(e) => { setContractExistsError(false) }}
                            {...register("contractName", { required: true })}
                          />
                        </div>
                        {errors.contractName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error1")}</p>}
                        {contractExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error2")}</p>}
                      </div>
                      {dataProject ? (
                        <div className="content-levels">
                          <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type1.name")}</label>
                          <select value={getName(dataProject?.requirement?.level1!, language)} disabled>
                            <option>{getName(dataProject?.requirement?.level1!, language)}</option>
                          </select>
                          {dataProject?.requirement?.level2 && (
                            <>
                              <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type2.name")}</label>
                              <select value={getName(dataProject?.requirement?.level2!, language)} disabled>
                                <option>{getName(dataProject?.requirement?.level2!, language)}</option>
                              </select>
                            </>
                          )}
                          {dataProject?.requirement?.level3 && (
                            <>
                              <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type3.name")}</label>
                              <select value={getName(dataProject?.requirement?.level3!, language)} disabled>
                                <option>{getName(dataProject?.requirement?.level3!, language)}</option>
                              </select>
                            </>
                          )}
                        </div>
                      ) : (
                        <div style={{width:"40%"}}>
                          <Controller
                            name="Type1"
                            control={control}
                            defaultValue={t("pages.pilot.popups.contract.inputs.type1.placeholder")}
                            rules={{
                              required: true,
                              validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type1.placeholder"),
                            }}
                            render={({ field }) => (
                              <CustomCombobox
                               fullWidth
                                label={t("pages.pilot.popups.contract.inputs.type1.name")}
                                options={options.level1.map((item) => getName(item, language))}
                                value={dataProject ? getName(dataProject?.requirement?.level1, language) : field.value}
                                onDropdownOpen={handleComboboxClick}
                                onChange={(value) => {
                                  handleLevel1Change(value);
                                  field.onChange(value);
                                }}
                                isOpen={openCombobox === "level1"}
                                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                              />
                            )}
                          />

                          {errors.Type1 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type1.error")}</p>}
                          {nameLevels.level2 && (
                            <Controller
                              name="Type2"
                              control={control}
                              defaultValue={t("pages.pilot.popups.contract.inputs.type2.placeholder")}
                              rules={{
                                required: true,
                                validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type2.placeholder"),
                              }}
                              render={({ field }) => (
                                <CustomCombobox
                                fullWidth
                                  label={t("pages.pilot.popups.contract.inputs.type2.name")}
                                  onDropdownOpen={handleComboboxClick}
                                  options={options.level2.map((item) => getName(item, language))}
                                  value={dataProject ? getName(dataProject?.requirement?.level2!, language) : field.value}
                                  onChange={(value) => {
                                    handleLevel2Change(value);
                                    field.onChange(value);
                                  }}
                                  isOpen={openCombobox === "level2"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                                />
                              )}
                            />
                          )}
                          {options.level2.length > 0 && errors.Type2 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type2.error")}</p>}
                          {nameLevels.level3 && (
                            <Controller
                              name="Type3"
                              control={control}
                              defaultValue={t("pages.pilot.popups.contract.inputs.type3.placeholder")}
                              rules={{
                                required: true,
                                validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type3.placeholder"),
                              }}
                              render={({ field }) => (
                                <CustomCombobox
                                fullWidth
                                  label={t("pages.pilot.popups.contract.inputs.type3.name")}
                                  onDropdownOpen={handleComboboxClick}
                                  options={options.level3.map((item) => getName(item, language))}
                                  value={dataProject ? getName(dataProject?.requirement?.level3!, language) : field.value}
                                  onChange={(value) => {
                                    handleLevel3Change(value);

                                    field.onChange(value)
                                  }}
                                  isOpen={openCombobox === "level3"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                                />
                              )}
                            />
                          )}

                          {options.level3.length > 0 && errors.Type3 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type3.error")}</p>}
                        </div>)}
                    </div>
                    <div >
                      <button
                        disabled={contractExistsError || !!checkContractExistsTimeout}
                        type="submit" className="btn btn-primary" style={{ left: "4.5%" }}>
                        {t("pages.pilot.popups.contract.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default CreateContractModal;
