import "./Home.scss";
import Navbar from "./components/common/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingProvider from "./contexts/LoadingContext";
import { TranslationProvider } from './contexts/TranslationProvider';
import RestrictedContent from "./RestrictedContent";
import { useProvideContextInputValues } from "./hooks/useProvideContextInputValues";
import InputValuesContext from "./contexts/InputValuesContext";

export const RestrictedHome: React.FC = () => {

  const expanded = false;
  return (
    <TranslationProvider>
      <LoadingProvider>
        <InputValuesContext.Provider value={useProvideContextInputValues()}>
          <div className="app-container">
            <Navbar expanded={expanded} restricted={true} />
            <div style={{ display: "flex" }}>
              <RestrictedContent />
            </div>
          </div>
        </InputValuesContext.Provider>
        <ToastContainer hideProgressBar={true} autoClose={3000} />
      </LoadingProvider>
    </TranslationProvider>
  );
}