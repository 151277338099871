import { useEffect, useContext } from "react";

import apiClientWithLoading from "../services/api/ApiClient";
import { EditFormContextType } from "../contexts/EditFormContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../domain/entities";
import { FormClient } from "../services/api/FormClient";
import { FormTemplateClient } from "../services/api/FormTemplateClient";
import { useTranslation } from "../contexts/TranslationProvider";

export function useFormData(
  context: EditFormContextType,
  levelsFilter: {
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }
) {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { language, setLanguage } = useTranslation();
  const {
    form, setForm,
    formId, setFormId,
    template, setTemplate,
    name, setName,
    setParamValues,
    setFileNames,
    setBeneficialsMap,
    setTemplateId,
    setGroups,
  } = context;

  useEffect(() => {
    const fetchData = async () => {
      const apiClient = apiClientWithLoading(setLoading);
      const formClient = new FormClient(apiClient)
      const formTemplateClient = new FormTemplateClient(apiClient)

      if (form && template)
        return
      if (formId) {
        const row = await formClient.getById(formId);
        if (row.template) {
          const { languages } = row.template;
          if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
            setLanguage(languages[0]);
          }
        }
        setForm(row);
        setFormId(formId);
        setTemplate(row.template)
        setTemplateId(row?.templateId);
        setName(row?.name);
        setParamValues(row?.paramValues);
        setFileNames(row?.fileNames);
        setBeneficialsMap(row?.beneficialsMap)
        setGroups(row?.validationGroups);
      } else if (name) {
        setLoading(true);
        setForm(null);
        setFormId(null);
        const { row } = await formTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'full');
        setTemplate(row);
        setTemplateId(row?.id);
        setGroups(row?.groups!);
        setParamValues({});
        setLoading(false);
      }
    };
    fetchData();
  }, [formId, name]);
}
